import { CONFIG_RESET, CONFIG_ON_CHANGE } from '../actions/webConfig';
import { SET_ACTIVE_PLACE } from '../actions/places';

const defaultState = {
  alphabeticalSort: process.env.REACT_APP_ALPHABETICAL_SORT,
  apiUrl: process.env.REACT_APP_API_URL,
  basicAuth: process.env.REACT_APP_BASIC_AUTH,
  customerId: process.env.REACT_APP_CUSTOMER_ID,
  billingInformationCopy: process.env.REACT_APP_BILLING_INFORMATION_COPY,
  confirmationDisclaimerMessage:
    process.env.REACT_APP_CONFIRMATION_DISCLAIMER_MESSAGE,
  confirmationDisclaimerMessageEnabled:
    process.env.REACT_APP_CONFIRMATION_DISCLAIMER_MESSAGE_ENABLED,
  contactUsPhoneNum: process.env.REACT_APP_CONFIRMATION_PHONE_NUM,
  contactUsPhoneNumEnabled:
    process.env.REACT_APP_CONFIRMATION_PHONE_NUM_ENABLED,
  customCategoryHeader: process.env.REACT_APP_CUSTOM_CATEGORY_HEADER,
  genericErrorMessage: process.env.REACT_APP_GENERIC_ERROR_MESSAGE,
  genericErrorMessage2: process.env.REACT_APP_GENERIC_ERROR_MESSAGE_2,
  genericErrorTitle: process.env.REACT_APP_GENERIC_ERROR_TITLE,
  maxItemInCart: process.env.REACT_APP_MAX_ITEM_IN_CART,
  maxItemInCartBody: process.env.REACT_APP_MAX_ITEM_IN_CART_BODY,
  maxItemInCartTitle: process.env.REACT_APP_MAX_ITEM_IN_CART_TITLE,
  maxItemBody: process.env.REACT_APP_MAX_ITEM_BODY,
  maxItemTitle: process.env.REACT_APP_MAX_ITEM_TITLE,
  mustBeOnsiteBanner: process.env.REACT_APP_MUST_BE_ONSITE_BANNER,
  mustBeOnsiteBody: process.env.REACT_APP_MUST_BE_ONSITE_BODY,
  mustBeOnsiteTitle: process.env.REACT_APP_MUST_BE_ONSITE_TITLE,
  menuId: process.env.REACT_APP_MENU_ID,
  menuVenue: process.env.REACT_APP_MENU_VENUE,
  nodeEnv: process.env.REACT_APP_NODE_ENV,
  orderPickupCopy1: process.env.REACT_APP_ORDER_PICKUP_COPY_1,
  orderPickupCopy2: process.env.REACT_APP_ORDER_PICKUP_COPY_2,
  placeId: process.env.REACT_APP_PLACE_ID,
  taxAndDiscountCopy: process.env.REACT_APP_TAX_AND_DISCOUNT_COPY,
  token: process.env.REACT_APP_TOKEN,
  username: process.env.REACT_APP_USERNAME,
  userEmail: process.env.REACT_APP_USEREMAIL,
  venueId: process.env.REACT_APP_VENUE_ID,
  weAreClosedTitle: process.env.REACT_APP_WE_ARE_CLOSED_TITLE,
  weAreClosedBody: process.env.REACT_APP_WE_ARE_CLOSED_BODY,
  grayedButtonMaxItem: process.env.REACT_APP_GRAYED_BUTTON_MAX_ITEM,
  overrideInVenue: null,
  inVenueRequired: null,
  themes: null,
  selectedTheme: null,
  themeVariables: null,
  pickupFromUrl: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CONFIG_ON_CHANGE:
      return {
        ...state,
        ...action.payload,
      };
    case CONFIG_RESET:
      return defaultState;
    case SET_ACTIVE_PLACE:
      if (action.data && action.data.fullyQualifiedId)
        return { ...state, placeId: action.data.fullyQualifiedId };
      else return { ...state, placeId: null };
    default:
      return state;
  }
};
