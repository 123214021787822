export const getCheckinLink = (
  config: string,
  venueId: string,
  orderId: string,
  token: string,
) => {
  let baseRoute =
    process.env.NODE_ENV === 'development'
      ? window.location.origin.replace('localhost:3000', 'foodandbev.te2.io')
      : window.location.origin;

  if (config && venueId && orderId && token) {
    return `${baseRoute}/#/config/${config}/venue/${venueId}/order/${orderId}?access_token=${token}`;
  }

  return undefined;
};
