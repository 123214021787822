import React from 'react';
import classNames from 'classnames';

export default (props) => {
  const onChange = (event) => {
    props.onClick(props.action, props.id, event.currentTarget.checked);
  };

  const displayNameClasses = classNames(
    'modifier-text',
    'color-text-primary',
    'header-text',
    'uppercase',
    props.disabled ? 'color-disabled' : '',
  );
  const checkmarkClasses = classNames(
    'checkmark color-dark-highlight',
    props.checked ? 'border-color-accent bg-color-accent' : '',
    props.disabled ? 'disabled color-light-highlight' : '',
  );
  const modifierClasses = classNames(
    'modifier-price',
    'font-primary',
    'color-text-tertiary',
  );

  return (
    <div className="modifier-container">
      <label className="modifier-wrapper">
        <p className={displayNameClasses}>{props.displayName}</p>
        <input
          id={`${props.id}`}
          type="checkbox"
          name={props.modifierGroupId}
          onChange={onChange}
          checked={props.checked}
          disabled={props.disabled}
        />
        <span className={checkmarkClasses} />
        {props.price > 0 && (
          <span className={modifierClasses}>+ {props.displayPrice}</span>
        )}
      </label>
    </div>
  );
};
