import { MENU_NOT_FOUND, SAVE_MENU, SET_MENU_STATUS } from '../actions/menu';

const defaultState = {
  categories: [],
  products: [],
  cacheControl: {},
  cacheExpires: 0,
  readinessStatus: true,
  willBeOpenLaterAt: null,
};

const MS_PER_SECOND = 1000;

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case MENU_NOT_FOUND: {
      return {};
    }
    case SET_MENU_STATUS: {
      const { readinessStatus, willBeOpenLaterAt } = data;
      return { ...state, readinessStatus, willBeOpenLaterAt };
    }
    case SAVE_MENU: {
      const categories = data.categories;
      const cacheControl = data.cacheControl || {};
      const cacheExpires = Date.now() + 300 * MS_PER_SECOND;

      return {
        ...state,
        ...data,
        cacheExpires,
        categories,
        cacheControl,
      };
    }
    default:
      return state;
  }
};
