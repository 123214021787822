import { ActionType } from '../action-types';
import { Action, QueryParam } from '../actions/query.actions';

interface QueryState {
  params: QueryParam;
}

const initialState = {
  params: {},
};

export const queryReducer = (
  state: QueryState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionType.STORE_QUERY_PARAMS:
      return { ...state, params: payload };

    default:
      return state;
  }
};
