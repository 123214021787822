import React from 'react';
import { connect } from 'react-redux';

import {
  reportMenuSelect,
  reportPageView,
} from '../../helpers/googleAnalytics';
import { history } from '../../helpers/history';

import actions from '../../redux/actions';
import api from '../../redux/actions/api';
import { closeToast2, openToast2 } from '../../redux/actions/toast2';

import clock from '../../assets/icons/clock-ordering-hours.svg';
import './place.scss';

const buildMenuPath = (matchParams, placeId, menuId) => {
  const { config, venue } = matchParams;
  return `/config/${config}/venue/${venue}/place/${placeId}/menu/${menuId}`;
};

const getLabelFor = (place) => {
  return place?.activeMenu?.content?.name || place.label;
};

class Place extends React.Component {
  constructor(props) {
    super(props);
    const { place } = props;
    this.state = {
      visibleTags: place.tags ? place.tags.slice(0, 2) : [],
      tagsSize: props.tagsSize || 0,
    };
    this.tagsRef = React.createRef();
  }

  goToPlace() {
    const { place, setActivePlace, getPaymentKeyFor, match } = this.props;
    const menu = place.activeMenu;
    setActivePlace(place);
    getPaymentKeyFor(place);
    const menuPath = buildMenuPath(
      match.params,
      place.fullyQualifiedId,
      menu.id,
    );
    reportPageView(menuPath);
    reportMenuSelect(place.label, menu?.content?.name);
    history.push(menuPath);
  }

  calcVisibleTags = () => {
    const { place, placeSize } = this.props;

    // Hiding 2nd place if size is too long
    if (document.getElementsByClassName('place').length > 0) {
      const placeSizeHTML = placeSize
        ? placeSize
        : document.getElementsByClassName('place')[0].clientWidth - 112 - 20;
      const tagsSize = this.state.tagsSize
        ? this.state.tagsSize
        : this.tagsRef.current.scrollWidth;
      const sliceNumber = tagsSize > placeSizeHTML ? 1 : 2;

      // Only display the first two tags or one if we have a greater size.
      this.setState({
        visibleTags: place.tags ? place.tags.slice(0, sliceNumber) : [],
      });
    }
  };

  componentDidMount() {
    if (this.state.visibleTags.length === 2) {
      this.setState({ tagsSize: this.tagsRef.current.scrollWidth });
      this.calcVisibleTags();
      window.addEventListener('resize', this.calcVisibleTags);
    }
  }

  componentWillUnmount() {
    if (this.state.visibleTags.length === 2) {
      window.removeEventListener('resize', this.calcVisibleTags);
    }
  }

  renderOrderingHours = (place) => {
    if ((place.opensAt || place.closesAt) && place.isDisplayOrderingHours) {
      return (
        <div className="tag">
          <div className="tag-images">
            <img className="tag-image" src={clock} alt="icon" />
          </div>
          <div className="tag-info">
            {place.opensAt && (
              <div>
                <div className={`tag-label color-text-tertiary`}>
                  {this.props.placeLocalization.opensAt}
                </div>
                <div className={`tag-value`}>{place.opensAt}</div>
              </div>
            )}
            {place.closesAt && (
              <div>
                <div className={`tag-label color-text-tertiary`}>
                  {this.props.placeLocalization.closesAt}
                </div>
                <div className={`tag-value`}>{place.closesAt}</div>
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  };

  renderPickupTimeWindow = (place) => {
    if (place.isDisplayPickupTimeWindow) {
      return (
        <div className="ptw">
          <span className="label">
            <span>{this.props.placeLocalization.nextPickupTime}</span>:{' '}
          </span>
          {place.estimatedPickupTime && (
            <span className="time bg-color-primary">
              {place.estimatedPickupTime}
            </span>
          )}
          {!place.estimatedPickupTime && (
            <span>{this.props.placeLocalization.currentlyUnavailable}</span>
          )}
        </div>
      );
    }
    return null;
  };

  render() {
    const { place } = this.props;
    const { visibleTags } = this.state;
    const MDPI = 'MDPI';

    // Use the sticker
    const stickerImage =
      place.sticker && place.sticker.images
        ? place.sticker.images.filter((image) => image.resolution === MDPI)[0]
        : null;

    // Use the POI image as a backup
    const poiImage = place.images
      ? place.images.filter((image) => image.resolution === MDPI)[0]
      : null;

    const placeImage = stickerImage || poiImage;
    const sizeRegex = /unsafe\/([^/]*)/;
    if (placeImage && placeImage.src) {
      placeImage.src = placeImage.src.replace(sizeRegex, 'unsafe/100x100');
    }

    const ellipsis = visibleTags.length === 1 ? 'ellipsis' : '';

    return (
      <div
        className="place color-text-primary bg-color-card"
        onClick={this.goToPlace.bind(this)}>
        <div className="sticker">
          {placeImage ? (
            <img
              className="place-image"
              src={placeImage.src}
              alt="place"
              key={placeImage.resolution}
            />
          ) : (
            <div className="place-image placeholder-image border-color-dark-highlight"></div>
          )}
        </div>
        <div className="info">
          <div className="label">{getLabelFor(place)}</div>
          {visibleTags.length > 0 || place.opensAt || place.closesAt ? (
            <div className="tags" ref={this.tagsRef}>
              {this.renderOrderingHours(place)}
              {visibleTags.map((tag, index) => (
                <div key={index} className="tag">
                  <div className="tag-images">
                    {tag.icons
                      ? tag.icons
                          .filter((icon) => icon.resolution === MDPI)
                          .map((icon) => (
                            <img
                              className="tag-image"
                              key={icon.resolution}
                              src={icon.src}
                              alt="icon"
                            />
                          ))
                      : null}
                  </div>
                  <div className="tag-info">
                    <div
                      className={`tag-label color-text-tertiary ${ellipsis}`}>
                      {tag.label}
                    </div>
                    <div className={`tag-value ${ellipsis}`}>{tag.value}</div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="tags">{this.renderOrderingHours(place)}</div>
          )}
          {this.renderPickupTimeWindow(place)}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  places: state.places.data,
  prevPlaceId: state.order.placeId,
  cart: state.order.products,
  orderManagement: state.localization.orderManagement,
  placeLocalization: state.localization.place,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setActivePlace: (id) => {
      dispatch(actions.setActivePlace(id));
    },
    setOrderPlace: (id) => {
      dispatch(actions.setOrderPlace(id));
    },
    openToast2: (data) => {
      dispatch(openToast2(data));
    },
    closeToast2: (data) => {
      dispatch(closeToast2(data));
    },
    cancelOrder: () => {
      dispatch(api.cancelOrder());
    },
    getPaymentKeyFor: (place) => {
      dispatch(api.getPaymentKey(place.fullyQualifiedId));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Place);
