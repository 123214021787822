import { userIsAnonymous } from '../token';

export const shouldPromptForAuth = (
  webConfig: any,
  token: any,
  prompts: any,
): boolean => {
  const { identity } = token || {};
  const { accessParams } = identity || {};
  const isAnonymous = userIsAnonymous(accessParams);

  return (
    !!webConfig?.gateway &&
    webConfig?.shouldPromptForAuth &&
    isAnonymous &&
    !prompts?.auth
  );
};

export const shouldPromptForSignUp = (webConfig: any, token: any): boolean => {
  const { identity } = token || {};
  const { accessParams } = identity || {};
  const isAnonymous = userIsAnonymous(accessParams);

  return !!webConfig?.gateway && webConfig?.shouldPromptForAuth && isAnonymous;
};
