export const handleDefaultZone = ({ webConfig, pickupZones }) => {
  const { pickupFromUrl } = webConfig;

  if (pickupFromUrl.hasPickupFromUrl) {
    // Find zone by label(pickupLoc from query param)
    // Ignoring case sensitivity and accent
    const pickedUpZoneFromUrl = pickupZones.find(
      (o) =>
        o.label.localeCompare(pickupFromUrl.pickupLoc, undefined, {
          sensitivity: 'base',
        }) === 0,
    );

    if (pickedUpZoneFromUrl && pickedUpZoneFromUrl.pickupZoneId) {
      return pickedUpZoneFromUrl.pickupZoneId;
    }
  }

  return '';
};

export const handleDefaultZoneUserCustomValue = ({ webConfig }) => {
  const { pickupFromUrl } = webConfig;

  if (pickupFromUrl.hasPickupFromUrl && pickupFromUrl.pickupText) {
    // Use pickupText from query param as default zone user custom value
    return pickupFromUrl.pickupText;
  }

  return '';
};
