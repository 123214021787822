const INVALID_INPUT = 'INVALID INPUT';

export default function (amount, useDecimalPoint = true) {
  if (!amount) {
    return [0, ''];
  }

  const sep = useDecimalPoint ? '.' : ',';

  let whole = '';
  let fraction = '';
  let foundDecimal = false;
  for (let i = 0; i < amount.length; i++) {
    const c = amount.charAt(i);
    if ('0' <= c && c <= '9') {
      if (foundDecimal) {
        fraction += c;
      } else {
        whole += c;
      }
    } else if (c === sep) {
      foundDecimal = true;
      continue;
    } else {
      throw Error(INVALID_INPUT);
    }
  }

  if (!foundDecimal) {
    const pennies = parseInt(whole);
    const dollars = Math.floor(pennies / 100);
    const cents = (pennies % 100) / 100;
    const sum = dollars + cents;
    let rewritten = sum.toFixed(2);
    if (!useDecimalPoint) {
      rewritten = rewritten.replace('.', ',');
    }
    return [sum, rewritten];
  }

  while (fraction.length < 2) {
    const donor = whole.charAt(whole.length - 1);
    whole = whole.slice(0, whole.length - 1);
    fraction = `${donor}${fraction}`;
  }

  while (fraction.length > 2) {
    const donor = fraction.charAt(0);
    whole = `${whole}${donor}`;
    fraction = fraction.slice(1);
  }

  const parsed = parseFloat(`${whole}.${fraction}`);
  let rewritten = parsed.toFixed(2);
  if (!useDecimalPoint) {
    rewritten = rewritten.replace('.', ',');
  }
  return [parsed, rewritten];
}
