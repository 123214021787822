import React, { FC, useState } from 'react';
import './discountSelectionList.scss';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useActions';
import { DiscountApplicationType } from '../../models/discountCode.model';
import RadioButton from '../../components/ModifierGroups/RadioButton';
import OrderButton from '../OrderButton/OrderButton';
import { toast } from 'pebl';
interface DiscountSelectionListProps {
  localization: any;
}

const DiscountSelectionList: FC<DiscountSelectionListProps> = ({
  localization,
}) => {
  const { setDiscount, setDiscountPopup, _updateOrder } = useActions();
  let seasonPassDiscount = useTypedSelector((state) =>
    state.discountCode?.selectedDiscount?.find(
      (discount) =>
        discount.applicationType ===
        DiscountApplicationType.ORDER_LEVEL_DISCOUNT,
    ),
  );
  let diningPassDiscount = useTypedSelector((state) =>
    state.discountCode?.discounts?.find(
      (discount) =>
        discount.applicationType ===
        DiscountApplicationType.ITEM_LEVEL_DISCOUNT,
    ),
  );

  const [selectedLineItemOptions, setSelectedLineItemOptions] = useState<
    string[]
  >([]);

  const onClick = (action: any, id: any, isChecked: boolean) => {
    if (diningPassDiscount && diningPassDiscount.lineItemOptions) {
      diningPassDiscount.lineItemOptions.forEach((option) => {
        if (option.lineItemId === id) {
          option.checked = isChecked;
          setSelectedLineItemOptions([option.lineItemId]);
        } else {
          option.checked = false;
        }
      });
    }
  };

  const onApplyOrder = () => {
    if (diningPassDiscount && selectedLineItemOptions) {
      diningPassDiscount.lineItems = selectedLineItemOptions;
      const applicableDiscounts = seasonPassDiscount
        ? [seasonPassDiscount, diningPassDiscount]
        : [diningPassDiscount];
      unCheckSelectedDiningPlanLineItems();
      setDiscount(applicableDiscounts);
      _updateOrder({}, () => {
        toast({
          icon: 'done',
          message: `${diningPassDiscount?.displayName} applied`,
        });
      });
    }
    setDiscountPopup(false);
  };

  const unCheckSelectedDiningPlanLineItems = () => {
    if (diningPassDiscount && diningPassDiscount.lineItemOptions) {
      diningPassDiscount.lineItemOptions.forEach((option) => {
        option.checked = false;
      });
    }
  };

  return (
    <>
      <div className="discount-list-wrapper">
        <div className="wrapper-title">
          <h3 className="title">{localization.availableDiscounts}</h3>
        </div>
        {seasonPassDiscount && (
          <div className="discount-content color-text-primary font-primary content-border">
            <div className="discount-item-title">
              {seasonPassDiscount.displayName}
            </div>
            <div>
              {localization.save}{' '}
              {seasonPassDiscount?.percent && seasonPassDiscount.percent * 100}
              {localization.discountPercent}
            </div>
            <div className="season-pass-auto-text">
              {localization.automaticallyApplies}
            </div>
          </div>
        )}
        {diningPassDiscount && (
          <div className="discount-content color-text-primary font-primary">
            <div className="discount-item-title">
              {diningPassDiscount.displayName}
            </div>
            <div>{localization.diningPlanLabel}</div>
            {diningPassDiscount.lineItemOptions.map((category, index) => {
              return (
                <RadioButton
                  key={'diningPlanItems' + index}
                  modifierGroupId={category.lineItemId + index}
                  id={category.lineItemId}
                  checked={category.checked}
                  displayName={category.displayName}
                  onClick={onClick}></RadioButton>
              );
            })}
          </div>
        )}
        <div className="button-wrapper">
          <OrderButton
            type="OTHER"
            onClick={onApplyOrder}
            buttonText={localization.applyOrder}
            disabled={selectedLineItemOptions.length === 0}
          />
        </div>
      </div>
    </>
  );
};

export default DiscountSelectionList;
