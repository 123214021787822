import { SAVE_ORDER_READINESS, GET_ORDER_READINESS } from '../actions/store';
import { RECEIVED, REQUESTING } from '../../helpers/constants';

const defaultState = {
  ready: false,
  pickupZones: [],
  orderingWindows: [],
  api: {
    status: '',
  },
};

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case SAVE_ORDER_READINESS: {
      return {
        ...state,
        ...data,
      };
    }
    case `${GET_ORDER_READINESS}_REQUESTED`: {
      return {
        ...state,
        api: {
          status: REQUESTING,
        },
      };
    }
    case `${GET_ORDER_READINESS}_RECEIVED`: {
      return {
        ...state,
        api: {
          status: RECEIVED,
        },
      };
    }
    default:
      return state;
  }
};
