export enum ActionType {
  // get order
  GET_ORDER = 'GET_ORDER',
  GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS',
  GET_ORDER_FAILURE = 'GET_ORDER_FAILURE',

  // cancel order
  CANCEL_ORDER = 'CANCEL_ORDER',
  CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS',
  CANCEL_ORDER_FAILURE = 'CANCEL_ORDER_FAILURE',

  // submit order
  SUBMIT_ORDER = 'SUBMIT_ORDER',
  SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS',
  SUBMIT_ORDER_FAILURE = 'SUBMIT_ORDER_FAILURE',

  // checkin order
  CHECKIN_ORDER = 'CHECKIN_ORDER',
  CHECKIN_ORDER_SUCCESS = 'CHECKIN_ORDER_SUCCESS',
  CHECKIN_ORDER_FAILURE = 'CHECKIN_ORDER_FAILURE',

  // query
  STORE_QUERY_PARAMS = 'STORE_QUERY_PARAMS',

  //get discount
  GET_DISCOUNT = 'GET_DISCOUNT',
  GET_DISCOUNT_SUCCESS = 'GET_DISCOUNT_SUCCESS',
  GET_NO_DISCOUNT_FOUND = 'GET_NO_DISCOUNT_FOUND',
  GET_DISCOUNT_FAILURE = 'GET_DISCOUNT_FAILURE',
  SET_DISCOUNT = 'SET_DISCOUNT',
  REMOVE_DISCOUNT = 'REMOVE_DISCOUNT',
  SET_DISCOUNT_POPUP = 'SET_DISCOUNT_POPUP',
  RESET_DISCOUNT_DATA = 'RESET_DISCOUNT_DATA',
  REMOVE_ERROR_TEXT = 'REMOVE_ERROR_TEXT',

  // zone details
  SAVE_ZONE_SELECTION_DETAILS = 'SAVE_ZONE_SELECTION_DETAILS',

  // venue
  GET_VENUE = 'GET_VENUE',
  GET_VENUE_SUCCESS = 'GET_VENUE_SUCCESS',
  GET_VENUE_FAILURE = 'GET_VENUE_FAILURE',

  // age restriction prompt
  SET_AGE_RESTRICTION_PROMPT = 'SET_AGE_RESTRICTION_PROMPT',
  AGE_RESTRICTION_ACCEPTED = 'AGE_RESTRICTION_ACCEPTED',
  AGE_RESTRICTION_DECLINED = 'AGE_RESTRICTION_DECLINED',
}
