import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { Order } from '../../models/order.model';
import { ActionType } from '../action-types';
import { Action } from '../actions/order.actions';
import store from '../index';

export const getOrder = (orderId: string) => (dispatch: Dispatch<Action>) => {
  const {
    token,
    webConfig: { apiUrl, customerId },
  } = store.getState();

  dispatch({
    type: ActionType.GET_ORDER,
    payload: orderId,
  });

  try {
    axios({
      method: 'get',
      url: `${apiUrl}orders/${orderId}`,
      headers: {
        Authorization: `Bearer ${token.identity.access}`,
        'X-Customer-ID': customerId,
        'Content-Type': 'application/json',
      },
    }).then((response: AxiosResponse<Order, undefined>) => {
      dispatch({
        type: ActionType.GET_ORDER_SUCCESS,
        payload: response.data,
      });
    });
  } catch (error: any) {
    dispatch({
      type: ActionType.GET_ORDER_FAILURE,
      payload: error.message,
    });
  }
};
