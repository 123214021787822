import store from '../../index';
import axios from 'axios';
import { saveDiscounts } from '../discounts';
import { pushPromise, popPromise } from '../spinner';
import { createPromiseId, redirectToErrorPage } from '../../../helpers';
import { history } from '../../../helpers/history';
import { offlineError } from '../../../helpers/errorMessages';
import { SIRIUSWARE_LOGIN_FEATURE } from './features';

export const getDiscountsRequest = (discountCode, menuId, placeId) => {
  const {
    webConfig: { apiUrl },
    token: {
      identity: { access },
    },
  } = store.getState();
  return axios.post(
    `${apiUrl}discounts/${menuId}/${placeId}`,
    { barcode: `${discountCode}` },
    {
      headers: {
        Authorization: `Bearer ${access}`,
        Accept: 'application/json',
      },
    },
  );
};

export const getDiscounts = (placeId, menuId) => {
  const {
    token: {
      identity: { access },
    },
    webConfig: { isSiriuswareClient },
    siriusware: { passes },
    features,
  } = store.getState();
  let promiseId = createPromiseId();

  const siriuswareLoginFeature =
    isSiriuswareClient && features[SIRIUSWARE_LOGIN_FEATURE];

  const discountRequests = siriuswareLoginFeature
    ? passes.map((pass) => getDiscountsRequest(pass.masterId, menuId, placeId))
    : [getDiscountsRequest(access, menuId, placeId)];

  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return Promise.all(discountRequests)
      .then((response) => {
        let discounts = [];
        response.forEach((res) => {
          discounts = discounts.concat(res.data);
        });
        dispatch(saveDiscounts(discounts));
      })
      .catch((error) => {
        console.log(error);
        if (error.message === 'Network Error') {
          if (history.location.pathname !== '/error') {
            redirectToErrorPage(history, offlineError);
          }
        } else {
          // Don't dispatch a toast for Get Discounts errors
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};
