import {
  LOAD_LOCALIZATION,
  UPDATE_LOCALIZATION,
} from '../actions/localizationActions';
import { set } from 'lodash/set';

const defaultState = {};

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case LOAD_LOCALIZATION: {
      return data;
    }
    case UPDATE_LOCALIZATION: {
      const newState = { ...state };
      set(newState, data.keypath, data.value);
      return newState;
    }
    default:
      return state;
  }
};
