export const getActivePlaceContent = (places) => {
  if (!places.activePlace) {
    return {};
  }

  const activePlace = places.activePlace;
  const id = activePlace.fullyQualifiedId;

  if (!places.placeContent || !places.placeContent[id]) {
    return {
      ...activePlace,
    };
  }

  const placeContent = places.placeContent[id];
  return {
    ...activePlace,
    ...placeContent,
    id: activePlace.id,
  };
};
