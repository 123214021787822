export const SIRIUSWARE_LOGIN_FEATURE = 'siriusware_login';
export const PICKUP_TIME_WINDOWS_FEATURE = 'pickup_time_windows';
export const DISCOUNT_FIELD = 'discount_field';

const features = [
  SIRIUSWARE_LOGIN_FEATURE,
  PICKUP_TIME_WINDOWS_FEATURE,
  DISCOUNT_FIELD,
];

export default features;
