import { isEmpty } from 'lodash/lang';
import React from 'react';
import { connect } from 'react-redux';
import { reportPageView } from '../../helpers/googleAnalytics';
import { mapIcon } from '../../helpers/imageAssets';
import {
  getCurrentCategory,
  getProductsFromCategory,
} from '../../helpers/index';
import { setupWebGateway } from '../../helpers/webgateway';
import { resetSpecialRequest } from '../../redux/actions/order';
import { openPopUp } from '../../redux/actions/popup';
import {
  getActivePlaceContent,
  shouldRequestRefreshedLocationWhenAsked,
  shouldShowOutOfVenueMessage,
} from '../../redux/selectors';
import AuthPrompter from '../authPrompter';
import { reportViewItem, reportViewItemList } from './helper';
import MenuItem from './index';
import NoticeBanner from './NoticeBanner';

class MenuItemContainer extends React.Component {
  state = {
    items: null,
  };

  constructor(props) {
    super(props);

    this.itemOnClick = this.itemOnClick.bind(this);
  }

  componentDidMount() {
    const {
      location,
      resetSpecialRequest,
      match: { params },
      activePlace,
      menu: { products, categories },
    } = this.props;
    reportPageView(location && location.pathname);
    reportViewItemList(params, activePlace, products, categories);
    resetSpecialRequest();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      const contentWrapper = document.querySelector('.content-wrapper');
      contentWrapper && contentWrapper.scrollTo(0, 0);
    }

    const {
      match: { params },
      activePlace,
      menu: { products, categories },
    } = this.props;
    const categoryParam = params.category;
    if (categoryParam || categoryParam !== prevProps.match.params.category) {
      reportViewItemList(params, activePlace, products, categories);
    }
  }

  itemOnClick(product, item, index) {
    const {
      location,
      match: { params },
      activePlace,
      menu: { categories },
    } = this.props;
    reportPageView(
      location && location.pathname && `${location.pathname}/item/${item.id}`,
    );
    this.props.openPopUp(product, item);
    reportViewItem(params, product, index, activePlace, categories);
  }

  renderMenu() {
    const {
      menu,
      match: { params },
    } = this.props;

    if (isEmpty(menu.products) || isEmpty(menu.categories)) {
      return <div>The menu is empty.</div>;
    }

    const products = getProductsFromCategory(
      menu.products,
      getCurrentCategory(params, menu.categories),
    );

    return (
      <ul className={'item-list'}>
        {products &&
          products.map((product, index) => (
            <MenuItem
              key={index}
              item={product}
              index={index}
              onClick={this.itemOnClick}
            />
          ))}
      </ul>
    );
  }

  renderOnsiteNotice = () => {
    const { localization, shouldRequestRefreshedLocation } = this.props;
    const message = shouldRequestRefreshedLocation
      ? localization.popup.mustAllowLocationTitle
      : localization.onSiteNotice;

    return <NoticeBanner icon={mapIcon} alt={'ic-map'} message={message} />;
  };

  render() {
    const { content, uiGateway, match } = this.props;

    return (
      <AuthPrompter uiGateway={uiGateway} match={match}>
        <div className="menu-item-list-wrapper">
          <div className="offset-top"></div>
          {this.renderMenu()}
          {content && content.priceDisclaimer && (
            <p className="tax-discount color-text-tertiary">
              {content.priceDisclaimer}
            </p>
          )}
        </div>
      </AuthPrompter>
    );
  }
}

const mapStateToProps = (state) => {
  const activePlace = getActivePlaceContent(state.places);
  return {
    menu: state.menu,
    categories: state.categories,
    uiGateway: setupWebGateway(state.webConfig, {
      placeId: state?.places?.activePlace?.fullyQualifiedId,
    }),
    activePlace,
    content: activePlace.content,
    localization: state.localization,
    toast: state.toast,
    showOnsiteNotice: shouldShowOutOfVenueMessage(
      state.webConfig,
      state.location,
      activePlace.features,
    ),
    shouldRequestRefreshedLocation: shouldRequestRefreshedLocationWhenAsked(
      state.webConfig,
      state.location,
      activePlace.features,
    ),
  };
};

const mapDispatchToProps = (dispatch) => ({
  openPopUp: (...args) => dispatch(openPopUp(...args)),
  resetSpecialRequest: () => dispatch(resetSpecialRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
