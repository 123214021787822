import find from 'lodash/find';

export const formatCurrency = (
  currency,
  amount,
  locale = navigator.language,
) => {
  const fmt = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  });
  return fmt.format(amount);
};

export const getCurrencySymbol = (currency, locale = navigator.language) => {
  const fmt = new Intl.NumberFormat(locale, { style: 'currency', currency });
  const parts = fmt.formatToParts(1);
  const symbol = find(parts, (p) => p.type === 'currency');
  if (symbol) {
    return symbol.value;
  }
};

export const getTimeFormat = (dateString) => {
  const dateObj = new Date(dateString);
  const timeOptions = { hour: 'numeric', minute: 'numeric' };
  const timeText = new Intl.DateTimeFormat(
    navigator.language,
    timeOptions,
  ).format(dateObj);
  return timeText;
}
