import { SAVE_PROFILE } from '../actions/profile';

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
};

export default (state = defaultState, action) => {
  const { type, data } = action;
  switch (type) {
    case SAVE_PROFILE: {
      return {
        ...state,
        ...data,
      };
    }
    default:
      return state;
  }
};
