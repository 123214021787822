import queryString from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import App from '../App';
import ErrorPage from '../ErrorPage';
import Spinner from '../Spinner';

import actions from '../../redux/actions';
import api from '../../redux/actions/api';
import { _setError } from '../../redux/actions/errorStatus';
import { loadLocalization } from '../../redux/actions/localizationActions';
import { resetConfig } from '../../redux/actions/webConfig';

import { queryConfigBuilder } from '../../helpers';
import { defaultError } from '../../helpers/errorMessages';
import { reportBootScreen } from '../../helpers/googleAnalytics';
import { checkTokenFreshness, decodeTokens } from '../../helpers/token';
import { setupWebGateway } from '../../helpers/webgateway';
import { supportedLanguageHeader } from '../../helpers/supportedLanguage';
import axios from 'axios';

import enLocalization from '../../assets/en.translations.json';

import { getVenue } from '../../redux/action-creators';
import { _initFlagsmith } from '../../redux/actions/api/flagsmith';
import './boot.scss';

class Boot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      haveConfig: false,
      haveToken: false,
      havePlaces: false,
    };
  }

  componentDidMount() {
    const { location, saveToken } = this.props;
    const paramsLocation = queryString.parse(location.search, {
      arrayFormat: 'index',
    });
    const paramsWindow = queryString.parse(window.location.search, {
      arrayFormat: 'index',
    });
    const queryParams = { ...paramsLocation, ...paramsWindow };

    // ROOM-4369 - arrayFormat specifices behavior such that duplicate keys now take the last value instead of creating an array

    // Merge query parameters

    this.props.storeQueryParams(queryParams);
    if (queryParams.access_token) {
      if (typeof queryParams.access_token === 'string') {
        this.setState({ haveToken: true });
        saveToken(queryParams.access_token);
      } else if (
        queryParams.access_token.length &&
        queryParams.access_token.length > 1
      ) {
        this.setState({ haveToken: true });
        saveToken(queryParams.access_token[0]);
      }
    }
    this.fetchConfig(queryParams);
  }

  redirectToGateway(webConfig) {
    const { location } = this.props;
    const paramsLocation = queryString.parse(location.search);
    const paramsWindow = queryString.parse(window.location.search);

    // Merge existing query parameters
    const queryParams = { ...paramsLocation, ...paramsWindow };

    // Remove tokens (they should be invalid at this point)
    delete queryParams.access_token;
    delete queryParams.refresh_token;

    // Redirect to the UI gateway
    const uiGateway = setupWebGateway(webConfig, queryParams, 'anonymous');
    window.location.replace(uiGateway);
  }

  fetchConfig(queryParams) {
    const {
      setError,
      resetConfig,
      fetchWebConfig,
      webConfig,
      match: { params },
      initFlagsmith,
    } = this.props;

    //Loading features with flagsmith
    initFlagsmith();

    // If the browser or client used relative path instead of query parameters
    // Assign the parameters from the relative path as query parameters
    if (params.config) {
      queryParams.configFile = params.config;
    }
    if (params.pickupLoc) {
      queryParams.pickupLoc = params.pickupLoc;
    }
    if (params.pickupText) {
      queryParams.pickupText = params.pickupText;
    }

    const configId = params.config || queryParams.configFile;

    if (configId) {
      resetConfig();
      const queryConfig = queryConfigBuilder(queryParams);
      fetchWebConfig(
        {
          ...queryParams,
          queryConfig,
          venueId: params.venue || queryParams?.venueId,
        },
        (config) => {
          this.initLocalizationAndWebAppConfig(config);
          reportBootScreen({ ...config });
        },
        (error) => {
          setError(defaultError, error);
        },
      );
    } else {
      this.handleConfig(webConfig);
    }
  }

  // Assuming queryParams.localization is available and contains the desired localization
  // Below dynamic import loads the JSON file based on the specified localization
  initLocalizationAndWebAppConfig(config) {
    const { location, loadLocalization } = this.props;
    const paramsLocation = queryString.parse(location.search, {
      arrayFormat: 'index',
    });
    const paramsWindow = queryString.parse(window.location.search, {
      arrayFormat: 'index',
    });
    const queryParams = { ...paramsLocation, ...paramsWindow };
    if (
      queryParams.localization &&
      supportedLanguageHeader.includes(queryParams.localization)
    ) {
      import(`../../assets/${queryParams.localization}.translations.json`)
        .then((localization) => {
          // Axios interceptor to include the chosen localization in all outgoing requests
          axios.interceptors.request.use(
            function (config) {
              config.headers['Accept-Language'] = queryParams.localization;
              return config;
            },
            function (error) {
              return Promise.reject(error);
            },
          );
          loadLocalization(localization);
          this.handleConfig(config);
        })
        .catch((error) => {
          console.error('Error loading translations:', error);
        });
    } else {
      loadLocalization(enLocalization);
      this.handleConfig(config);
    }
  }

  handleConfig(webConfig) {
    const { match, setError } = this.props;
    let venueId = match.params.venue || webConfig.venueId;
    if (!webConfig.customerId || !venueId) {
      const error =
        'missing config parameters:\n' + JSON.stringify(webConfig, null, '  ');
      console.error(error);
      setError(defaultError, error);
    } else {
      this.setState({ haveConfig: true });
      if (webConfig.access_token) {
        const { saveToken } = this.props;
        const decoded = decodeTokens({
          access_token: webConfig.access_token,
          refresh_token: webConfig.refresh_token,
        });
        saveToken(decoded);
      }
      this.handleToken();
    }
  }

  handleToken() {
    const { identity, webConfig } = this.props;
    const identityClientId = (identity && identity.clientId) || null;
    const identityCustomer = (identity && identity.customer) || null;

    /**
     * To prevent users use a valid access_token from a different clientId
     * We add this constraint to the token validation but some access_token data has the venueId
     * in the clientId value for that, both will work to prevent this
     *
     * After discussion with mobile and architecture team, we decided to also check for customer id as a backup for the use cases
     * where the clientId is passed as the brandId (support of multiple venues under one parent venue).
     */
    if (
      identityClientId === webConfig.clientId ||
      identityClientId === webConfig.venueId ||
      identityCustomer === webConfig.customerId
    ) {
      const { accessExpired, refreshExpired } = checkTokenFreshness(identity);
      if (!accessExpired) {
        this.setState({ haveToken: true });
        this.clearQueryParams();
        return this.handleProfile();
      }
      if (!refreshExpired) {
        this.clearQueryParams();
        return this.fetchTokensUsingRefresh(webConfig);
      }
    }

    // The gateway will return with a token.
    this.redirectToGateway(webConfig);
  }

  fetchTokensUsingRefresh(webConfig) {
    const { identity, useRefreshToken, resetPromptedAuth, resetOrderData } =
      this.props;

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRefreshToken(
      identity.refresh,
      () => {
        this.setState({ haveToken: true });
        this.handleProfile();
      },
      (error) => {
        resetPromptedAuth();
        resetOrderData();
        this.redirectToGateway(webConfig);
      },
    );
  }

  handleProfile() {
    this.props.getProfile();
    this.handlePlaces();
    this.handleVenue();
  }

  handleVenue() {
    const { webConfig, match, getVenue } = this.props;
    const { venue } = match.params;
    const venueId = venue || webConfig.venueId;
    getVenue(venueId, (error) => {
      console.error(error);
    });
  }

  handlePlaces() {
    const { setError, getPlaces } = this.props;
    getPlaces(
      () => {
        this.handlePlace();
      },
      (error) => {
        setError(defaultError, error);
      },
    );
  }

  handlePlace() {
    const { history, setActivePlace, setMenuStatus, places, webConfig, match } =
      this.props;
    const { config, venue, menuId, category } = match.params;
    const venueId = venue || webConfig.venueId;
    const configId = config || webConfig.configFile;
    const placeId = match.params.placeId || webConfig.placeId;

    let destination = `/config/${configId}/venue/${venueId}/places`;

    if (placeId) {
      const place = places?.find((place) => place.fullyQualifiedId === placeId);
      if (place) {
        setActivePlace(place);
        this.handlePaymentKey();
      } else {
        setActivePlace(null);
        setMenuStatus(true);
      }
      const targetPlaceId = (place && place.fullyQualifiedId) || placeId;
      const destMenuId =
        menuId || (place && place.activeMenu && place.activeMenu.id);
      destination = `/config/${configId}/venue/${venueId}/place/${targetPlaceId}/menu/${destMenuId}`;
      if (category) {
        destination += `/cat/${category}`;
      }
    } else {
      this.handlePaymentKey();
    }

    this.setState({ havePlaces: true });
    let url = this.props.match.url;
    url.includes('/ptw/change') || url.includes('/order/')
      ? history.replace(`${this.props.match.url}`)
      : history.replace(destination);
  }

  handlePaymentKey() {
    const { activePlace, getPaymentKeyFor } = this.props;
    if (!activePlace || !activePlace.fullyQualifiedId) {
      return;
    }

    getPaymentKeyFor(activePlace);
  }

  clearQueryParams() {
    const { match } = this.props;
    window.history.replaceState(null, '', `/#${match.url}`);
  }

  render() {
    const { haveConfig, haveToken, havePlaces } = this.state;
    const { errorStatus } = this.props;

    if (errorStatus.error) {
      console.error(errorStatus.error);
      return <ErrorPage location={{ state: errorStatus }} />;
    }

    if (haveConfig && haveToken && havePlaces) {
      return <App />;
    }

    return (
      <div>
        <Spinner show={true} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    identity: state.token.identity,
    places: state.places.data,
    activePlace: state.places.activePlace,
    webConfig: state.webConfig,
    errorStatus: state.errorStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setError: (errorMessage, error) => {
      dispatch(_setError({ ...errorMessage, error }));
    },
    initFlagsmith: () => {
      dispatch(_initFlagsmith());
    },
    loadLocalization: (data) => {
      dispatch(loadLocalization(data));
    },
    resetConfig: () => {
      dispatch(resetConfig());
    },
    resetPromptedAuth: () => {
      dispatch(actions.resetPromptedAuth());
    },
    resetOrderData: () => {
      dispatch(actions.resetOrderData());
    },
    fetchWebConfig: (callback, errCallback) => {
      dispatch(api.fetchWebConfig(callback, errCallback));
    },
    useRefreshToken: (token, callback, errCallback) => {
      dispatch(api.useRefreshToken(token, callback, errCallback));
    },
    saveToken: (data) => {
      dispatch(actions.saveToken(data));
    },
    getProfile: () => {
      dispatch(api.getProfile());
    },
    getPlaces: (callback, errCallback) => {
      dispatch(api.getPlaces(callback, errCallback));
    },
    savePlaces: (data) => {
      dispatch(actions.savePlaces(data));
    },
    setActivePlace: (place) => {
      dispatch(actions.setActivePlace(place));
    },
    setMenuStatus: (status) => {
      dispatch(actions.setMenuStatus(status));
    },
    getPaymentKeyFor: (place) => {
      dispatch(api.getPaymentKey(place.fullyQualifiedId));
    },
    storeQueryParams: (params) => {
      dispatch(actions.storeQueryParams(params));
    },
    getVenue: (data, errCallback) => {
      dispatch(getVenue(data, errCallback));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Boot));
