import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { getCheckinLink } from '../../helpers/checkinLink/checkinLink';
import { Order, SubmitOrderRequest } from '../../models/order.model';
import { ActionType } from '../action-types';
import { PICKUP_TIME_WINDOWS_FEATURE } from '../actions/api/features';
import { Action } from '../actions/submitOrder.actions';
import store from '../index';
import { getActivePlaceContent } from '../selectors';
import { checkinOrder } from './checkinOrder.action-creator';

export const submitOrder = (history: any) => (dispatch: Dispatch<Action>) => {
  const {
    order,
    token,
    places: { activePlace },
    menu,
    query,
    places,
    features,
    checkinTimeWindows: {
      selectedCheckinTimeWindow: { windowId },
    },
    orderReadiness: { pickupZones, deliveryZones },
    zoneDetails: { zone, zoneUserCustomValue },
    webConfig: { apiUrl, customerId, venueId },
  } = store.getState();

  dispatch({
    type: ActionType.SUBMIT_ORDER,
    payload: order,
  });

  const { configFile } = query.params;

  // Default to first listed zone if there is one
  const defaultZone = pickupZones.length > 0 ? pickupZones[0].pickupZoneId : '';

  const selectedZone =
    zone ||
    defaultZone ||
    (deliveryZones.length > 0 && deliveryZones[0]) ||
    'INSIDE_TAKE_OUT';

  const zoneRequiresEntry =
    zone &&
    pickupZones?.filter((pz: any) => pz?.pickupZoneId === zone)?.length > 0
      ? pickupZones.filter((pz: any) => pz?.pickupZoneId === zone)[0]
          ?.requiresEntry
      : false;

  const placeFeatures = getActivePlaceContent(places).features;
  const isPTWEnabled =
    placeFeatures['pickupTimeWindowsEnabled'] &&
    (features ? features[PICKUP_TIME_WINDOWS_FEATURE] : false);

  const isCombineSubmitAndCheckin =
    placeFeatures &&
    placeFeatures.uiControls &&
    placeFeatures.uiControls['combineSubmitAndCheckin'];

  if (order) {
    const orderRequest: SubmitOrderRequest = {
      customerEmail: order.customerEmail,
      customerName: order.customerName,
      customerPhone: order.customerPhone,
      checkinLink: getCheckinLink(
        configFile,
        venueId,
        order.orderId,
        token.identity.access,
      ),
      paymentToken: order.paymentToken,
      paymentScheme: order.paymentScheme,
      pickupWindowId: windowId,
      zone: isPTWEnabled ? selectedZone : null,
      customerSpecificOrderDetails: {
        [selectedZone]:
          isPTWEnabled && zoneRequiresEntry && zoneUserCustomValue
            ? zoneUserCustomValue
            : undefined,
        ...order.customerSpecificOrderDetails,
      },
    };

    axios({
      method: 'post',
      url: `${apiUrl}orders/${order.orderId}/submit`,
      headers: {
        Authorization: `Bearer ${token.identity.access}`,
        'X-Customer-ID': customerId,
        'Content-Type': 'application/json',
      },
      data: { ...orderRequest },
    })
      .then((response: AxiosResponse<Order, undefined>) => {
        dispatch({
          type: ActionType.SUBMIT_ORDER_SUCCESS,
          payload: response.data,
        });
        if (isCombineSubmitAndCheckin) {
          checkinOrder(history)(dispatch);
        } else {
          history.push(
            `/config/${configFile}/venue/${venueId}/place/${activePlace.fullyQualifiedId}/menu/${menu.id}/confirmation`,
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionType.SUBMIT_ORDER_FAILURE,
          payload: error.message,
        });
      });
  }
};
