import { Dispatch } from 'react';
import { ActionType } from '../action-types';
import { Action, QueryParam } from '../actions/query.actions';

export const storeQueryParams =
  (queryParams: QueryParam) => (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.STORE_QUERY_PARAMS,
      payload: queryParams,
    });
  };
