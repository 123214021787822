import axios from 'axios';
import { onChangeConfig } from '../webConfig';
import { setApiError } from '../apiError';
import { popPromise, pushPromise } from '../spinner';
import { createPromiseId } from '../../../helpers';

import themeVariables from '../../../styles/themes/_index.scss';

//check if theme is founded on themeList
const isValidTheme = (queryTheme) => {
  if (!themeVariables || !queryTheme) return false;

  const theme = queryTheme.replace('-palette-', '_');
  return themeVariables[`color_canvas_${theme}`] !== undefined;
};

const mapTheme = (theme) => {
  theme = theme.replace('-palette-', '_');
  if (theme !== 'default-theme' && isValidTheme(theme)) {
    return {
      color_primary: themeVariables[`color_primary_${theme}`],
      color_secondary: themeVariables[`color_secondary_${theme}`],
      color_nav: themeVariables[`color_nav_${theme}`],
      color_accent: themeVariables[`color_accent_${theme}`],
      color_discount: themeVariables[`color_discount_${theme}`],
      color_error: themeVariables[`color_error_${theme}`],
      color_canvas: themeVariables[`color_canvas_${theme}`],
      color_card: themeVariables[`color_card_${theme}`],
      color_dark_highlight: themeVariables[`color_dark_highlight_${theme}`],
      color_light_highlight: themeVariables[`color_light_highlight_${theme}`],
      color_text_primary: themeVariables[`color_text_primary_${theme}`],
      color_text_secondary: themeVariables[`color_text_secondary_${theme}`],
      color_text_tertiary: themeVariables[`color_text_tertiary_${theme}`],
      color_button_disabled: themeVariables[`color_button_disabled_${theme}`],
    };
  }
  return null;
};

export const _fetchWebConfig = (
  {
    configFile,
    subPath,
    queryConfig,
    venueId,
    theme,
    submitError,
    checkinError,
  },
  callback,
  errCallback,
) => {
  const promiseId = createPromiseId();

  // Default to config, which is where deployed instances place their config files
  const webappPath = subPath ? `${subPath}/` : 'config/';
  const parts = configFile.split('/');
  var paths = parts.map((p) => p);
  // Append the paths together
  for (let i = 1; i < parts.length; ++i) {
    paths[i] = `${paths[i - 1]}/${parts[i]}`;
  }

  const files = paths.map((path, index) => {
    // if in development, pull from the local QA config folder, if not do the normal
    let origin =
      process.env.NODE_ENV === 'development'
        ? `${process.env.PUBLIC_URL}/${process.env.REACT_APP_NODE_ENV}/`
        : `${window.location.origin}/${webappPath}`;

    return `${origin}${path}.json`;
  });
  const requestOptions = { headers: { 'content-type': 'application/json' } };

  return (dispatch) => {
    dispatch(pushPromise(promiseId));

    // Error section
    dispatch(setApiError('orderSubmit', submitError && parseInt(submitError)));
    dispatch(
      setApiError('orderCheckin', checkinError && parseInt(checkinError)),
    );

    const loaders = files.map((file) => {
      return axios.get(file, requestOptions).catch((error) => {
        // Skip errors when loading the config files
        console.log(error);
        return { data: { error: error } };
      });
    });

    Promise.all(loaders)
      .then((responses) => {
        // If the last item in the list failed, there won't be a proper place config
        if (responses[responses.length - 1].error) {
          throw new Error(responses[responses.length - 1].error);
        }

        let configData = { configFile };
        responses.forEach(
          (response) => (configData = { ...configData, ...response.data }),
        );

        //Select CSS Theme by QueryParam or venueId
        const selectedTheme = isValidTheme(theme)
          ? theme
          : configData.themes && venueId
          ? configData.themes[venueId] || 'default-theme'
          : 'default-theme';
        const themeVariables = mapTheme(selectedTheme);

        // Handle pick up location config
        if (queryConfig.pickupLoc || queryConfig.pickupText) {
          // Move to pickupFromUrl to group, and much readable
          queryConfig.pickupFromUrl = {
            hasPickupFromUrl: true,
            pickupLoc: queryConfig.pickupLoc,
            pickupText: queryConfig.pickupText,
          };
        } else {
          queryConfig.pickupFromUrl = { hasPickupFromUrl: false };
        }
        // Not needed anymore, see webConfig.pickupFromUrl
        delete queryConfig.pickupLoc;
        delete queryConfig.pickupText;

        // Explicit query parameters are applied, such as overrideInVenue from query param
        configData = {
          ...configData,
          ...queryConfig,
          selectedTheme,
          themeVariables,
        };

        // URL parameters are then applied
        if (venueId) {
          configData.venueId = venueId;
        }

        dispatch(onChangeConfig(configData));

        // Let the caller know it worked
        if (callback) {
          callback(configData);
        }
      })
      .catch((error) => {
        if (errCallback) {
          errCallback(error);
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};
