import { Dispatch } from 'react';
import { Action } from '../actions/ageRestrictionPrompt.action';
import { ActionType } from '../action-types';

export const setAgeRestrictionPrompt =
  (setAgeRestrictionPrompt: boolean) => (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_AGE_RESTRICTION_PROMPT,
      payload: setAgeRestrictionPrompt,
    });
  };

export const acceptAgeRestriction =
  (isAgeRestrictionAccepted: boolean) => (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.AGE_RESTRICTION_ACCEPTED,
      payload: isAgeRestrictionAccepted,
    });
  };

export const declineAgeRestriction =
  (isAgeRestrictionDeclined: boolean) => (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.AGE_RESTRICTION_DECLINED,
      payload: isAgeRestrictionDeclined,
    });
  };
