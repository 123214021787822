import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import BillingInfo from '../Billing/billingInfo';
import Button from '../Button';
import Spinner from '../Spinner';
import AccessoPayment from './accessoPayment';

import { redirectToErrorPage } from '../../helpers';
import { paymentError } from '../../helpers/errorMessages';

import {
  handleDefaultZone,
  handleDefaultZoneUserCustomValue,
} from '../../helpers/defaultZone';
import {
  reportCompletedPayment,
  reportOrderSubmitted,
  reportPageView,
} from '../../helpers/googleAnalytics';
import { history } from '../../helpers/history';
import { PTWRoutes, routePTW } from '../../helpers/routePTW/routePTW';
import {
  _loadAvailableWindows,
  _submitOrder,
} from '../../redux/actions/api/order';
import {
  ACCESSO_PAY_STATE,
  BILLING_INFORMATION_STATE,
  LOADING_STATE,
  _setPaymentStatus,
} from '../../redux/actions/payment';
import { getActivePlaceContent } from '../../redux/selectors';

import {
  checkoutStep,
  GACheckoutStep,
} from '../../helpers/googleAnalytics/checkoutSteps';
import './index.scss';
class AccessoPay extends React.Component {
  constructor(props) {
    super(props);

    const { webConfig, pickupZones } = props;
    const { firstName, lastName, email } = props.profile;
    const userName = firstName && lastName ? `${firstName} ${lastName}` : '';

    this.state = {
      userName: userName,
      userEmail: email || '',
      zone: handleDefaultZone({ webConfig, pickupZones }),
      zoneUserCustomValue: handleDefaultZoneUserCustomValue({ webConfig }),
      infoValid: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleValidity = this.handleValidity.bind(this);
    this.handleContinueToPayment = this.handleContinueToPayment.bind(this);
    this.handleInitFailure = this.handleInitFailure.bind(this);
    this.handlePaymentComplete = this.handlePaymentComplete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { setPaymentStatus } = this.props;
    setPaymentStatus(BILLING_INFORMATION_STATE);
  }

  handleChange({ name, value }, callback) {
    this.setState({ [name]: value }, callback);
  }

  handleValidity(infoValid) {
    this.setState({ infoValid });
  }

  handleContinueToPayment() {
    const { infoValid } = this.state;
    if (infoValid) {
      const { location, setPaymentStatus } = this.props;
      reportPageView(
        location &&
          location.pathname &&
          location.pathname.replace('billing', 'payment'),
      );
      setPaymentStatus(ACCESSO_PAY_STATE);
    }
  }

  handlePaymentComplete(e) {
    const {
      payload: { authId, method, cardType },
    } = e;
    const { userName, userEmail, zone, zoneUserCustomValue } = this.state;
    const {
      order,
      match: { params },
      paymentKeys,
      submitOrder,
    } = this.props;

    const userInfo = { userEmail, userName, zone, zoneUserCustomValue };
    const payment = {
      id: authId,
      scheme: paymentKeys.paymentProvider,
    };
    checkoutStep(
      GACheckoutStep.PAYMENT_COMPLETE,
      method === 'creditcard' ? cardType : method,
    );

    reportCompletedPayment();
    const redirect = () => {
      reportOrderSubmitted();
      this.navigateToPage('/confirmation');
    };
    const onPTWUnavailable = () => {
      routePTW(
        PTWRoutes.PTW_UNAVAILABLE_ROUTE,
        params.config,
        params.venue,
        order.orderId,
      );
    };
    submitOrder(
      params,
      userInfo,
      payment,
      redirect,
      null,
      null,
      false,
      onPTWUnavailable,
    );
  }

  handleCancel(e) {
    const { accessoPaySessionId } = e;
    const { setPaymentStatus } = this.props;
    setPaymentStatus(BILLING_INFORMATION_STATE);
    this.setState({ accessoPaySessionId });
  }

  handleInitFailure(e) {
    console.error(e);
    redirectToErrorPage(history, paymentError);
  }

  handleSubmit() {
    const { userName, userEmail, zone, zoneUserCustomValue } = this.state;
    const {
      order,
      match: { params },
      submitOrder,
    } = this.props;

    const userInfo = { userEmail, userName, zone, zoneUserCustomValue };
    const redirect = () => {
      reportOrderSubmitted();
      this.navigateToPage('/confirmation');
    };
    const onPTWUnavailable = () => {
      routePTW(
        PTWRoutes.PTW_UNAVAILABLE_ROUTE,
        params.config,
        params.venue,
        order.orderId,
      );
    };
    submitOrder(
      params,
      userInfo,
      null,
      redirect,
      null,
      null,
      false,
      onPTWUnavailable,
    );
  }

  isPTWValid = () => {
    const {
      placeFeatures,
      checkinTimeWindows: { selectedCheckinTimeWindow },
    } = this.props;
    return (
      !placeFeatures['pickupTimeWindowsEnabled'] || selectedCheckinTimeWindow
    );
  };

  render() {
    const { userName, userEmail } = this.state;
    const {
      payment: { status },
    } = this.props;
    const billingInfo = { userName, userEmail };

    switch (status) {
      case BILLING_INFORMATION_STATE: {
        return this.renderUserInfoForm();
      }
      case ACCESSO_PAY_STATE: {
        return (
          <AccessoPayment
            billingInfo={billingInfo}
            handleInitFailure={this.handleInitFailure}
            handlePaymentComplete={this.handlePaymentComplete}
            handleCancel={this.handleCancel}
          />
        );
      }
      case LOADING_STATE: {
        return (
          <div>
            <Spinner show={true} />
          </div>
        );
      }
      default: {
        return <div>oops</div>;
      }
    }
  }

  renderUserInfoForm() {
    const {
      localization,
      order,
      pickupZones,
      webContent,
      features,
      loadAvailableWindows,
      checkinTimeWindows,
      placeFeatures,
      placeControls,
    } = this.props;
    const orderTotal = `${
      order && order.displayOrderBillTotal ? order.displayOrderBillTotal : ''
    }`;
    const isPaymentRequired = order && order.orderTotal > 0;
    const { userName, userEmail, zone, zoneUserCustomValue, infoValid } =
      this.state;

    return (
      <div className="accesso-pay-form">
        <BillingInfo
          userName={userName}
          userEmail={userEmail}
          zone={zone}
          pickupZones={pickupZones}
          zoneUserCustomValue={zoneUserCustomValue}
          localization={localization.billing}
          webContent={webContent}
          loadAvailableWindows={loadAvailableWindows}
          placeFeatures={placeFeatures}
          placeControls={placeControls}
          selectedCheckinTimeWindow={
            checkinTimeWindows.selectedCheckinTimeWindow
          }
          features={features}
          handleChange={this.handleChange}
          handleValidity={this.handleValidity}
        />

        {isPaymentRequired && (
          <div className="submit-button-wrapper">
            <Button
              onClick={this.handleContinueToPayment}
              disabled={!infoValid || !this.isPTWValid()}>
              <div className="button-content-wrapper">
                <span className="primary-text header-text uppercase">
                  {localization.billing.continueToPayment}
                </span>
                <span className="secondary-text">{orderTotal}</span>
              </div>
            </Button>
          </div>
        )}
        {!isPaymentRequired && (
          <div className="submit-button-wrapper">
            <Button
              onClick={this.handleSubmit}
              disabled={!infoValid || !this.isPTWValid()}>
              <div className="button-content-wrapper">
                <span className="primary-text header-text uppercase">
                  {localization.billing.submitOrder}
                </span>
                <span className="secondary-text">{orderTotal}</span>
              </div>
            </Button>
          </div>
        )}
      </div>
    );
  }

  navigateToPage(url) {
    const {
      match: {
        params: { placeId, menuId, config, venue },
      },
    } = this.props;
    history.push(
      `/config/${config}/venue/${venue}/place/${placeId}/menu/${menuId}${url}`,
    );
  }
}

const mapStateToProps = (state) => ({
  paymentKeys: state.paymentKey.keys,
  profile: state.profile,
  localization: state.localization,
  order: state.order,
  pickupZones: state.orderReadiness.pickupZones,
  webContent: getActivePlaceContent(state.places).webContent || {},
  webConfig: state.webConfig,
  features: state.features,
  checkinTimeWindows: state.checkinTimeWindows,
  payment: state.payment,
  placeFeatures: getActivePlaceContent(state.places).features || {},
  placeControls: getActivePlaceContent(state.places).controls || {},
});

const mapDispatchToProps = (dispatch) => ({
  submitOrder: (...args) => {
    dispatch(_submitOrder(...args));
  },
  loadAvailableWindows: (...args) => {
    dispatch(_loadAvailableWindows(...args));
  },
  setPaymentStatus: (...args) => {
    dispatch(_setPaymentStatus(...args));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccessoPay),
);
