import React from 'react';
import Input from '../FormFields/Input';

export default (props) => {
  const {
    title,
    label,
    onChange,
    initialValue,
    specialRequestsEnabled,
    maxLength,
  } = props;
  if (specialRequestsEnabled && maxLength > 0) {
    return (
      <React.Fragment>
        <h3 className="title">{title}</h3>
        <section className="special-request contains-forms content form-padding">
          <Input
            type={'text'}
            id={'specialRequestInput'}
            label={label}
            name={'specialRequestInput'}
            displayCharCounter={true}
            onChange={onChange}
            placeholder={'optional'}
            maxLength={maxLength}
            initialValue={initialValue}
          />
        </section>
      </React.Fragment>
    );
  }
  return '';
};
