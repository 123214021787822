import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash/lang';
import { noop } from 'lodash/util';
import Button from '../Button';
import { determinePage, MENU_PAGE, VIEW_ORDER_PAGE } from '../../helpers';
import { _createOrder, _updateOrder } from '../../redux/actions/api/order';
import './index.scss';
import AgeRestrictionPrompt from '../Cart/ageRestrictionPrompt';

class PageButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      action: props.onClick,
      showAgeRestrictionPrompt: false,
    };

    this.viewOrder = this.viewOrder.bind(this);
    this.viewBillingInformation = this.viewBillingInformation.bind(this);
  }

  viewOrder() {
    const { createOrder, order, updateOrder, match } = this.props;

    const redirect = () => {
      this.navigateToPage('/order');
    };

    if (!order.orderId) {
      createOrder(match.params, redirect);
    } else {
      updateOrder(match.params, redirect);
    }
  }

  viewBillingInformation() {
    const {
      createOrder,
      order,
      updateOrder,
      match,
      isAgeRestrictionPromptSet,
    } = this.props;

    const redirect = () => {
      this.navigateToPage('/billing');
    };

    if (isAgeRestrictionPromptSet) {
      this.setState({ showAgeRestrictionPrompt: true });
      return;
    }

    if (!order.orderId) {
      createOrder(match.params, redirect);
    } else {
      updateOrder(match.params, redirect);
    }
  }

  navigateToPage(url) {
    const {
      match: {
        params: { config, venue, placeId, menuId },
      },
    } = this.props;
    this.props.history.push(
      `/config/${config}/venue/${venue}/place/${placeId}/menu/${menuId}${url}`,
    );
  }

  renderButton() {
    const {
      disabled: disabledProps,
      disabledButClickable,
      location,
      order,
      popup,
      prevPlaceId,
      activePlace,
      localization,
    } = this.props;

    const page = determinePage(location.pathname);
    let primaryText = '';
    let secondaryText = '';
    let disabled = !!disabledProps;
    let onClick = noop;

    switch (page) {
      case MENU_PAGE: {
        primaryText = localization.ctaButton.viewOrder;

        secondaryText = `${order.totalQuantity} ${localization.ctaButton.items}`;

        onClick = this.viewOrder;
        // disable the view order button when user has order in other location
        disabled =
          order.totalQuantity === 0 ||
          (prevPlaceId && prevPlaceId !== activePlace.fullyQualifiedId);
        break;
      }
      case VIEW_ORDER_PAGE: {
        primaryText =
          order.totalQuantity > 0
            ? localization.ctaButton.continue
            : localization.ctaButton.returnToMenu;

        if (order.orderBillTotal) {
          secondaryText = order.displayOrderBillTotal;
        }
        if (order.totalQuantity > 0) {
          onClick = this.viewBillingInformation;
        } else {
          onClick = () => {
            this.navigateToPage(`/`);
          };
        }
        break;
      }
      default:
        break;
    }

    if (popup.isOpen) {
      primaryText = popup.isEditing
        ? localization.ctaButton.updateOrder
        : localization.ctaButton.addToOrder;

      secondaryText = '';
      onClick = this.props.onClick;
      disabled = disabledProps;
    }

    const isEmptySecondaryText = isEmpty(secondaryText);

    return (
      <>
        <Button
          className={`page-button-content ${
            disabledButClickable ? 'disabled' : ''
          }`}
          onClick={onClick}
          disabled={disabled}>
          <div
            className={`button-content-wrapper ${
              isEmptySecondaryText ? 'center' : ''
            }`}>
            <span className="primary-text header-text uppercase">
              {primaryText}
            </span>
            {!isEmptySecondaryText && (
              <span className="secondary-text">{secondaryText}</span>
            )}
          </div>
        </Button>
        <AgeRestrictionPrompt
          match={this.props.match}
          navigateToPage={this.navigateToPage.bind(this)}
          showAgeRestrictionPrompt={this.state.showAgeRestrictionPrompt}
          onPromptClose={() => {
            this.setState({ showAgeRestrictionPrompt: false });
          }}></AgeRestrictionPrompt>
      </>
    );
  }

  render() {
    let buttonComponent = this.renderButton();
    const { className, inline } = this.props;

    return (
      <div
        className={`page-button-wrapper ${
          inline ? '' : 'fixed-bottom bg-color-card'
        } ${className || ''}`}>
        {buttonComponent}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menu,
  order: state.order,
  popup: state.popup,
  prevPlaceId: state.order.placeId,
  activePlace: state.places.activePlace,
  localization: state.localization,
  isAgeRestrictionPromptSet:
    state.ageRestrictionPrompt.isAgeRestrictionPromptSet,
});

const mapDispatchToProps = {
  createOrder: _createOrder,
  updateOrder: _updateOrder,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PageButton),
);
