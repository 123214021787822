import React from 'react';
import { connect } from 'react-redux';

import './CustomerSupportInfo.scss';

interface CustomerSupportProps {
  phoneNumber: string;
}

interface StateProps {
  localization: any;
  phoneNumber: string;
}

type Props = CustomerSupportProps & StateProps;

function CustomerSupportInfo({ localization, phoneNumber }: Props) {
  const hrefPhoneNumber = phoneNumber?.replace(/- /g, '');

  return (
    <div className={'customer-support-wrapper'}>      
      {phoneNumber && (
        <div className="customer-support">
            <div className="color-light-highlight">
            <span className="icon-small icon-i-help"></span>
            </div>
            <div className="font-secondary color-text-primary">
              {localization.customerSupport}:&nbsp;
              <a className="phone-nav" href={`tel:${hrefPhoneNumber}`}>
                  {phoneNumber}
              </a>
            </div>
        </div>
        )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    localization: state.localization,
  };
};

export default connect(mapStateToProps)(CustomerSupportInfo);
