import React, { useRef, useState } from 'react';
import classNames from 'classnames';

import atmParse from './atmParse';
import { getCurrencySymbol } from '../../helpers/format';

import './gratuity.scss';

const formatZero = (currency) => {
  const fmt = new Intl.NumberFormat(navigator.language, {
    currency,
    minimumFractionDigits: 2,
  });
  return fmt.format(0);
};

const shouldUseDecimalPoint = (currency) => {
  return formatZero(currency).indexOf(',') < 0;
};

const setOrToggle = (prev, next) => {
  if (prev === next) {
    return null;
  }
  return next;
};

export default function Gratuity(props) {
  const {
    currency,
    options,
    customEnabled,
    orderGratuity,
    gratuityOption,
    onSelect,
    onError,
    localization,
  } = props;
  const [customAmount, setCustomAmount] = useState(
    !orderGratuity ? '' : orderGratuity.toFixed(2),
  );
  const [customAmountError, setCustomAmountError] = useState(null);
  const customAmountRef = useRef();

  const customSelected = customEnabled && gratuityOption >= options.length;
  const currencySymbol = getCurrencySymbol(currency);

  const updateSelection = (index) => {
    const newIndex = setOrToggle(gratuityOption, index);
    if (newIndex !== null) {
      if (newIndex < options.length) {
        onSelect(options[newIndex].gratuityAmount, newIndex);
        return;
      } else {
        setTimeout(() => {
          // Have to wait a moment for the element to render before focus
          customAmountRef.current && customAmountRef.current.focus();
        }, 50);
        if (!customAmountError) {
          const [parsed] = atmParse(customAmount);
          onSelect(parsed, newIndex);
          return;
        }
      }
    }
    onSelect(0, newIndex);
  };

  const updateCustomAmount = (event) => {
    const { value } = event.target;
    try {
      let [parsed, rewritten] = atmParse(
        value,
        shouldUseDecimalPoint(currency),
      );
      if (parsed === 0) {
        rewritten = '';
      }
      setCustomAmount(rewritten);
      setCustomAmountError(null);
      onSelect(parsed, options.length);
      onError(false);
    } catch (err) {
      setCustomAmount(value);
      setCustomAmountError(err);
      onError(true);
    }
  };

  return (
    <section className="gratuity-wrapper">
      <div className="gratuity-options">
        {options.map((option, index) => {
          return (
            <div
              className={classNames(
                'gratuity-option',
                'border-color-secondary',
                'color-text-primary',
                gratuityOption === index && 'selected',
                gratuityOption === index && 'bg-color-secondary',
                gratuityOption === index && 'color-text-secondary',
              )}
              key={`gratuity-${index}`}
              onClick={() => updateSelection(index)}>
              <div className="gratuity-percent">
                {option.displayGratuityPercentage}
              </div>
              <div className="gratuity-amount">
                {option.displayGratuityAmount}
              </div>
            </div>
          );
        })}
        {customEnabled && (
          <div
            className={classNames(
              'gratuity-option',
              'custom-amount',
              'border-color-secondary',
              customSelected &&
                'selected bg-color-secondary color-text-secondary',
            )}
            key="gratuity-custom"
            onClick={() => updateSelection(options.length)}>
            <div className="gratuity-amount">
              {localization.customGratuityAmount}
            </div>
          </div>
        )}
      </div>
      <div className={classNames(!customSelected && 'hide')}>
        <label className="color-text-tertiary" htmlFor="custom-gratuity-amount">
          {localization.enterGratuityAmount}
        </label>
        <div className="custom-amount-wrapper">
          {currencySymbol && (
            <div className="currency-symbol">{currencySymbol}</div>
          )}
          <input
            id="custom-gratuity-amount"
            name="gratuity-amount"
            ref={customAmountRef}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*(\.[0-9]{0,2})?"
            value={customAmount}
            placeholder={formatZero(currency)}
            onChange={updateCustomAmount}
          />
        </div>
      </div>
    </section>
  );
}
