import React from 'react';

import './index.scss';

const Button = (props) => {
  return (
    <button
      className={`button bg-color-accent ${props.className}`}
      onClick={props.onClick}
      disabled={props.disabled}>
      {props.children}
    </button>
  );
};

export default Button;
