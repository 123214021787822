export const OPEN_TOAST = 'OPEN_TOAST';
export const CLOSE_TOAST = 'CLOSE_TOAST';

export const openToast = (data) => {
  return {
    type: OPEN_TOAST,
    payload: data,
  };
};

export const closeToast = () => {
  return {
    type: CLOSE_TOAST,
  };
};
