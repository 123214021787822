export const LOAD_CHECKIN_TIME_WINDOWS = 'LOAD_CHECKIN_TIME_WINDOWS';
export const SET_SELECTED_CHECKIN_TIME_WINDOW =
  'SET_SELECTED_CHECKIN_TIME_WINDOW';

export const _setSelectedCheckinTimeWindow = (checkinTimeWindow) => {
  return {
    type: SET_SELECTED_CHECKIN_TIME_WINDOW,
    payload: checkinTimeWindow,
  };
};

export const _setLoadedCheckinTimeWindows = (checkinTimeWindows) => {
  return {
    type: LOAD_CHECKIN_TIME_WINDOWS,
    payload: checkinTimeWindows,
  };
};
