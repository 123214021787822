import React from 'react';
import { connect } from 'react-redux';
import './index.scss';
import classNames from 'classnames';

class Toast extends React.Component {
  render() {
    const { toast } = this.props;

    const toastTextClassNames = classNames('toast-text', 'toast-text-color');

    if (toast && toast.isOpen) {
      return (
        <div className={'toast-padding'}>
          <div className={'toast-wrapper toast-bg-color'}>
            <img src={toast.iconUrl} alt={'map-icon'} />
            <div className={toastTextClassNames}>{toast.message}</div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    toast: state.toast,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
