import axios from 'axios';
import moment from 'moment';
import { createPromiseId } from '../../../helpers';
import { offlineError } from '../../../helpers/errorMessages';
import store from '../../index';
import { _setError } from '../errorStatus';
import { logIn, setErrorLogin } from '../siriusware';
import { popPromise, pushPromise } from '../spinner';

const getPassesRequest = (memberIdOrEmail, password) => {
  const state = store.getState();
  const {
    webConfig: { apiUrl, clientId, venueId },
    token: {
      identity: { access },
    },
  } = state;
  const url = `${apiUrl}passes/places/venue:${venueId}`;
  return axios.post(
    url,
    { email: memberIdOrEmail, password, include: 'passes' },
    {
      headers: {
        Authorization: `Bearer ${access}`,
        'Content-Type': 'application/json',
        'X-Customer-ID': clientId,
      },
    },
  );
};

const filterPasses = (passes) => {
  return passes.filter((pass) => {
    const endOfDay = moment().endOf('day');
    const dateExpiresMoment = moment(pass.dateExpires);
    return pass.voidedBy === '' && dateExpiresMoment.isAfter(endOfDay);
  });
};

export const _login = (memberIdOrEmail, password) => {
  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    getPassesRequest(memberIdOrEmail, password)
      .then(({ data: { data: siriuswareLogin } }) => {
        const passes =
          (siriuswareLogin.siriusware && siriuswareLogin.siriusware.passes) ||
          [];
        dispatch(logIn(filterPasses(passes)));
      })
      .catch((error) => {
        console.error(error);
        if (error.message === 'Network Error') {
          dispatch(_setError({ ...offlineError, error }));
        } else {
          dispatch(setErrorLogin());
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};
