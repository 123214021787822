import { Order } from '../../models/order.model';
import { formatCurrency } from '../format';

export const updateOrderAmounts = (order: Order) => {
  const total = order.orderTotal || 0;
  const gratuity = order.orderGratuity || 0;
  const orderBillTotal = total;
  const displayOrderBillTotal =
    order.displayOrderTotal || formatCurrency(order.currency, orderBillTotal);
  const orderTotal = total - gratuity;
  const displayOrderTotal = formatCurrency(order.currency, orderTotal);

  const gratuityConfig = order.gratuityConfig || {};
  const gratuityOptions = (gratuityConfig.gratuityOptions || []).map(
    (option) => {
      if (option.gratuityAmount !== null) {
        return option;
      }

      const fraction = option.gratuityPercentage || 0;
      const gratuityAmount =
        Math.abs(Math.round(orderTotal * fraction * 100)) / 100;
      const displayGratuityAmount = formatCurrency(
        order.currency,
        gratuityAmount,
      );
      return {
        ...option,
        gratuityAmount,
        displayGratuityAmount,
      };
    },
  );
  gratuityConfig.gratuityOptions = gratuityOptions;

  return {
    ...order,
    orderBillTotal,
    displayOrderBillTotal,
    orderTotal,
    displayOrderTotal,
  };
};
