const extract = (jwt) => JSON.parse(atob(jwt.split('.')[1]));

export const isExpired = (exp) => {
  if (!exp) return true;
  const now = Math.floor(Date.now() / 1000);
  return exp < now;
};

export const decodeTokens = (resp) => {
  const access = resp.access_token;
  try {
    if (access) {
      const accessParams = extract(access);
      const refresh = resp.refresh_token;
      const customer = accessParams.organization;
      const clientId = accessParams.client_id;
      const userId = accessParams.uuid;
      return {
        access,
        refresh,
        customer,
        clientId,
        accessParams,
        userId,
      };
    }
  } catch (e) {
    console.warn(e);
  }
  // The access_token in the URL was invalid
  return {};
};

export const checkTokenFreshness = (tokens) => {
  const accessParams = extract(tokens.access);
  const refreshParams = (tokens.refresh && extract(tokens.refresh)) || {
    exp: 0,
  };
  return {
    accessExpired: isExpired(accessParams.exp),
    refreshExpired: isExpired(refreshParams.exp),
  };
};

export const userIsAnonymous = (accessParams) => {
  return (
    accessParams &&
    accessParams.scope &&
    accessParams.scope.indexOf('anonymous') >= 0
  );
};
