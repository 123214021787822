import axios from 'axios';
import { stringify } from 'query-string';
import { createPromiseId } from '../../../helpers';
import { decodeTokens } from '../../../helpers/token';
import store from '../../index';
import { saveProfile } from '../profile';
import { popPromise, pushPromise } from '../spinner';
import { saveToken } from '../token';

const getToken = (promiseId, data, callback, errCallback) => {
  const state = store.getState();
  const { webConfig } = state;

  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return axios
      .post(`${webConfig.apiUrl}v4/tokens`, stringify(data), {
        headers: {
          Authorization: `Basic ${webConfig.basicAuth}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        dispatch(saveToken(decodeTokens(response.data)));
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (errCallback) {
          errCallback(error);
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const _getAnonymousToken = (callback, errCallback) => {
  let promiseId = createPromiseId();
  const data = {
    grant_type: 'client_credentials',
    scope: 'anonymous',
  };
  return getToken(promiseId, data, callback, errCallback);
};

export const useRefreshToken = (token, callback, errCallback) => {
  let promiseId = createPromiseId();
  const data = {
    grant_type: 'refresh_token',
    refresh_token: token,
  };
  return getToken(promiseId, data, callback, errCallback);
};

export const getProfile = () => {
  const state = store.getState();
  const { webConfig, token } = state;

  if (!token?.identity?.access) {
    return () => {};
  }

  const access = token.identity.access;
  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return axios
      .get(`${webConfig.apiUrl}v2/accounts/me`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then((response) => {
        const profile = response.data.profile;
        if (profile) {
          const updatedProfile = {};
          ['firstName', 'lastName', 'email'].forEach((key) => {
            const value = profile[key]?.value;
            if (value) {
              updatedProfile[key] = value;
            }
          });
          dispatch(saveProfile(updatedProfile));
        }
      })
      .catch((error) => {
        // Ignore the error - profile is optional
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};
