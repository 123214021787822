export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export const _setError = (error) => {
  return {
    type: SET_ERROR,
    payload: error,
  };
};

export const _clearError = () => {
  return {
    type: CLEAR_ERROR,
  };
};
