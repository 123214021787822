export const parseCacheControl = (headers) => {
  const parsed = {};
  const values = (headers['cache-control'] || '').split(',');
  values.forEach((v) => {
    const split = v.replace(' ', '').split('=');
    switch (split.length) {
      case 1: {
        const [key] = split;
        if (key.length > 0) {
          parsed[key] = true;
        }
        break;
      }
      case 2: {
        const [key, value] = split;
        parsed[key] = parseInt(value) || value;
        break;
      }
      default: {
        console.error(`unexpected cache-control value: ${v}`);
      }
    }
  });
  return parsed;
};
