import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'react';
import { ActionType } from '../action-types';
import { CLOSE_TOAST2, OPEN_TOAST2 } from '../actions/toast2';
import store from '../index';

export interface CancelOrderResponse {
  orderId: string;
  orderStatus: string;
}

export const cancelOrder =
  (orderId: string, history: any) => (dispatch: Dispatch<any>) => {
    const {
      token,
      webConfig: { apiUrl, customerId, venueId, configFile },
      localization,
    } = store.getState();

    dispatch({
      type: ActionType.CANCEL_ORDER,
      payload: orderId,
    });

    axios({
      method: 'delete',
      url: `${apiUrl}orders/${orderId}`,
      headers: {
        Authorization: `Bearer ${token.identity.access}`,
        'X-Customer-ID': customerId,
        'Content-Type': 'application/json',
      },
    })
      .then((response: AxiosResponse<CancelOrderResponse, undefined>) => {
        dispatch({
          type: ActionType.CANCEL_ORDER_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: OPEN_TOAST2,
          payload: {
            title: localization.order.orderCancelled,
            message: null,
            buttons: [
              {
                label: localization.button.ok,
                action: () => {
                  dispatch({ type: CLOSE_TOAST2 });
                  history.push(`/config/${configFile}/venue/${venueId}/places`);
                },
              },
            ],
          },
        });
      })
      .catch((error: any) => {
        dispatch({
          type: ActionType.CANCEL_ORDER_FAILURE,
          payload: error.message,
        });
        dispatch({
          type: OPEN_TOAST2,
          payload: {
            title: localization.error.error,
            message: localization.error.errorMessage,
            buttons: [
              {
                label: localization.button.ok,
                action: () => {
                  dispatch({ type: CLOSE_TOAST2 });
                },
              },
            ],
          },
        });
      });
  };
