export interface DiscountCode {
  discounts: Discount[];
  problems: DiscountProblem[];
  currentAppliedCode?: string;
  isPopup?: boolean;
  seasonPassDiscount?: Discount;
}

export interface lineItemOption {
  lineItemId: string;
  displayName: string;
  menuOption?: any;
  checked?: boolean;
}

export interface Discount {
  applicationType: string;
  displayName: string;
  id: string;
  lineItemOptions: lineItemOption[];
  lineItems?: string[];
  percent?: number;
  status: string;
  type: string;
  value?: number;
  isSelected?: boolean;
}

export interface DiscountProblem {
  discountCode: string;
  errors: any[];
}

export enum DiscountApplicationType {
  ORDER_LEVEL_DISCOUNT = 'ORDER',
  ITEM_LEVEL_DISCOUNT = 'ITEM',
}

export enum DiscountType {
  ORDER_LEVEL_DISCOUNT = '5',
  ITEM_LEVEL_DISCOUNT = '1',
}
