export const inputValidation = (
  value,
  emptyErrorMessage,
  invalidErrorMessage = null,
  regexValidation = null,
) => {
  if (emptyErrorMessage && value === '') return emptyErrorMessage;
  if (invalidErrorMessage && regexValidation && !regexValidation.test(value))
    return invalidErrorMessage;
  return '';
};

export const emailValidation = (
  email,
  emptyErrorMessage,
  invalidErrorMessage,
) => {
  const regex =
    /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~.-]{1,64}@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*$/;
  return inputValidation(email, emptyErrorMessage, invalidErrorMessage, regex);
};

export const selectValidation = (
  selectedItem,
  items,
  emptySelectionErrorMessage,
) => {
  if (!items || !items.length || items.length === 1) return '';
  return selectedItem ? '' : emptySelectionErrorMessage;
};
