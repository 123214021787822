import { OPEN_TOAST, CLOSE_TOAST } from '../actions/toast';
import mapIcon from '../../assets/icons/i-maps.svg';

const defaultState = {
  isOpen: false,
  iconUrl: mapIcon,
  message: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_TOAST:
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case CLOSE_TOAST:
      return {
        ...state,
        ...defaultState,
      };
    default:
      return state;
  }
};
