import { SAVE_LOCATION, SAVE_IN_VENUE } from '../actions/location';

const defaultState = {
  asked: false,
  error: null,
  lat: null,
  lon: null,
  inVenue: false,
};

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case SAVE_LOCATION: {
      return {
        ...state,
        ...data,
      };
    }
    case SAVE_IN_VENUE: {
      return {
        ...state,
        ...data,
      };
    }
    default:
      return state;
  }
};
