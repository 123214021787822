import React, { useState } from 'react';
import classNames from 'classnames';

const Select = ({
  onChange,
  errorMessage,
  selectOneMessage,
  hasMsg,
  initialValue,
  items,
  label,
  id,
  name,
}) => {
  const [selectedItem, setSelectedItem] = useState(initialValue || '');

  const handleChange = (event) => {
    onChange(name, event.target.value);
    setSelectedItem(event.target.value);
  };

  const sanitizedItems = items.filter(
    (z) => !!z.label && (!z.requiresEntry || !!z.entryPrompt),
  );

  return (
    <div
      className={classNames(
        'form-group',
        !!errorMessage && 'form-error',
        hasMsg && 'pickup-padding',
      )}>
      <label htmlFor={id} className="select-label color-text-tertiary">
        {label}
      </label>
      <select
        name={name}
        className="bg-color-card"
        id={id}
        value={selectedItem}
        onChange={handleChange}
        onBlur={handleChange}
        required>
        <option value="">{selectOneMessage}</option>
        {sanitizedItems.map((item, index) => (
          <option
            value={item.pickupZoneId}
            key={index}
            data-testid={item.pickupZoneId}>
            {item.label}
          </option>
        ))}
      </select>
      {!!errorMessage && <div className="error-label">{errorMessage}</div>}
    </div>
  );
};

export default Select;
