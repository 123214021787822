import React from 'react';

import Checkbox from './Checkbox';
import RadioButton from './RadioButton';

function getOptions(item, group) {
  const selectedModifiers = item.modifiers
    .filter((mod) => mod.modifierGroupId === group.id)
    .reduce((selected, mod) => {
      selected[mod.modifierId] = true;
      return selected;
    }, {});
  return group.modifiers.map((mod) => {
    mod.isSelected = !!selectedModifiers[mod.id];
    return mod;
  });
}

function SingleSelectGroup(props) {
  const { item, group, updateModifiers } = props;
  const options = getOptions(item, group);

  const onClick = (action, modifierId, isChecked) => {
    const modifiers = item.modifiers.filter(
      (mod) => mod.modifierGroupId !== group.id,
    );
    if (isChecked) {
      modifiers.push({ action, modifierId, modifierGroupId: group.id });
    }
    updateModifiers(modifiers);
  };

  return (
    <div>
      {options.map((option) => {
        const key = `radio-modifier-${option.id}`;
        return (
          <RadioButton
            key={key}
            {...option}
            modifierGroupId={group.id}
            group="modifiers"
            checked={option.isSelected}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
}

function MultiSelectGroup(props) {
  const { item, group, updateModifiers } = props;
  const options = getOptions(item, group);

  const numSelected = options.filter((o) => o.isSelected).length;
  const maxModifiersSelected = group.max !== null && group.max <= numSelected;

  const onClick = (action, modifierId, isChecked) => {
    const modifiers = item.modifiers.filter(
      (mod) => mod.modifierId !== modifierId,
    );
    if (isChecked) {
      modifiers.push({ action, modifierId, modifierGroupId: group.id });
    }
    updateModifiers(modifiers);
  };

  return (
    <div>
      {options.map((option) => {
        const key = `checkbox-modifier-${option.id}`;
        return (
          <Checkbox
            key={key}
            {...option}
            modifierGroupId={group.id}
            group="modifiers"
            checked={option.isSelected}
            disabled={!option.isSelected && maxModifiersSelected}
            onClick={onClick}
          />
        );
      })}
    </div>
  );
}

export default (props) => {
  const { localization, group } = props;

  const selectHeader = (localization && localization.selectHeader) || '%NAME%';
  const header =
    group.displayName && selectHeader.replace('%NAME%', group.displayName);

  let selectionRequirements = '';
  const isRequired = group.min === group.max;
  if (isRequired) {
    const localizedRequired =
      localization &&
      (group.min === 1
        ? localization.countRequiredSingular
        : localization.countRequiredPlural);
    selectionRequirements =
      localizedRequired && localizedRequired.replace('%COUNT%', group.min);
  } else {
    if (group.min > 0) {
      selectionRequirements =
        localization &&
        localization.countMin &&
        localization.countMin.replace('%COUNT%', group.min);
      if (group.max !== null) {
        selectionRequirements = `${selectionRequirements} - `;
      }
    }
    if (group.max !== null) {
      const countMax =
        localization &&
        localization.countMax &&
        localization.countMax.replace('%COUNT%', group.max);
      selectionRequirements = countMax && `${selectionRequirements}${countMax}`;
    }
  }

  const isSingleSelect = group.min === 1 && group.max === 1;

  return (
    <div key={`modifierGroup-${group.id}`}>
      <div className="modifier-group-header">
        <div className="gray-header header-secondary-text uppercase color-text-tertiary">
          {header}
        </div>
        <div className="gray-header header-secondary-text uppercase color-text-tertiary">
          {selectionRequirements}
        </div>
      </div>
      {isSingleSelect ? (
        <SingleSelectGroup {...props} />
      ) : (
        <MultiSelectGroup {...props} />
      )}
    </div>
  );
};
