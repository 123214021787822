import store from '../../index';
import axios from 'axios';
import moment from 'moment';
import { menuNotFound, saveMenu, setMenuStatus } from '../menu';
import { pushPromise, popPromise } from '../spinner';
import {
  ORDER_OPERATION_CREATE,
  determineOrderingWindows,
  createPromiseId,
  redirectToErrorPage,
  checkIfWillBeOpenLater,
} from '../../../helpers';
import { openToast2 } from '../toast2';
import { history } from '../../../helpers/history';
import { offlineError } from '../../../helpers/errorMessages';
import { parseCacheControl } from '../../../helpers/cacheControl';

export const _getMenu = (placeId, menuId, onSuccess, onFinally) => {
  const state = store.getState(),
    {
      orderReadiness,
      webConfig: { apiUrl, genericErrorTitle, genericErrorMessage },
    } = state;

  const { status } = determineOrderingWindows(
    moment(),
    orderReadiness,
    ORDER_OPERATION_CREATE,
    'MENU',
  );
  const willBeOpenLaterAt = checkIfWillBeOpenLater(orderReadiness);

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    dispatch(setMenuStatus(status !== false, willBeOpenLaterAt));

    return axios
      .get(
        `${apiUrl}menus/${menuId}/places/${placeId}?abbreviateSchedules=false`,
        {
          headers: {
            Authorization: `Bearer ${state.token.identity.access}`,
            'x-menu-id': menuId,
            'x-place-id': placeId,
          },
        },
      )
      .then((response) => {
        const cacheControl = parseCacheControl(response.headers);
        const data = {
          ...response.data,
          cacheControl,
        };
        dispatch(saveMenu(data));
        if (onSuccess) {
          onSuccess(response.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          return dispatch(menuNotFound());
        }
        if (error.message === 'Network Error') {
          if (history.location.pathname !== '/error') {
            redirectToErrorPage(history, offlineError);
          }
        } else if (error.response.status !== 404) {
          dispatch(
            openToast2({
              title: genericErrorTitle,
              message: genericErrorMessage,
            }),
          );
        }
      })
      .finally(() => {
        onFinally();
        dispatch(popPromise(promiseId));
      });
  };
};
