import { Product } from './product.model';

export interface Order {
  bundles?: any[];
  checkinLink?: string;
  currency?: string;
  customerName?: string;
  customerEmail?: string;
  customerPhone?: string;
  orderId: string;
  orderStatus?: string;
  orderSubtotal?: number;
  displayOrderSubtotal?: string;
  orderTax?: number;
  displayOrderTax?: string;
  orderTotal?: number;
  displayOrderTotal?: string;
  placeId?: string;
  products?: Product[];
  displayOrderDiscountTotal?: string;
  orderDiscountTotal?: number;
  displayOrderDiscountTax?: string;
  orderDiscountTax?: number;
  discounts?: any[];
  source?: string;
  orderGratuity?: number;
  displayOrderGratuity?: string;
  specialInstructions?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  vehicleColor?: string;
  zone?: string;
  pickupZoneId?: string;
  formattedZoneSelection?: string;
  zoneEnteredData?: string;
  deviceId?: string;
  created?: Date;
  externalOrderId?: string;
  lastUpdated?: Date;
  upsell?: string;
  orderProblems?: OrderProblems;
  posOrderId?: string;
  token?: string;
  schedules?: any[];
  cardId?: string;
  paymentToken?: string;
  paymentScheme?: string;
  cardBrand?: string;
  cardLast4?: string;
  authCode?: string;
  ewalletType?: string;
  customerId?: string;
  estimatedPrepTime?: string;
  estimatedWaitTime?: string;
  fulfillmentStatus?: string;
  paymentAttributes?: PaymentAttributes;
  paymentId?: string;
  paymentStatus?: string;
  orderLineItems?: any[];
  distributedOrderId?: string;
  gratuityConfig?: GratuityConfig;
  fees?: any[];
  feeSubtotal?: number;
  displayFeeSubtotal?: string;
  feeTaxSubtotal?: number;
  displayFeeTaxSubtotal?: string;
  smsOptIn?: boolean;
  checkinTime?: Date;
  expireTime?: Date;
  pickupWindow?: PickupWindow;
  pickupZoneDetails?: PickupZoneDetails;
  customerSpecificOrderDetails?: CustomerSpecificOrderDetails[];
}

export enum OrderStatus {
  SUBMITTED = 'SUBMITTED',
  RELEASED = 'RELEASED',
  EXPIRED = 'EXPIRED',
}

export interface CustomerSpecificOrderDetails {
  adaCompliant?: { adaCompliant: boolean };
}

export interface GratuityConfig {
  gratuityOptions?: any[];
  gratuityEnabled?: boolean;
  customGratuityEnabled?: boolean;
}

export interface OrderProblems {
  unavailableProducts?: any[];
  unavailableBundles?: any[];
  removedLineItems?: any[];
  removedReasons?: PaymentAttributes;
}

export interface PaymentAttributes {}

export interface PickupWindow {
  windowId?: string;
  startTime?: string;
  endTime?: string;
}

export interface PickupZoneDetails {
  code?: string;
  hold?: string;
  status?: LockerOrderStatus;
  type?: PickupZoneType;
}

export enum LockerOrderStatus {
  RELEASED = 'RELEASED',
  LOADED = 'LOADED',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  REMOVED = 'REMOVED',
  DONE = 'DONE',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
}

export enum PickupZoneType {
  LOCKER_PICKUP = 'LOCKER_PICKUP',
  DELIVERY = 'DELIVERY',
}

export interface PosItemOptions {
  itemId?: null;
  priceCode?: null;
}

export interface SubmitOrderRequest {
  customerEmail?: string;
  customerName?: string;
  customerPhone?: string;
  checkinLink?: string;
  paymentToken: string;
  paymentScheme?: string;
  pickupWindowId?: string;
  zone?: string;
  customerSpecificOrderDetails?: object;
}

export interface CheckinOrderRequest {
  customerId: string;
  customerSpecificOrderDetails?: any;
  externalOrderId?: string;
  placeId: string;
  smsOptIn: boolean;
  vehicleColor?: string;
  vehicleMake?: string;
  vehicleModel?: string;
  zone?: string;
}
