import { FC } from 'react';
import { connect } from 'react-redux';
import { setupWebGateway } from '../../helpers/webgateway';

import { Card } from '../Card';

interface SignUpCardProps {
  localization: any;
  uiGateway: string;
}

const mapStateToProps = (state: any) => {
  return {
    localization: state.localization.signUpPrompt,
    uiGateway: setupWebGateway(state.webConfig),
  };
};

const SignUpCard: FC<SignUpCardProps> = ({ localization, uiGateway }) => {
  const onSignUpClick = () => {
    window.location.replace(uiGateway);
  };

  if (
    localization?.signUpTitle &&
    localization.signUpBody &&
    localization.signUpCta &&
    uiGateway
  )
    return (
      <Card
        containerClassNames={'account-sign-up-card'}
        title={localization.signUpTitle}
        message={localization.signUpBody}
        callToAction={
          <div className="card-cta color-accent" onClick={onSignUpClick}>
            <div className="card-cta-chevron-right border-color-accent"></div>
            <div className="card-cta-label color-accent">
              {localization.signUpCta}
            </div>
          </div>
        }
      />
    );

  return null;
};

export default connect(mapStateToProps)(SignUpCard);
