export const SET_PAYMENT_STATUS = 'SET_PAYMENT_STATUS';

export const BILLING_INFORMATION_STATE = 'BILLING_INFORMATION';
export const ACCESSO_PAY_STATE = 'ACCESSO_PAY';
export const LOADING_STATE = 'LOADING';

export const _setPaymentStatus = (status) => {
  return {
    type: SET_PAYMENT_STATUS,
    data: { status },
  };
};
