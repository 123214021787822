import store from '../redux';
import { gaEvent } from './googleAnalytics/gaEvent';

export const reportPageView = (page_path) => {
  if (window.gtag && page_path) {
    const loc = window.location;
    const page_location = `${loc.protocol}//${loc.host}/#${page_path}`;
    window.gtag('event', 'page_view', { page_location, page_path });
  }
};

export const reportBootScreen = ({
  customerId,
  venueId,
  placeId,
  access_token,
}) => {
  const action = placeId ? 'load_direct_menu' : 'load_menu_list';
  const category = 'boot';
  let label = `${customerId},${venueId}`;
  if (placeId) {
    label = `${label},${placeId}`;
  }
  const tokenStatus = !!access_token ? 'with_token' : 'no_token';
  label = `${label},${tokenStatus}`;
  gaEvent(action, { category, label });
};

const getParams = () => {
  const {
    webConfig: { customerId, venueId },
    places: {
      activePlace: {
        label,
        activeMenu: {
          content: { name },
        },
      },
    },
  } = store.getState();
  return { customerId, venueId, label, name };
};

export const reportMenuSelect = (placeName, menuName) => {
  const { customerId, venueId } = getParams();
  const action = 'select_menu';
  const category = 'menu';
  const label = `${customerId},${venueId},${placeName},${menuName}`;
  gaEvent(action, { category, label });
};

export const reportCategorySelect = (categoryName) => {
  const { customerId, venueId, placeName, menuName } = getParams();
  const action = 'select_category';
  const category = 'menu';
  const label = `${customerId},${venueId},${placeName},${menuName},${categoryName}`;
  gaEvent(action, { category, label });
};

const modifyCart = (action, itemId, quantity) => {
  const { customerId, venueId, placeName, menuName } = getParams();
  const category = 'cart';
  const label = `${customerId},${venueId},${placeName},${menuName},${itemId}`;
  const value = quantity;
  gaEvent(action, { category, label, value });
};

export const reportEditCart = (itemId, quantity) => {
  modifyCart('edit_item', itemId, quantity);
};

const paymentStep = (action) => {
  const { customerId, venueId, placeName, menuName } = getParams();
  const category = 'cart';
  const label = `${customerId},${venueId},${placeName},${menuName}`;
  gaEvent(action, { category, label });
};

export const reportCompletedPayment = () => {
  paymentStep('completed_payment');
};

export const reportOrderSubmitted = () => {
  paymentStep('order_submitted');
};

export const reportLocationRequest = (action) => {
  const { customerId, venueId } = getParams();
  const category = 'location_request';
  const label = `${customerId},${venueId},location_${action}`;
  gaEvent(action, { category, label });
};

export const reportPlaceClosed = () => {
  const { customerId, venueId, placeName, menuName } = getParams();
  const category = 'error';
  const action = 'place_closed';
  const label = `${customerId},${venueId},${placeName},${menuName}`;
  gaEvent(action, { category, label });
};
