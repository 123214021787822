import { Dispatch } from 'react';
import { ActionType } from '../action-types';
import { Action, ZoneDetails } from '../actions/zoneSelectionDetails.actions';

export const setZoneSelectionDetails =
  (zoneDetails: ZoneDetails) => (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SAVE_ZONE_SELECTION_DETAILS,
      payload: zoneDetails,
    });
  };
