import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import {
  createPromiseId,
  getCloseUrl,
  redirectToErrorPage,
} from '../../../helpers';
import { getCheckinLink } from '../../../helpers/checkinLink/checkinLink';
import { offlineError } from '../../../helpers/errorMessages';
import { getItemsListForGAEvent } from '../../../helpers/googleAnalytics/checkoutSteps';
import { gaEvent } from '../../../helpers/googleAnalytics/gaEvent';
import { history } from '../../../helpers/history';
import { updateOrderAmounts } from '../../../helpers/updateOrderValues/updateOrderValues';
import { AnalyticsAction } from '../../../models/googleAnalytics.model';
import { ActionType } from '../../action-types';
import store from '../../index';
import { getActivePlaceContent } from '../../selectors';
import { clearApiError } from '../apiError';
import { _setLoadedCheckinTimeWindows } from '../checkinTimeWindows';
import { _setError } from '../errorStatus';
import { menuNotFound } from '../menu';
import { resetOrderData, resetOrderId, saveOrderData } from '../order';
import { popPromise, pushPromise } from '../spinner';
import {
  getOrderReadinessReceived,
  getOrderReadinessRequested,
  saveOrderReadiness,
} from '../store';

import { _setNotificationTags, _setVenueNotificationTags } from './tags';
import { closeToast2, openToast2 } from '../toast2';
import { DISCOUNT_FIELD, PICKUP_TIME_WINDOWS_FEATURE } from './features';

const errorMessages = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Time Out',
};

const errorRequest = (message, errorCode) => {
  return new Promise((resolve, reject) => {
    reject({ response: { status: errorCode }, message });
  });
};

const orderRequest = ({
  url,
  method = 'get',
  headers = {},
  data = undefined,
  testErrorCode = null,
}) => {
  const state = store.getState();
  const {
    webConfig: { apiUrl, customerId, nodeEnv },
  } = state;

  const baseAxiosOrderRequestConfig = {
    method,
    url: `${apiUrl}${url}`,
    data,
    headers: {
      Authorization: `Bearer ${state.token.identity.access}`,
      'X-Customer-ID': customerId,
      ...headers,
    },
  };
  const errorMessage = errorMessages[testErrorCode];

  //The errorCodes only can be tested in QA environment
  return testErrorCode && nodeEnv === 'qa' && errorMessage
    ? errorRequest(errorMessage, testErrorCode)
    : axios(baseAxiosOrderRequestConfig);
};

export const _getOrderReadiness = (matchParams, callback, notFoundError) => {
  const state = store.getState();
  const {
    webConfig: { genericErrorTitle, genericErrorMessage },
    places: { activePlace },
  } = state;

  // Determine active place
  let placeId = matchParams.placeId;

  if (!placeId) {
    if (activePlace && activePlace.fullyQualifiedId) {
      placeId = activePlace.fullyQualifiedId;
    } else {
      console.warn(`No place ID could be found.`);
      return (dispatch) => {};
    }
  }

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    dispatch(getOrderReadinessRequested());
    return orderRequest({ url: `orders/places/${placeId}/status` })
      .then((response) => {
        dispatch(saveOrderReadiness(response.data));
        dispatch(getOrderReadinessReceived());

        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.message === 'Network Error') {
          redirectToErrorPage(history, offlineError);
        } else if (error.response.status === 404) {
          notFoundError();
          dispatch(menuNotFound());
        } else {
          dispatch(
            openToast2({
              title: genericErrorTitle,
              message: genericErrorMessage,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

function addDiscountsToOrder(order, discounts) {
  if (!discounts || !discounts.length) {
    return order;
  }

  const discountedOrder = { ...order };
  discountedOrder.discounts = discounts
    .filter((d) => d.applicationType === 'ORDER')
    .map((d, index) => ({
      id: d.discountId,
      type: d.typeId,
      lineItemId: `DISCOUNT_${index}`,
      lineItems: [],
    }));
  return discountedOrder;
}

function addSelectedDiscountsToOrder(order, discounts) {
  const discountedOrder = { ...order };
  discountedOrder.discounts = discounts.map((d, index) => ({
    id: d.id,
    type: d.type,
    lineItemId: `DISCOUNT_${index}`,
    lineItems: d.lineItems || [],
  }));
  return discountedOrder;
}

function orderWindowIsClosed(response) {
  return (
    response && response.data && response.data.errorType === 'ORDERING_WINDOW'
  );
}

export const _createOrder = (matchParams, callback) => {
  let state = store.getState(),
    {
      webConfig,
      order,
      discounts,
      localization,
      menu: { willBeOpenLaterAt },
    } = state;

  // ROOM-4000 - Use order source from webconfig json file if present
  if (webConfig.source) {
    order.source = webConfig.source;
  }

  const discountedOrder = addDiscountsToOrder(order, discounts);

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return orderRequest({
      url: 'orders/',
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: { ...discountedOrder },
    })
      .then((response) => {
        const orderData = updateOrderAmounts(response.data);
        //work around as create call doesn't persist phone number
        dispatch(
          saveOrderData({ ...orderData, customerPhone: order.customerPhone }),
        );

        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.message === 'Network Error') {
          dispatch(_setError({ ...offlineError, error }));
        } else if (orderWindowIsClosed(error.response)) {
          const { opensLaterInTheCurrentDay, closedEntireDay } =
            localization.popup;
          const customBanner =
            willBeOpenLaterAt !== null
              ? opensLaterInTheCurrentDay.replace('%hour%', willBeOpenLaterAt)
              : closedEntireDay;
          dispatch(
            openToast2({
              title: localization.popup.weAreClosedTitle,
              message: customBanner,
            }),
          );
        } else {
          dispatch(
            openToast2({
              title: webConfig.genericErrorTitle,
              message: webConfig.genericErrorMessage2,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const _updateOrder = (matchParams, callback) => {
  let state = store.getState(),
    {
      webConfig,
      places: { activePlace },
      order,
      discounts,
      localization,
      menu: { willBeOpenLaterAt },
      discountCode: { selectedDiscount },
      features,
      ageRestrictionPrompt: { isAgeRestrictionAccepted },
    } = state;

  const placeId = activePlace.fullyQualifiedId;
  order.placeId = placeId;
  order.ageRestrictionAcknowledged = isAgeRestrictionAccepted;

  let discountedOrder;

  const isDiscountCodeEnabled = features[DISCOUNT_FIELD];
  if (isDiscountCodeEnabled && webConfig.isSeasonPassDiscountEnabled) {
    discountedOrder = addSelectedDiscountsToOrder(order, selectedDiscount);
  } else {
    discountedOrder = addDiscountsToOrder(order, discounts);
  }

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return orderRequest({
      url: `orders/${order.orderId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
      data: { ...discountedOrder },
    })
      .then((response) => {
        const orderData = updateOrderAmounts(response.data);
        dispatch(saveOrderData(orderData));

        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.message === 'Network Error') {
          dispatch(_setError({ ...offlineError, error }));
        } else if (orderWindowIsClosed(error.response)) {
          const { opensLaterInTheCurrentDay, closedEntireDay } =
            localization.popup;
          const customBanner =
            willBeOpenLaterAt !== null
              ? opensLaterInTheCurrentDay.replace('%hour%', willBeOpenLaterAt)
              : closedEntireDay;
          dispatch(
            openToast2({
              title: localization.popup.weAreClosedTitle,
              message: customBanner,
            }),
          );
        } else {
          dispatch(
            openToast2({
              title: webConfig.genericErrorTitle,
              message: webConfig.genericErrorMessage2,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const _retrieveOrder = (matchParams, callback) => {
  let state = store.getState(),
    {
      webConfig,
      places: { activePlace },
      order,
    } = state;

  const placeId = activePlace.fullyQualifiedId;
  const { config, venue, menuId } = matchParams;
  order.placeId = placeId;

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return orderRequest({ url: `orders/${order.orderId}` })
      .then((response) => {
        const { data } = response;

        const orderData = updateOrderAmounts(data);
        dispatch(saveOrderData(orderData));

        if (data.orderStatus === 'RELEASED') {
          history.push(
            `/config/${config}/venue/${venue}/place/${placeId}/menu/${menuId}/confirmation`,
          );
        } else {
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.message === 'Network Error') {
          redirectToErrorPage(history, offlineError);
        } else {
          dispatch(
            openToast2({
              title: webConfig.genericErrorTitle,
              message: webConfig.genericErrorMessage2,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const _submitOrder = (
  matchParams,
  userInfo,
  payment,
  onSuccess,
  onError,
  resubmitOrder,
  showResubmit = true,
  onPTWUnavailable,
) => {
  const state = store.getState();
  const {
    webConfig: { genericErrorTitle, genericErrorMessage2 },
    order,
    localization,
    apiError: { orderSubmit },
    menu: { willBeOpenLaterAt },
    orderReadiness: { pickupZones, deliveryZones },
    checkinTimeWindows: { selectedCheckinTimeWindow },
    places,
    token: {
      identity: { access },
    },
    zoneDetails: { zone, zoneUserCustomValue },
    features,
  } = state;

  const testErrorCode = orderSubmit || undefined;

  const placeFeatures = getActivePlaceContent(places).features;
  const isPTWEnabled =
    placeFeatures['pickupTimeWindowsEnabled'] &&
    (features ? features[PICKUP_TIME_WINDOWS_FEATURE] : false);

  const isCombineSubmitAndCheckin =
    placeFeatures &&
    placeFeatures.uiControls &&
    placeFeatures.uiControls['combineSubmitAndCheckin'];

  // Default to first listed zone if there is one
  const defaultZone = pickupZones.length > 0 ? pickupZones[0].pickupZoneId : '';
  const selectedZone =
    zone ||
    defaultZone ||
    (deliveryZones.length > 0 && deliveryZones[0]) ||
    'INSIDE_TAKE_OUT';

  const zoneRequiresEntry =
    zone && pickupZones?.filter((pz) => pz?.pickupZoneId === zone)?.length > 0
      ? pickupZones.filter((pz) => pz?.pickupZoneId === zone)[0]?.requiresEntry
      : false;

  const orderPayload = {
    customerEmail: userInfo.userEmail,
    customerName: userInfo.userName,
    customerPhone: order.customerPhone,
    smsOptIn: order.smsOptIn,
    checkinLink: getCheckinLink(
      matchParams.config,
      matchParams.venue,
      order.orderId,
      access,
    ),
    paymentToken: (payment && payment.id) || undefined,
    paymentScheme: (payment && payment.scheme) || undefined,
    pickupWindowId:
      isPTWEnabled && selectedCheckinTimeWindow
        ? selectedCheckinTimeWindow.windowId
        : undefined,
    zone: isPTWEnabled ? selectedZone : null,
    customerSpecificOrderDetails: {
      [selectedZone]:
        isPTWEnabled && zoneRequiresEntry && zoneUserCustomValue
          ? zoneUserCustomValue
          : undefined,
      ...order.customerSpecificOrderDetails,
    },
  };

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT_ORDER,
      payload: { orderId: order.orderId, ...orderPayload },
    });
    dispatch(pushPromise(promiseId));
    return orderRequest({
      url: `orders/${order.orderId}/submit`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: { ...orderPayload },
      testErrorCode,
    })
      .then((response) => {
        dispatch(saveOrderData(response.data));
        handleTextNotification(dispatch);
        dispatch({
          type: ActionType.SUBMIT_ORDER_SUCCESS,
        });

        if (isCombineSubmitAndCheckin) {
          dispatch(_checkinOrder(matchParams, onSuccess, onError));
        } else {
          onSuccess();
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionType.SUBMIT_ORDER_FAILURE,
        });
        console.error(error);
        const { message, response } = error;
        Bugsnag.notify(error, (event) => {
          event.addMetadata('apiError', {
            status: response.status,
            message: response?.data?.message,
          });
        });
        // create a new order since this one will be in the error state
        dispatch(resetOrderId());
        dispatch(_createOrder(matchParams));
        if (onError) {
          onError();
        }
        if (message === 'Network Error') {
          redirectToErrorPage(history, offlineError);
        } else if (orderWindowIsClosed(response)) {
          const { opensLaterInTheCurrentDay, closedEntireDay } =
            localization.popup;
          const customBanner =
            willBeOpenLaterAt !== null
              ? opensLaterInTheCurrentDay.replace('%hour%', willBeOpenLaterAt)
              : closedEntireDay;
          dispatch(
            openToast2({
              title: localization.popup.weAreClosedTitle,
              message: customBanner,
            }),
          );
        } else if (
          response &&
          response.status === 409 &&
          response.data.error === 'Conflict' &&
          response.data.message.indexOf('RELEASED') > -1
        ) {
          // Check if the order has been released, and if it's been released, fetch the order to update the
          // values in the app's state and then redirect the user to the confirmation page.
          const redirect = () => history.push('/confirmation');
          dispatch(_retrieveOrder(redirect));
        } else if (
          response &&
          response.status >= 500 &&
          response.status <= 599
        ) {
          dispatch(clearApiError('orderSubmit'));
          // If the server responses with these codes, we show a toast with a button
          // to resubmit the order
          const {
            popup: {
              timeOutOrderTitle,
              timeOutSubmitOrderMessage,
              reSubmitOrderCta,
            },
          } = localization;
          const toastObject = {
            title: timeOutOrderTitle,
            plainTitle: true,
            message: timeOutSubmitOrderMessage,
            smallMessage: true,
            dismiss: true,
            buttons: showResubmit
              ? [
                  {
                    label: reSubmitOrderCta,
                    action: () => {
                      if (payment && resubmitOrder) {
                        resubmitOrder();
                      } else {
                        dispatch(
                          _submitOrder(
                            matchParams,
                            userInfo,
                            payment,
                            onSuccess,
                            onError,
                            resubmitOrder,
                          ),
                        );
                      }
                      dispatch(closeToast2());
                    },
                  },
                ]
              : undefined,
          };
          dispatch(openToast2(toastObject));
        } else if (response && response.status === 409) {
          // PTW is unavailable
          if (onPTWUnavailable) {
            dispatch(saveOrderData(orderPayload));
            onPTWUnavailable();
          }
        } else {
          dispatch(
            openToast2({
              title: genericErrorTitle,
              message: genericErrorMessage2,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const _checkinOrder = (matchParams, onSuccess, onError) => {
  const state = store.getState();
  const {
    webConfig: { customerId, genericErrorTitle, genericErrorMessage2 },
    apiError: { orderCheckin },
    places: { activePlace },
    order: { orderId },
    orderReadiness: { pickupZones, deliveryZones },
    localization,
    menu: { willBeOpenLaterAt },
    zoneDetails: { zone, zoneUserCustomValue },
  } = state;

  // Default to first listed zone if there is one
  const defaultZone = pickupZones.length > 0 ? pickupZones[0].pickupZoneId : '';
  const selectedZone =
    zone ||
    defaultZone ||
    (deliveryZones.length > 0 && deliveryZones[0]) ||
    'INSIDE_TAKE_OUT';
  const baseOrderUserInfo = {
    customerId,
    zone: selectedZone,
    placeId: activePlace.fullyQualifiedId,
  };

  const orderUserInfo = zoneUserCustomValue
    ? {
        ...baseOrderUserInfo,
        customerSpecificOrderDetails: { [selectedZone]: zoneUserCustomValue },
      }
    : baseOrderUserInfo;

  const testErrorCode = orderCheckin || undefined;
  let promiseId = createPromiseId();

  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return orderRequest({
      url: `orders/${orderId}/checkin`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: { ...orderUserInfo },
      testErrorCode,
    })
      .then((response) => {
        dispatch(saveOrderData(response.data));
        gaEvent(AnalyticsAction.PURCHASE, {
          transaction_id: response.data.externalOrderId,
          value: response.data.orderTotal,
          tax: response.data.orderTax,
          shipping: 0,
          currency: response.data.currency,
          items: getItemsListForGAEvent() || undefined,
          coupon:
            response.data.discounts && response.data.discounts.length > 0
              ? response.data.discounts[0]?.code
              : undefined,
        });
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.error(error);
        const { message, response } = error;
        Bugsnag.notify(error, (event) => {
          event.addMetadata('apiError', {
            status: response.status,
            message: response?.data?.message,
          });
        });
        // create a new order since this one will be in the error state
        dispatch(resetOrderId());
        dispatch(_createOrder(matchParams));

        if (onError) {
          onError();
        }
        if (message === 'Network Error') {
          redirectToErrorPage(history, offlineError);
        } else if (orderWindowIsClosed(response)) {
          const { opensLaterInTheCurrentDay, closedEntireDay } =
            localization.popup;
          const customBanner =
            willBeOpenLaterAt !== null
              ? opensLaterInTheCurrentDay.replace('%hour%', willBeOpenLaterAt)
              : closedEntireDay;
          dispatch(
            openToast2({
              title: localization.popup.weAreClosedTitle,
              message: customBanner,
            }),
          );
        } else if (
          response &&
          response.status === 409 &&
          response.data.error === 'Conflict' &&
          response.data.message.indexOf('RELEASED') > -1
        ) {
          // Check if the order has been released, and if it's been released, fetch the order to update the
          // values in the app's state and then redirect the user to the confirmation page.
          const redirect = () => history.push('/confirmation');
          dispatch(_retrieveOrder(redirect));
        } else if (
          response &&
          response.status !== 409 &&
          response.status >= 400 &&
          response.status <= 599
        ) {
          dispatch(clearApiError('orderCheckin'));
          // If the server responses with these codes, we show a toast with a button
          // to resubmit the order
          const {
            popup: { timeOutOrderTitle, timeOutCheckinOrderMessage },
          } = localization;
          const toastObject = {
            title: timeOutOrderTitle,
            plainTitle: true,
            message: timeOutCheckinOrderMessage,
            smallMessage: true,
            dismiss: true,
            dismissCallback: () => {
              const { config, venue } = matchParams;
              history.push(`/config/${config}/venue/${venue}/places`);
            },
          };
          dispatch(openToast2(toastObject));
        } else {
          dispatch(
            openToast2({
              title: genericErrorTitle,
              message: genericErrorMessage2,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const _cancelOrder = (onFinallyCallback = null) => {
  let state = store.getState(),
    { order, webConfig } = state;

  return (dispatch) => {
    if (!order.orderId) {
      // Order was never placed
      dispatch(resetOrderData());
      return;
    }

    let promiseId = createPromiseId();
    dispatch(pushPromise(promiseId));
    return axios
      .delete(`${webConfig.apiUrl}orders/${order.orderId}`, {
        headers: {
          Authorization: `Bearer ${state.token.identity.access}`,
        },
      })
      .catch((error) => {
        // Don't care if the order wasn't cancelled successfully
        // because there's nothing we could do anyways.
        console.error(error);
      })
      .finally(() => {
        dispatch(resetOrderData());
        dispatch(popPromise(promiseId));

        if (onFinallyCallback) {
          onFinallyCallback();
        }
      });
  };
};

export const _updateCustomerInfo = (customerPhone, onSuccess, onError) => {
  const state = store.getState();
  const {
    webConfig: { genericErrorTitle, genericErrorMessage2 },
    order: { orderId, smsOptIn },
  } = state;

  const customerInfo = {
    smsOptIn: smsOptIn,
    customerPhone,
  };

  let promiseId = createPromiseId();

  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return orderRequest({
      url: `orders/${orderId}/customerInfo`,
      method: 'patch',
      headers: { 'Content-Type': 'application/json' },
      data: { ...customerInfo },
      // testErrorCode
    })
      .then((response) => {
        // customerInfo API doesn't return anything so nothing to process here just the onSuccess function if provided

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.error(error);
        const { message, response } = error;
        Bugsnag.notify(error, (event) => {
          event.addMetadata('apiError', {
            status: response.status,
            message: response?.data?.message,
          });
        });

        if (onError) {
          onError();
        }

        if (message === 'Network Error') {
          redirectToErrorPage(history, offlineError);
        } else {
          dispatch(
            openToast2({
              title: genericErrorTitle,
              message: genericErrorMessage2,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const _loadAvailableWindows = (id) => {
  const state = store.getState();
  const {
    order: { orderId },
  } = state;

  const orderToFind = id ? id : orderId;

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return orderRequest({
      url: `orders/${orderToFind}/pickupWindow`,
      method: 'get',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(({ data }) => {
        dispatch(_setLoadedCheckinTimeWindows(data));
      })
      .catch((error) => {
        console.error(error);
        if (error.message === 'Network Error') {
          dispatch(_setError({ ...offlineError, error }));
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const _rescheduleWindow = (checkinWindow, orderId) => {
  const { windowId } = checkinWindow;
  const {
    query: {
      params: { fromMobile, foodAndBev },
    },
    webConfig: { venueId },
  } = store.getState();

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return orderRequest({
      url: `orders/${orderId}/pickupWindow/${windowId}`,
      method: 'put',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(({ data }) => {
        dispatch(_setLoadedCheckinTimeWindows(data));
        if (fromMobile || foodAndBev) {
          const closeUrl = getCloseUrl(venueId);
          window.location.replace(closeUrl);
        }
      })
      .catch((error) => {
        console.error(error);
        if (error.message === 'Network Error') {
          dispatch(_setError({ ...offlineError, error }));
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const handleTextNotification = (dispatch) => {
  const state = store.getState();
  const {
    order: { pickupZoneDetails, customerPhone, smsOptIn },
  } = state;
  if (
    pickupZoneDetails &&
    pickupZoneDetails.type !== 'LOCKER_PICKUP' &&
    customerPhone
  ) {
    dispatch(_setVenueNotificationTags());
    dispatch(
      _setNotificationTags(customerPhone, smsOptIn, () => {
        dispatch(_updateCustomerInfo(customerPhone));
      }),
    );
  }
};
