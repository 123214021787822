import { isEmpty } from 'lodash/lang';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';

import actions from '../../redux/actions';
import api from '../../redux/actions/api';
import { _getMenu } from '../../redux/actions/api/menu';
import { _getOrderReadiness } from '../../redux/actions/api/order';
import { _getPlaceContent } from '../../redux/actions/api/places';
import { setMenuStatus } from '../../redux/actions/menu';
import { setOrderPlace } from '../../redux/actions/order';

import {
  checkIfWillBeOpenLater,
  determineOrderingWindows,
  newlinesToBreaks,
  ORDER_OPERATION_CREATE,
} from '../../helpers';
import { reportPlaceClosed } from '../../helpers/googleAnalytics';

import alertIcon from '../../assets/icons/i-alert-red.svg';
import Billing from '../Billing/billing.js';
import Cart from '../Cart';
import MenuItemContainer from '../MenuItem/MenuItemContainer';

import OrderDetails from '../../routes/OrderDetails/OrderDetails';
import './menu.scss';

class Menu extends React.Component {
  state = { menu: null, loading: true };

  componentDidMount() {
    const {
      menu,
      match,
      getOrderReadiness,
      places,
      setActivePlace,
      cancelOrder,
    } = this.props;
    const { placeId, menuId } = match.params;

    // Similarly, set the active place. This isn't a great option but that's what we have
    if (
      !places.activePlace ||
      places.activePlace.fullyQualifiedId !== placeId
    ) {
      const place = places.data?.find((d) => d.fullyQualifiedId === placeId);
      setActivePlace(place);
      cancelOrder();
    }

    this.setState({ loading: true });
    getOrderReadiness(
      match.params,
      () => {
        const isNewMenu = !menu || menu.id !== menuId;
        const menuIsExpired =
          !menu || !menu.cacheExpires || menu.cacheExpires < Date.now();
        if (isNewMenu || menuIsExpired) {
          this.loadMenu();
        } else {
          this.checkMenuHours();
          this.loadPlaceContent();
          this.setState({ loading: false });
        }
      },
      () => {
        this.setState({ loading: false });
      },
    );
  }

  checkMenuHours() {
    const { setMenuStatus, orderReadiness } = this.props;
    const { status } = determineOrderingWindows(
      moment(),
      orderReadiness,
      ORDER_OPERATION_CREATE,
      'MENU',
    );
    const willBeOpenLaterAt = checkIfWillBeOpenLater(orderReadiness);
    setMenuStatus(status !== false, willBeOpenLaterAt);
  }

  loadMenu() {
    const { match, getMenu, getPlaceContent } = this.props;
    const { placeId, menuId } = match.params;

    if (placeId) {
      getPlaceContent(placeId);
      if (menuId) {
        getMenu(
          placeId,
          menuId,
          (menu) => {
            if (!menu.products) {
              reportPlaceClosed();
            }
          },
          () => {
            this.setState({ loading: false });
          },
        );
        this.props.getDiscounts(placeId, menuId);
      }
    }
  }

  loadPlaceContent() {
    const { match } = this.props;
    const { placeId, menuId } = match.params;

    if (placeId) {
      this.props.getPlaceContent(placeId);
      this.props.getDiscounts(placeId, menuId);
    }
    this.setState({ loading: false });
  }

  unAvailableMenu = () => {
    const {
      localization: { notAvailable, seeOtherMenus },
      match,
    } = this.props;
    const { config, venue } = match.params;
    return (
      <div className="menu error">
        <div className="not-available">
          <img
            className="alert-icon"
            src={alertIcon}
            alt={'ic-alert'}
            width="48"
            height="48"
          />
          <div className="msg font-secondary color-error">
            {newlinesToBreaks(notAvailable)}
          </div>
          <div className="menus-link color-accent font-primary">
            <Link to={`/config/${config}/venue/${venue}/places`}>
              {seeOtherMenus}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { loading } = this.state;

    if (loading) return <div></div>;

    const {
      menu: { products, categories },
    } = this.props;
    if (isEmpty(products) || isEmpty(categories)) return this.unAvailableMenu();

    return (
      <div className="menu">
        <Switch>
          <Route
            exact
            path={[
              '/config/:config/venue/:venue/place/:placeId/menu/:menuId/cat/:category',
              '/config/:config/venue/:venue/place/:placeId/menu/:menuId/',
            ]}
            component={MenuItemContainer}
          />
          <Route
            path="/config/:config/venue/:venue/place/:placeId/menu/:menuId/order"
            component={Cart}
          />
          <Route
            path="/config/:config/venue/:venue/place/:placeId/menu/:menuId/billing"
            component={Billing}
          />
          <Route
            path="/config/:config/venue/:venue/place/:placeId/menu/:menuId/confirmation"
            component={OrderDetails}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    localization: state.localization.menu,
    menu: state.menu,
    placeContent: state.places.placeContent,
    places: state.places,
    orderReadiness: state.orderReadiness,
  };
};

const mapDispatchToProps = {
  getMenu: _getMenu,
  getPlaceContent: _getPlaceContent,
  getOrderReadiness: _getOrderReadiness,
  getDiscounts: api.getDiscounts,
  setMenuStatus: setMenuStatus,
  setOrderPlace: setOrderPlace,
  setActivePlace: actions.setActivePlace,
  cancelOrder: api.cancelOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
