import React from 'react';

import { connect } from 'react-redux';
import {
  emailValidation,
  inputValidation,
  selectValidation,
} from '../../helpers/billingValidation';
import { formatTimeCheckinWindow } from '../../helpers/pickupTimeWindows';
import { setZoneSelectionDetails } from '../../redux/action-creators/zoneSelectionDetails.action-creator';
import { PICKUP_TIME_WINDOWS_FEATURE } from '../../redux/actions/api/features';
import Input from '../FormFields/Input';
import Select from '../FormFields/Select';
import PickupTimeWindowsList from '../PickupTimeWindowsList';
import PopUpHeader from '../PopUpHeader';
import OrderStatusNotifications from './OrderStatusNotifications';
import './billingInfo.scss';

class BillingInfo extends React.Component {
  constructor(props) {
    super(props);
    const { userName, userEmail, zone, zoneUserCustomValue, placeControls } =
      props;
    const pickedZone = this.handlePickedZone();
    this.state = {
      pickedZone,
      popUp: false,
      errors: {
        userName: userName ? this.validation('userName', userName) : null,
        userEmail: userEmail ? this.validation('userEmail', userEmail) : null,
        zone: zone ? this.validation('zone', zone) : null,
        zoneUserCustomValue:
          zoneUserCustomValue?.length > 0
            ? this.validation(
                'zoneUserCustomValue',
                zoneUserCustomValue,
                pickedZone,
              )
            : null,
      },
      isValidPhoneNumber: this.getInitialPhoneNumberValidityState(
        pickedZone,
        placeControls,
      ),
    };
    this.formValidity(this.state.isValidPhoneNumber);
  }

  // Trigger handleChange to display the zone and zone user custom value
  componentDidMount() {
    const { zone, zoneUserCustomValue } = this.props;
    if (zone) {
      this.handleChange('zone', zone);
    }
    if (zoneUserCustomValue) {
      this.handleChange('zoneUserCustomValue', zoneUserCustomValue);
    }
  }

  // Before rendering, check if zone has value and use that as picked zone
  handlePickedZone = () => {
    const {
      zone: zoneId,
      pickupZones,
      zoneUserCustomValue,
      _setZoneSelectionDetails,
    } = this.props;
    if (zoneId) {
      const pickedZone = pickupZones.find((o) => o.pickupZoneId === zoneId);
      if (pickedZone) {
        _setZoneSelectionDetails({
          zone: zoneId,
          zoneUserCustomValue: zoneUserCustomValue,
          isLockerPickup: pickedZone?.type === 'LOCKER_PICKUP' ? true : false,
        });
      }
      return pickedZone;
    }

    _setZoneSelectionDetails({
      zone:
        pickupZones && pickupZones.length === 1
          ? pickupZones[0]?.pickupZoneId
          : null,
      zoneUserCustomValue: null,
      isLockerPickup:
        pickupZones &&
        pickupZones.length === 1 &&
        pickupZones[0]?.type === 'LOCKER_PICKUP'
          ? true
          : false,
    });

    return pickupZones && pickupZones.length === 1 ? pickupZones[0] : null;
  };

  validation = (name, value, pickedZone) => {
    const {
      pickupZones,
      localization: {
        errorNameRequired,
        errorZoneRequired,
        errorZoneUserCustomValueRequired,
        errorZoneUserCustomValueInvalid,
        errorEmailRequired,
        errorEmailInvalid,
      },
    } = this.props;
    switch (name) {
      case 'userName':
        return inputValidation(value, errorNameRequired);
      case 'userEmail':
        return emailValidation(value, errorEmailRequired, errorEmailInvalid);
      case 'zone':
        return selectValidation(value, pickupZones, errorZoneRequired);
      case 'zoneUserCustomValue':
        const regexRule =
          pickedZone && pickedZone.rule && pickedZone.rule.regex;
        const regex = regexRule ? new RegExp(regexRule) : undefined;
        return inputValidation(
          value,
          errorZoneUserCustomValueRequired,
          errorZoneUserCustomValueInvalid,
          regex,
        );
      default:
        return '';
    }
  };

  getInitialPhoneNumberValidityState = (pickedZone, placeControls) => {
    // If the place is configured with locker pickup, the phone number is required so the initial state is invalid
    if (pickedZone !== null && pickedZone.type === 'LOCKER_PICKUP') {
      return false;
    }

    // If the place is configured to prompt for phone number and the input is required, the initial state is invalid
    if (
      placeControls?.promptForPhoneNumber &&
      placeControls?.phoneNumberRequired
    ) {
      return false;
    }

    return true;
  };

  formValidity = (isPhoneNumberValid) => {
    const {
      errors: { userName, userEmail },
    } = this.state;
    const validity =
      userName === '' &&
      userEmail === '' &&
      this.zoneValidity() &&
      this.zoneCustomValidity() &&
      isPhoneNumberValid;
    this.props.handleValidity(validity);
  };

  phoneNumberValidity = (value) => {
    this.setState({ isValidPhoneNumber: value });
    this.formValidity(value);
  };

  zoneValidity = () => {
    const { pickupZones } = this.props;
    const {
      errors: { zone },
    } = this.state;
    return !(pickupZones && pickupZones.length > 1) || zone === '';
  };

  zoneCustomValidity = () => {
    const {
      pickedZone,
      errors: { zoneUserCustomValue },
    } = this.state;
    return (
      !(pickedZone && pickedZone.requiresEntry) || zoneUserCustomValue === ''
    );
  };

  handleChange = (name, value) => {
    const { pickupZones, _setZoneSelectionDetails } = this.props;
    const { pickedZone, errors, isValidPhoneNumber } = this.state;
    const newPickedZone =
      name === 'zone'
        ? pickupZones.find((z) => z.pickupZoneId === value)
        : pickedZone;
    const errorMessage = this.validation(name, value, newPickedZone);
    const newErrors = { ...errors, [name]: errorMessage };
    this.setState({ errors: newErrors, pickedZone: newPickedZone }, () =>
      this.formValidity(isValidPhoneNumber),
    );
    this.props.handleChange({ name, value });

    if (name === 'zone') {
      _setZoneSelectionDetails({
        zone: value,
      });
    } else if (name === 'zoneUserCustomValue') {
      _setZoneSelectionDetails({
        zoneUserCustomValue: value,
      });
    }
  };

  handlePTWSelectorClick = (e) => {
    e.preventDefault();
    this.setState({ popUp: true });
    this.props.loadAvailableWindows();
  };

  onPTWSelection = (selectedPTW) => {
    this.setState({ popUp: false });
  };

  valueTimeWindow = () => {
    const {
      localization: { checkInPlaceholder, pickupPlaceholder },
      selectedCheckinTimeWindow,
      placeFeatures,
    } = this.props;
    if (selectedCheckinTimeWindow === null) {
      return placeFeatures &&
        placeFeatures.uiControls &&
        placeFeatures.uiControls.combineSubmitAndCheckin
        ? pickupPlaceholder
        : checkInPlaceholder;
    }

    const { startTime, endTime } = selectedCheckinTimeWindow;
    return formatTimeCheckinWindow(startTime, endTime);
  };

  render() {
    const {
      pickupZones,
      webContent,
      localization,
      userName,
      userEmail,
      zone,
      zoneUserCustomValue,
      features,
      placeFeatures,
      placeControls,
    } = this.props;
    const { pickedZone, errors, popUp } = this.state;

    const billingMsg = webContent.preorderBillingMessage;
    const pickupMsg = webContent.preorderPickupMessage;
    const hasMsg = billingMsg || pickupMsg;
    const hasZones = pickupZones && pickupZones.length > 1;

    const requiresEntry = pickedZone && pickedZone.requiresEntry;
    const newFieldLabel = pickedZone && pickedZone.entryPrompt;
    const newFieldRegex =
      pickedZone && pickedZone.rule && pickedZone.rule.regex;
    const newFieldType =
      newFieldRegex && newFieldRegex.includes('^\\s*\\d+\\s*$')
        ? 'number'
        : 'text';

    //Features
    const ptwFeature =
      placeFeatures &&
      placeFeatures['pickupTimeWindowsEnabled'] &&
      (features ? features[PICKUP_TIME_WINDOWS_FEATURE] : false);

    const isCombineSubmitAndCheckin =
      placeFeatures?.uiControls?.combineSubmitAndCheckin;

    const ptwHeader = isCombineSubmitAndCheckin
      ? localization.pickUpWindow
      : localization.checkInWindow;

    const ptwDesc = isCombineSubmitAndCheckin
      ? localization.pickUpWindowDesc
      : localization.checkInWindowDesc;

    const ptwPopupHeader = isCombineSubmitAndCheckin
      ? localization.pickupTimeWindowsHeader
      : localization.checkInTimeWindowsHeader;
    return (
      <React.Fragment>
        <div id="billing-info">
          <section className="user-info contains-forms">
            <h3 className="title">{localization.billingInfo}</h3>
            <div className="content">
              <p className="billing-text">
                {localization.billingInformationCopy}
              </p>
              <Input
                errorMessage={errors.userName}
                id={'userName'}
                type={'text'}
                initialValue={userName}
                label={localization.name}
                name={'userName'}
                onChange={this.handleChange}
              />
              <Input
                errorMessage={errors.userEmail}
                id={'userEmail'}
                type={'email'}
                initialValue={userEmail}
                label={localization.email}
                name={'userEmail'}
                onChange={this.handleChange}
              />
            </div>
          </section>

          {this.props.children}

          {(hasMsg || hasZones || (pickedZone && requiresEntry)) && (
            <section className="pickup-zone-selection contains-forms">
              <h3 className="title">{localization.orderPickup}</h3>
              <div className="content">
                {billingMsg && (
                  <p className="reminder-text color-text-tertiary">
                    {billingMsg}
                  </p>
                )}
                {pickupMsg && (
                  <p className="pickup-text color-dark-highlight">
                    {pickupMsg}
                  </p>
                )}
                {hasZones && (
                  <Select
                    errorMessage={errors.zone}
                    id={'zone-select'}
                    initialValue={zone}
                    label={localization.orderPickupDropdownLabel}
                    name={'zone'}
                    selectOneMessage={localization.selectOne}
                    hasMsg={hasMsg}
                    items={pickupZones}
                    onChange={this.handleChange}
                  />
                )}
                {requiresEntry && newFieldLabel && (
                  <Input
                    errorMessage={errors.zoneUserCustomValue}
                    id={'zoneUserCustomValue'}
                    type={newFieldType}
                    initialValue={zoneUserCustomValue}
                    label={newFieldLabel}
                    name={'zoneUserCustomValue'}
                    maxLength="20"
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </section>
          )}
          {ptwFeature && (
            <section className="check-in-selection contains-forms">
              <h3 className="title">{ptwHeader}</h3>
              <div className="content">
                <p className="check-in-text color-text-tertiary">{ptwDesc}</p>
                <div className="form-group">
                  <div
                    className="ptw-picker"
                    onClick={this.handlePTWSelectorClick}>
                    {this.valueTimeWindow()}
                    <span className="arrow-down"></span>
                  </div>
                </div>
              </div>
            </section>
          )}
        </div>

        {pickedZone && pickedZone.type === 'LOCKER_PICKUP' && (
          <OrderStatusNotifications
            onPhoneNumberUpdate={this.phoneNumberValidity}
            pickedZoneType={pickedZone?.type}
            isPhoneNumberRequired={true}
            enableOptInCheckbox={
              placeControls && !placeControls.blockOrderNotifications
            }
          />
        )}

        {placeControls &&
          placeControls.promptForPhoneNumber &&
          (!pickedZone || pickedZone.type !== 'LOCKER_PICKUP') && (
            <OrderStatusNotifications
              onPhoneNumberUpdate={this.phoneNumberValidity}
              pickedZoneType={pickedZone?.type}
              isPhoneNumberRequired={placeControls.phoneNumberRequired}
              enableOptInCheckbox={
                placeControls && !placeControls.blockOrderNotifications
              }
            />
          )}

        <div
          className={`popup-wrapper bg-color-card ${popUp ? 'show' : 'hide'}`}>
          <div className="header-wrapper bg-color-primary">
            <PopUpHeader
              text={ptwPopupHeader}
              onClose={() => {
                this.setState({ popUp: false });
              }}
            />
          </div>
          <div className="content-wrapper">
            <PickupTimeWindowsList onSelect={this.onPTWSelection} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  _setZoneSelectionDetails: (data) => {
    dispatch(setZoneSelectionDetails(data));
  },
});

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
