import { SET_API_ERROR, CLEAR_API_ERROR } from '../actions/apiError';

const defaultState = {
  orderSubmit: null,
  orderCheckin: null,
};

export default (state = defaultState, action) => {
  const { type, payload } = action;

  const apiRequest = payload && payload.apiRequest;
  const errorCode = payload && payload.errorCode;

  const exists = payload && defaultState.hasOwnProperty(apiRequest);

  switch (type) {
    case SET_API_ERROR:
      return exists ? { ...state, [apiRequest]: errorCode } : state;
    case CLEAR_API_ERROR:
      return exists ? { ...state, [apiRequest]: null } : state;
    default:
      return state;
  }
};
