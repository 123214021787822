import React from 'react';

import './card.scss';
import classNames from 'classnames';

export const Card = ({ containerClassNames, title, message, callToAction }) => {
  const cardContainerClassnames = classNames(containerClassNames);

  return (
    <div
      className={`card bg-color-card color-text-primary ${cardContainerClassnames}`}>
      {title && <div className="card-title">{title}</div>}
      {message && <div className="card-message">{message}</div>}
      {callToAction && <div className="cta">{callToAction}</div>}
    </div>
  );
};
