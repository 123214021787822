export interface AnalyticsItem {
  item_id: string;
  item_name: string;
  affiliation: string;
  coupon?: string;
  currency: string;
  discount?: number;
  index: number;
  item_brand: string;
  item_category: string;
  item_list_id: string;
  item_list_name: string;
  price: number;
  quantity?: number;
}

export interface AnalyticsPurchase {
  transaction_id?: string;
  affiliation: string;
  value?: number;
  tax?: number;
  shipping?: number;
  currency?: string;
  coupon?: string;
}

export enum AnalyticsAction {
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_ITEM = 'view_item',
  SELECT_ITEM = 'select_item',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  VIEW_CART = 'view_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_PAYMENT_INFO = 'add_payment_info',
  PURCHASE = 'purchase',
}
