import {
  setZoneSelectionDetails,
  storeQueryParams,
  submitOrder,
} from '../action-creators';
import { saveDiscounts } from './discounts';
import { locationError, saveInVenue, saveLocation } from './location';
import { setMenuStatus } from './menu';
import { resetOrderData, setOrderPlace } from './order';
import {
  displayOrderingHours,
  displayPickupTimeWindow,
  savePlaces,
  setActivePlace,
  setClosesAt,
  setEstimatedPickupTime,
  setOpensAt,
} from './places';
import { saveProfile } from './profile';
import { promptedAuth, resetPromptedAuth } from './prompts';
import { saveToken } from './token';

const Actions = {
  saveToken,
  saveProfile,
  promptedAuth,
  resetPromptedAuth,
  saveLocation,
  locationError,
  saveInVenue,
  savePlaces,
  setActivePlace,
  setOrderPlace,
  resetOrderData,
  saveDiscounts,
  setMenuStatus,
  displayOrderingHours,
  displayPickupTimeWindow,
  setEstimatedPickupTime,
  setOpensAt,
  setClosesAt,
  storeQueryParams,
  submitOrder,
  setZoneSelectionDetails,
};

export default Actions;
