import React from 'react';

const CartItem = ({
  product,
  nodeEnv,
  imageError,
  index,
  localization,
  onImageError,
  onEditOrder,
  onRemoveOrder,
  isDiscountedLineItem,
  isDiscountCodeFeatureEnabled,
  lineItemDiscountlabel,
}) => {
  const renderSelectedModifiers = () => {
    const { modifiers } = product;
    return (
      modifiers &&
      modifiers.map((modifier, index) => (
        <div
          className="selected-modifier-wrapper"
          key={`selected-modifier-${modifier.id}-${index}`}>
          <div className="modifier-display-name">{modifier.displayName}</div>
          <div className="display-price">
            {modifier.price && Number(modifier.price) >= 0 && (
              <>
                <span className="add-sign">+</span>
                <span className="calculated-modifier-price">
                  {getModifierPrice(product, modifier)}
                </span>
              </>
            )}
          </div>
        </div>
      ))
    );
  };

  const getProductPrice = (product) => {
    const quantity = Number(product.quantity);
    const price = Number(product.price);

    return price < 0
      ? ''
      : `$${(Math.round(price * quantity * 100) / 100).toFixed(2)}`;
  };

  const getModifierPrice = (product, modifier) => {
    const quantity = Number(product.quantity);
    const price = Number(modifier.price);
    return price <= 0
      ? ''
      : `$${(Math.round(price * quantity * 100) / 100).toFixed(2)}`;
  };

  const image = product?.images?.reduce(
    (selectedImage, productImage) =>
      selectedImage && selectedImage.type === 'STICKER'
        ? selectedImage
        : productImage,
    null,
  );

  return (
    product &&
    localization && (
      <li className="order-item">
        <div className="order-item-swipe-component">
          <div className="order-item-info font-secondary bg-color-card">
            <div className="product-image">
              {image && !imageError ? (
                <img
                  alt="product"
                  className="image"
                  src={`https://${nodeEnv}-img.te2.io/unsafe/${
                    image.crop ? image.crop + '/' : ''
                  }${image.src}`}
                  onError={onImageError}
                />
              ) : (
                <div className="image"></div>
              )}
            </div>
            <div className="product color-text-primary">
              <div className="product-details-wrapper">
                <span className="product-name">{product.displayName}</span>
                <span
                  className={`product-price ${
                    isDiscountedLineItem ? 'discount-price-color' : ''
                  }`}>
                  {getProductPrice(product) ||
                    product.displayLineItemPrice ||
                    product.displayPrice}
                </span>
              </div>
              <div className="product-modifiers-wrapper">
                {renderSelectedModifiers()}
              </div>
              <span className="quantity">
                {localization.quantity}: {product.quantity}
              </span>
              {product.hasAgeRestriction && (
                <span className="age-restriction">
                  {localization.identificationRequired}
                </span>
              )}
              {isDiscountedLineItem &&
                isDiscountCodeFeatureEnabled &&
                lineItemDiscountlabel && (
                  <span className="discount-label">
                    {lineItemDiscountlabel}
                  </span>
                )}
            </div>
          </div>
          <div className="order-item-control">
            <button
              className="order-item-button"
              onClick={() => {
                onRemoveOrder(product, index);
              }}>
              {localization.removeButton}
            </button>
            <button
              className="order-item-button"
              onClick={() => {
                onEditOrder(product, index);
              }}>
              {localization.modifyButton}
            </button>
          </div>
        </div>
      </li>
    )
  );
};

export default CartItem;
