import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { reportPageView } from '../../helpers/googleAnalytics';
import {
  checkoutStep,
  GACheckoutStep,
} from '../../helpers/googleAnalytics/checkoutSteps';

import { getActivePlaceContent } from '../../redux/selectors';
import AccessoPay from '../AccessoPay';
import StripePayment from '../Stripe/stripePayment';
import NoPayment from './noPayment';

import './billing.scss';

class Billing extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;

    reportPageView(location && location.pathname);
    const { paymentProvider, testMode, testKey, key } = this.props.paymentKeys;
    switch (paymentProvider) {
      case 'STRIPE': {
        const paymentKey = testMode ? testKey : key;
        this.setState({
          initialized: true,
          stripePromise: loadStripe(paymentKey),
        });
        break;
      }
      case 'PASSPORT': {
        this.setState({
          initialized: true,
        });
        break;
      }
      default: {
        this.setState({
          initialized: true,
        });
        break;
      }
    }

    try {
      checkoutStep(GACheckoutStep.BILLING_INFO);
      if (paymentProvider === 'STRIPE') {
        checkoutStep(GACheckoutStep.PAYMENT_INFO);
      }
    } catch (error) {}
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    const { paymentProvider } = this.props.paymentKeys;
    return (
      <div className="billing-wrapper">
        {this.state.initialized && (
          <form onSubmit={this.handleSubmit}>
            {this.renderPaymentForm(paymentProvider)}
          </form>
        )}
      </div>
    );
  }

  renderPaymentForm(paymentProvider) {
    const { match } = this.props;
    switch (paymentProvider) {
      case 'STRIPE':
        return (
          <Elements stripe={this.state.stripePromise}>
            <StripePayment />
          </Elements>
        );
      case 'PASSPORT':
        return <AccessoPay />;
      default:
        return <NoPayment match={match} />;
    }
  }
}

const mapStateToProps = (state) => ({
  webConfig: state.webConfig,
  localization: state.localization,
  paymentKeys: state.paymentKey.keys,
  webContent: getActivePlaceContent(state.places).webContent || {},
  discountCode: state.discountCode,
  order: state.order,
  menu: state.menu,
  activePlace: getActivePlaceContent(state.places),
});

export default connect(mapStateToProps)(Billing);
