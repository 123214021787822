import { Venue } from '../../models/venue.model';
import { Action } from '../actions/getVenue.actions';
import { ActionType } from '../action-types';

const initialState = {
  id: '',
  name: '',
  location: {},
  features: {},
  content: {},
  webContent: {},
  timeZone: '',
  controls: {},
  loading: false,
  errorMessage: '',
};

export const getVenueReducer = (
  state: Venue = initialState,
  action: Action,
) => {
  switch (action.type) {
    case ActionType.GET_VENUE:
      return {
        ...state,
        loading: true,
        errorMessage: '',
      };

    case ActionType.GET_VENUE_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
        errorMessage: '',
      };
    }

    case ActionType.GET_VENUE_FAILURE: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };
    }

    default:
      return state;
  }
};
