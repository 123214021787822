export const isPlaceOverrideInVenue = (placeFeatures) => {
  return placeFeatures && placeFeatures.overrideInVenue ? true : false;
};

export const isWebConfigOverrideInVenue = (webConfig) => {
  return webConfig && webConfig.overrideInVenue ? true : false;
};

export const shouldShowOutOfVenueMessage = (
  webConfig,
  location,
  placeFeatures,
) => {
  const result =
    // show if placeOverride is FALSE
    !isPlaceOverrideInVenue(placeFeatures) &&
    // show if override is FALSE
    !isWebConfigOverrideInVenue(webConfig) &&
    // show if in venue is TRUE
    webConfig.inVenueRequired &&
    // show if they are not in location
    !location.inVenue;

  return result;
};

export const shouldRequestRefreshedLocation = (
  webConfig,
  location,
  placeFeatures,
) => {
  const refresh =
    // request if override is FALSE
    !isPlaceOverrideInVenue(placeFeatures) &&
    // request if override is FALSE
    !isWebConfigOverrideInVenue(webConfig) &&
    // request if in venue is required
    webConfig.inVenueRequired &&
    // request if we HAVE asked before
    location.asked &&
    // check if they have denied prior or failed to click in time
    (location.error === 'permissionDenied' || location.error === 'timeout');

  return refresh;
};

export const shouldRequestRefreshedLocationWhenAsked = (
  webConfig,
  location,
  placeFeatures,
) => {
  return (
    !isPlaceOverrideInVenue(placeFeatures) &&
    // request if in venue is required
    webConfig.inVenueRequired &&
    // request if we HAVE asked before
    location.asked &&
    // check if they have denied prior or failed to click in time
    (location.error === 'permissionDenied' || location.error === 'timeout')
  );
};
