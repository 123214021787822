import React from 'react';
import classNames from 'classnames';

export default (props) => {
  const { displayName, displayPrice, price, modifierGroupId, checked } = props;

  const onChange = (event) => {
    props.onClick(props.action, props.id, event.currentTarget.checked);
  };

  const displayNameClasses = classNames(
    'modifier-text',
    'color-text-primary',
    'header-text',
    'uppercase',
    props.disabled ? 'color-disabled' : '',
  );
  const checkmarkClasses = classNames(
    'checkmark',
    'color-dark-highlight',
    'round',
    'bg-after-color-accent',
    checked ? 'border-color-accent' : '',
  );
  const modifierClasses = classNames(
    'modifier-price',
    'font-primary',
    'color-text-tertiary',
  );

  return (
    <div className="modifier-container">
      <label className="modifier-wrapper">
        <p className={displayNameClasses}>{displayName}</p>
        <input
          id={`${props.id}`}
          type="radio"
          name={modifierGroupId}
          onChange={onChange}
          checked={checked}
        />
        <span className={checkmarkClasses} />
        {price > 0 && <span className={modifierClasses}>+ {displayPrice}</span>}
      </label>
    </div>
  );
};
