export const LOAD_LOCALIZATION = 'LOAD_LOCALIZATION';
export const UPDATE_LOCALIZATION = 'UPDATE_LOCALIZATION';

export const loadLocalization = (localizationData) => {
  return {
    type: LOAD_LOCALIZATION,
    data: localizationData,
  };
};

export const updateLocalization = (keypath, value) => {
  return {
    type: UPDATE_LOCALIZATION,
    data: { keypath, value },
  };
};
