import React from 'react';
import Boot from './components/Boot';
import store from './redux';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import { history } from './helpers/history';

import './styles/main.scss';
import './assets/style.css';
import { PeblThemeProvider, Toaster, ToastAnimation } from 'pebl';

const routeBase = `/config/:config/venue/:venue`;

const Root = () => (
  <Provider store={store}>
    <PeblThemeProvider>
      <Toaster animationType={ToastAnimation.BOUNCE} verticalOffset={30} />
    </PeblThemeProvider>
    <Router history={history}>
      <Route
        path={[
          `${routeBase}/order/:orderId/ptw/change`,
          `${routeBase}/order/:orderId`,
          `${routeBase}/place/:placeId/menu/:menuId/cat/:category/pickup-loc/:pickupLoc/pickup-text/:pickupText`,
          `${routeBase}/place/:placeId/menu/:menuId/cat/:category/pickup-loc/:pickupLoc`,
          `${routeBase}/place/:placeId/menu/:menuId/cat/:category/pickup-text/:pickupText`,
          `${routeBase}/place/:placeId/menu/:menuId/pickup-loc/:pickupLoc/pickup-text/:pickupText`,
          `${routeBase}/place/:placeId/menu/:menuId/pickup-loc/:pickupLoc`,
          `${routeBase}/place/:placeId/menu/:menuId/pickup-text/:pickupText`,
          `${routeBase}/place/:placeId/menu/:menuId/cat/:category`,
          `${routeBase}/place/:placeId/menu/:menuId`,
          `${routeBase}/place/:placeId`,
          `${routeBase}/places`,
          `${routeBase}`,
          '/',
        ]}
        component={Boot}
      />
    </Router>
  </Provider>
);

export default Root;
