import React from 'react';
import { connect } from 'react-redux';
import MenuCategory from '../MenuCategory';

const MenuCategoryList = ({ categories = [], match }) => {
  const hasCategories = !!(categories && categories[0]);
  const activeCategory =
    match.params.category || (hasCategories ? categories[0].id : '');
  return (
    hasCategories && (
      <div className="menu-container bg-color-primary">
        <ul className="menu">
          {categories.map((category, index) => {
            return (
              <MenuCategory
                activeCategory={activeCategory}
                category={category}
                match={match}
                key={`menu-category-${index}`}
              />
            );
          })}
        </ul>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  categories: state.menu.categories,
});

export default connect(mapStateToProps)(MenuCategoryList);
