import {
  SET_ORDER_PLACE,
  ADD_TO_ORDER,
  REMOVE_PRODUCT_FROM_ODER,
  RESET_ORDER_DATA,
  SAVE_ORDER_DATA,
  UPDATE_ORDER,
  UPDATE_ORDER_GRATUITY,
  RESET_ORDER_ID,
  ADD_SPECIAL_REQUEST,
  RESET_SPECIAL_REQUEST,
} from '../actions/order';
import { ActionType } from '../action-types/index';
import { findIndex, remove } from 'lodash/array';
import { forEach, includes } from 'lodash/collection';
import { formatCurrency } from '../../helpers/format';

const defaultState = {
  orderId: null,
  bundles: [],
  modifiers: [],
  placeId: '',
  products: [],
  discounts: [],
  source: 'WEB',
  specialInstructions: null,
  zone: null,
  vehicleMake: null,
  vehicleModel: null,
  vehicleColor: null,
  totalQuantity: 0,
  orderGratuity: 0,
  gratuityOption: null,
  cancelling: false,
  error: '',
};

const getTotalQuantity = (products) => {
  let totalQuantity = 0;

  products &&
    products.forEach((product) => {
      totalQuantity = totalQuantity + product.quantity;
    });

  return totalQuantity;
};

const compareModifiers = (product, payloadData) => {
  let isAdded = true;
  forEach(payloadData.modifiers, (modifier) => {
    let prodModifiers = product.modifiers.map(
      (modifier) => modifier.modifierId,
    );
    if (isAdded) {
      isAdded = includes(prodModifiers, modifier.modifierId);
    }
  });

  return isAdded;
};

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case SET_ORDER_PLACE: {
      return {
        ...state,
        placeId: data,
      };
    }
    case ActionType.GET_ORDER:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case ActionType.GET_ORDER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        totalQuantity: getTotalQuantity(action.payload.products),
        loading: false,
        error: undefined,
      };

    case ActionType.GET_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case ActionType.CHECKIN_ORDER:
        return {
          ...state,
          loading: true,
          error: undefined,
        };
    case ActionType.CHECKIN_ORDER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: undefined,
        totalQuantity: getTotalQuantity(action.payload.products),
      };
      case ActionType.CHECKIN_ORDER_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.payload
        };
    case SAVE_ORDER_DATA: {
      return {
        ...state,
        ...data,
        totalQuantity: getTotalQuantity(data.products),
      };
    }
    case UPDATE_ORDER: {
      let index = findIndex(state.products, (product, index) => {
        return (
          product.productId === data.productId &&
          product.lineItemId !== data.lineItemId &&
          compareModifiers(product, data)
        );
      });

      let newProduct = data;
      let products = [];
      let productCheck = state.products[index];

      if (index > -1) {
        let exists = true;

        let dataModifiers = [];
        forEach(data.modifiers, (modifier) => {
          dataModifiers.push(modifier.modifierId);
        });

        forEach(productCheck.modifiers, (modifier) => {
          if (exists) {
            exists = dataModifiers.indexOf(modifier.modifierId) > -1;
          }
        });

        if (exists) {
          newProduct = {
            ...state.products[index],
            quantity: state.products[index].quantity + data.quantity,
          };

          state.products[index] = newProduct;
          let duplicateOrderIndex = findIndex(
            state.products,
            ({ lineItemId }) => lineItemId === data.lineItemId,
          );

          products = state.products;
          if (duplicateOrderIndex !== index) {
            remove(
              state.products,
              ({ lineItemId }) => lineItemId === data.lineItemId,
            );
          }
        } else {
          products = [...state.products, { ...data }];
        }
      } else {
        products = state.products;
        let index = findIndex(
          products,
          ({ lineItemId }) => lineItemId === data.lineItemId,
        );
        products[index] = data;
      }

      return {
        ...state,
        products: [...products],
        totalQuantity: getTotalQuantity(products),
        orderGratuity: 0,
        gratuityOption: null,
      };
    }
    case UPDATE_ORDER_GRATUITY: {
      const { amount, option } = data;
      const orderBillTotal = state.orderTotal + amount;
      const displayOrderGratuity = formatCurrency(state.currency, amount);
      const displayOrderBillTotal = formatCurrency(
        state.currency,
        orderBillTotal,
      );
      return {
        ...state,
        orderGratuity: amount,
        gratuityOption: option,
        orderBillTotal,
        displayOrderGratuity,
        displayOrderBillTotal,
      };
    }
    case ADD_TO_ORDER: {
      const products = [
        ...state.products,
        {
          ...data,
        },
      ];

      return {
        ...state,
        products: [...products],
        totalQuantity: getTotalQuantity(products),
        orderGratuity: 0,
        gratuityOption: null,
      };
    }
    case RESET_ORDER_DATA: {
      return {
        ...defaultState,
      };
    }
    case RESET_ORDER_ID: {
      const newState = {
        ...state,
        orderId: null,
      };

      return newState;
    }
    case REMOVE_PRODUCT_FROM_ODER: {
      let products = state.products;

      remove(products, (product) => {
        return product.lineItemId === data.lineItemId;
      });

      return {
        ...state,
        ...data,
        products: [...products],
        totalQuantity: getTotalQuantity(products),
        orderGratuity: 0,
        gratuityOption: null,
      };
    }
    case ADD_SPECIAL_REQUEST: {
      return {
        ...state,
        specialInstructions: data,
      };
    }
    case RESET_SPECIAL_REQUEST: {
      return {
        ...state,
        specialInstructions: null,
      };
    }
    case ActionType.CANCEL_ORDER: {
      return {
        ...state,
        cancelling: true,
      };
    }

    case ActionType.CANCEL_ORDER_SUCCESS: {
      return {
        ...defaultState,
      };
    }

    case ActionType.CANCEL_ORDER_FAILURE: {
      return {
        ...state,
        cancelling: false,
        error: data,
      };
    }
    default:
      return state;
  }
};
