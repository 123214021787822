import { SAVE_PAYMENT_KEY } from '../actions/api/paymentKey';

const defaultState = {
  keys: {},
};

export default (state = defaultState, action) => {
  const { type, keys } = action;
  switch (type) {
    case SAVE_PAYMENT_KEY: {
      return {
        keys: keys || {},
      };
    }
    default:
      return state;
  }
};
