import React from 'react';
import { connect } from 'react-redux';

import { shouldPromptForAuth } from '../helpers/authPrompt/authPrompt.helper';
import { history } from '../helpers/history';
import actions from '../redux/actions';
import { SIRIUSWARE_LOGIN_FEATURE } from '../redux/actions/api/features';
import { closeToast2, openToast2 } from '../redux/actions/toast2';

class AuthPrompter extends React.Component {
  componentDidMount() {
    const { showAuthPrompt, isSiriuswareClient, features } = this.props;
    const siriuswareLoginFeature =
      isSiriuswareClient && features[SIRIUSWARE_LOGIN_FEATURE];

    if (
      showAuthPrompt &&
      (!isSiriuswareClient || features[SIRIUSWARE_LOGIN_FEATURE])
    ) {
      const {
        uiGateway,
        promptedAuth,
        openToast2,
        closeToast2,
        localization: { popup, siriusware },
      } = this.props;
      const { authTitle, authBody, authSignInCta, authGuestCta } =
        siriuswareLoginFeature ? siriusware : popup;

      openToast2({
        title: siriuswareLoginFeature ? siriusware.authTitle : authTitle,
        plainTitle: true,
        message: siriuswareLoginFeature ? siriusware.authBody : authBody,
        smallMessage: true,
        buttons: [
          {
            label: siriuswareLoginFeature
              ? siriusware.authSignInCta
              : authSignInCta,
            action: () => {
              if (siriuswareLoginFeature)
                this.siriuswareLogin(promptedAuth, closeToast2);
              else this.defaultLogin(uiGateway, promptedAuth);
            },
          },
          {
            label: siriuswareLoginFeature
              ? siriusware.authGuestCta
              : authGuestCta,
            inverted: true,
            action: () => {
              promptedAuth();
              closeToast2();
            },
          },
        ],
      });
    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }

  defaultLogin(uiGateway, promptedAuth) {
    promptedAuth();
    window.location.replace(uiGateway);
  }

  siriuswareLogin(promptedAuth, closeToast2) {
    promptedAuth();
    closeToast2();
    const {
      match: {
        params: { config, venue },
      },
    } = this.props;
    history.push(`/config/${config}/venue/${venue}/sign-in`);
  }
}

const mapStateToProps = (state) => ({
  showAuthPrompt: shouldPromptForAuth(
    state.webConfig,
    state.token,
    state.prompts,
  ),
  isSiriuswareClient: state.webConfig.isSiriuswareClient || false,
  features: state.features,
  localization: state.localization,
});

const mapDispatchToProps = (dispatch) => ({
  promptedAuth: () => {
    dispatch(actions.promptedAuth());
  },
  openToast2: (data) => {
    dispatch(openToast2(data));
  },
  closeToast2: () => {
    dispatch(closeToast2());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthPrompter);
