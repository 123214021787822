import React from 'react';
import { connect } from 'react-redux';

import { getNormalizedLocale } from '../../helpers/locale';
import { userIsAnonymous } from '../../helpers/token';
import Spinner from '../Spinner';

import {
  checkoutStep,
  GACheckoutStep,
} from '../../helpers/googleAnalytics/checkoutSteps';
import './accessoPayment.scss';

class AccessoPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.waitForScript = this.waitForScript.bind(this);
  }

  componentDidMount() {
    if (!window.accessoPay) {
      const script = document.createElement('script');
      script.id = 'accesso-pay-sdk';
      script.src = this.props.scriptSrc;
      script.async = true;
      document.body.appendChild(script);

      this.setState({ script, sdkLoaded: false });
      setTimeout(this.waitForScript, 100);
    } else {
      const { accessoPaySession } = this.state;
      if (!accessoPaySession) {
        this.configureFrame();
      }
    }

    checkoutStep(GACheckoutStep.PAYMENT_INFO);
  }

  mapThemeToPaymentStyle = () => {
    const { webConfig } = this.props;
    const { themeVariables } = webConfig;

    return themeVariables
      ? {
          layout: {
            backgroundColor: themeVariables[`color_canvas`],
            borderColor: themeVariables[`color_dark_highlight`],
            labelColor: themeVariables[`color_text_primary`],
            textColor: themeVariables[`color_text_primary`],
          },
          buttons: {
            default: {
              backgroundColor: themeVariables[`color_accent`],
              color: themeVariables[`color_text_secondary`],
              borderColor: themeVariables[`color_accent`],
            },
            defaultHover: {
              backgroundColor: themeVariables[`color_accent`],
              color: themeVariables[`color_text_secondary`],
              borderColor: themeVariables[`color_accent`],
            },
            primary: {
              backgroundColor: themeVariables[`color_accent`],
              color: themeVariables[`color_text_secondary`],
              borderColor: themeVariables[`color_accent`],
            },
            primaryHover: {
              backgroundColor: themeVariables[`color_accent`],
              color: themeVariables[`color_text_secondary`],
              borderColor: themeVariables[`color_accent`],
            },
            warn: {
              backgroundColor: themeVariables[`color_error`],
              color: themeVariables[`color_text_secondary`],
              borderColor: themeVariables[`color_error`],
            },
            warnHover: {
              backgroundColor: themeVariables[`color_error`],
              color: themeVariables[`color_text_secondary`],
              borderColor: themeVariables[`color_error`],
            },
          },
          radio: {
            backgroundColor: themeVariables[`color_accent`],
            borderColor: themeVariables[`color_accent`],
            defaultColor: themeVariables[`color_dark_highlight`],
          },
          checkbox: {
            backgroundColor: themeVariables[`color_accent`],
            color: themeVariables[`color_text_secondary`],
            defaultColor: themeVariables[`color_dark_highlight`],
          },
          input: {
            default: {
              backgroundColor: themeVariables[`color_card`],
              borderColor: themeVariables[`color_dark_highlight`],
              hoverColor: themeVariables[`color_card`],
              placeholderColor: themeVariables[`color_text_primary`],
            },
            error: {
              backgroundColor: themeVariables[`color_card`],
              borderColor: themeVariables[`color_error`],
            },
            errorTextColor: themeVariables[`color_error`],
            focused: {
              backgroundColor: themeVariables[`color_card`],
              borderColor: themeVariables[`color_dark_highlight`],
            },
          },
          modalBackgroundColor: themeVariables[`color_card`],
        }
      : undefined;
  };

  componentWillUnmount() {
    const { accessoPaySession } = this.state;
    if (accessoPaySession) {
      accessoPaySession.destroy();
    }
  }

  waitForScript() {
    if (window.accessoPay) {
      this.configureFrame();
      this.setState({ sdkLoaded: true });
      return;
    }
    setTimeout(this.waitForScript, 100);
  }

  configureFrame() {
    if (!window.accessoPay) {
      throw new Error('accessoPay SDK is not available');
    }

    const {
      billingInfo: { userName, userEmail },
      paymentKeys,
      order: { orderBillTotal, paymentAttributes },
      identity: { userId, access, accessParams },
    } = this.props;
    const { island, merchantId } = paymentKeys.ephemeralKey;
    const { handlePaymentComplete, handleCancel, handleInitFailure } =
      this.props;
    const locale = getNormalizedLocale();

    const nameParts = userName.split(' ');
    let firstName = nameParts.shift();
    let lastName = nameParts.join(' ');

    const style = this.mapThemeToPaymentStyle();
    const mountParams = {
      amount: orderBillTotal,
      taxReferenceId: paymentAttributes?.taxReferenceId,
      sessionId: userId,
      user: {
        email: userEmail,
        firstName,
        lastName,
      },
      style,
    };
    if (!userIsAnonymous(accessParams)) {
      mountParams.user = {
        ...mountParams.user,
        accessToken: access,
        customerId: userId,
      };
    }

    const accessoPaySession = window.accessoPay
      .configure(island, `${merchantId}`, userId, locale)
      .on('InitializeFailed', handleInitFailure)
      .on('PaymentComplete', handlePaymentComplete)
      .on('Cancel', handleCancel)
      .mount('accesso-pay', mountParams);

    this.setState({ accessoPaySession });
  }

  render() {
    return (
      <div>
        <Spinner show={!window.accessoPay} />
        <div id="accesso-pay"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  scriptSrc: state.webConfig.accessoPaySdk,
  identity: state.token.identity,
  order: state.order,
  paymentKeys: state.paymentKey.keys,
  webConfig: state.webConfig,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AccessoPayment);
