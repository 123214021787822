import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './discountCode.scss';
import Input from '../FormFields/Input';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Spinner from '../Spinner';
import PopUpHeader from '../PopUpHeader';
import { _updateOrder } from '../../redux/actions/api/order';
import DiscountSelectionList from './discountSelectionList';
import { toast } from 'pebl';
import helpIcon from '../../assets/icons/help.svg';
import DOMPurify from 'dompurify';

interface DisountCodeProps {
  localization: any;
}

const mapStateToProps = (state: any) => {
  return {
    localization: state.localization.cart,
  };
};

type DispatchProps = {
  updateOrder: any;
};

type Props = DisountCodeProps & DispatchProps;

const mapDispatchToProps = {
  updateOrder: _updateOrder,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const DiscountCode: FC<Props> = ({ localization, updateOrder }) => {
  const { getDiscountCode, setDiscountPopup, removeErrorText } = useActions();
  const [applyButton, setApplyButton] = useState(false);
  const [enteredCode, setEnteredCode] = useState('');
  const [showDiscountInfo, setShowDiscountInfo] = useState(false);

  let { errorText, loading, isPopup, seasonPassDiscount } = useTypedSelector(
    (state) => state.discountCode,
  );
  const {
    webContent: { discountTitle, discountDescription, discountsLabel },
  } = useTypedSelector((state) => state.venue);
  const cleanDiscountDescription = DOMPurify.sanitize(discountDescription);

  useEffect(() => {
    if (seasonPassDiscount) {
      updateOrder({}, () => {
        toast({
          icon: 'done',
          message: `${seasonPassDiscount?.displayName} applied`,
        });
      });
    }
  }, [updateOrder, seasonPassDiscount]);

  useEffect(() => {
    removeErrorText();
  }, [removeErrorText]);

  const discountInputChangeHandler = (name: string, value: string) => {
    if (value.length) {
      setApplyButton(true);
      setEnteredCode(value);
    } else {
      setApplyButton(false);
    }
  };

  const onApplyCode = () => {
    getDiscountCode(enteredCode);
  };

  const showDiscountModalInfo = () => {
    setShowDiscountInfo(!showDiscountInfo);
  };

  return (
    <>
      <div className="discount-wrapper">
        <div className="two-column-title">
          <h3 className="title">
            {discountsLabel ?? localization.discountsAndPromos}
          </h3>
          {discountTitle && discountDescription && (
            <button className="about-discount" onClick={showDiscountModalInfo}>
              <img src={helpIcon} alt="About" />
              <span className="text">{localization.discountsAbout}</span>
            </button>
          )}
        </div>
        <div className="content">
          <Input
            errorMessage={errorText}
            id={'discountCode'}
            type={'text'}
            initialValue={enteredCode}
            label={localization.enterCode}
            name={'discountCode'}
            onChange={discountInputChangeHandler}
            maxLength="25"
            placeholder={undefined}
            displayCharCounter={undefined}
          />
          {applyButton && (
            <div className="apply-button font-primary" onClick={onApplyCode}>
              {localization.applyButton}
            </div>
          )}
        </div>
      </div>
      <Spinner show={loading} />
      <div
        className={`popup-wrapper bg-color-card ${isPopup ? 'show' : 'hide'}`}>
        <div className="header-wrapper bg-color-primary">
          <PopUpHeader
            text={localization.discounts}
            onClose={() => {
              setDiscountPopup(false);
            }}
          />
        </div>
        <div className="content-wrapper">
          <DiscountSelectionList localization={localization} />
        </div>
      </div>
      <div
        className={`popup-wrapper discount-info bg-color-card ${
          showDiscountInfo ? 'show' : 'hide'
        }`}>
        <div className="header-wrapper bg-color-primary">
          <PopUpHeader
            text={discountTitle}
            onClose={() => {
              setShowDiscountInfo(false);
            }}
          />
        </div>
        <div className="content-wrapper">
          <div
            className="discount-description"
            dangerouslySetInnerHTML={{ __html: cleanDiscountDescription }}
          />
        </div>
      </div>
    </>
  );
};

export default connector(DiscountCode);
