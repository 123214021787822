import { SAVE_DISCOUNTS } from '../actions/discounts';

const defaultState = [];

export default (state = defaultState, action) => {
  const { type, discounts } = action;

  switch (type) {
    case SAVE_DISCOUNTS: {
      return discounts;
    }
    default:
      return state;
  }
};
