import './index.scss';

const PopUpHeader = ({ text, onClose }) => {
  return (
    <div className="popup-header-wrapper">
      <div className="left-button-wrapper">
        <span
          className="icon-i-close-x close-popup color-nav"
          onClick={onClose}
          alt="ic-close"></span>
      </div>
      <h2 className="popup-header color-text-secondary">
        <span>{text}</span>
      </h2>
    </div>
  );
};

export default PopUpHeader;
