import { find } from 'lodash/collection';
import findIndex from 'lodash/findIndex';
import { isEmpty } from 'lodash/lang';
import React from 'react';
import { connect } from 'react-redux';

import { sortProducts } from '../../helpers';
import { reportPageView } from '../../helpers/googleAnalytics';
import { firePitIcon } from '../../helpers/imageAssets';
import { _cancelOrder, _updateOrder } from '../../redux/actions/api/order';
import {
  addSpecialRequest,
  removeProductFromOrder,
  updateOrderGratuity,
} from '../../redux/actions/order';
import { openPopUp } from '../../redux/actions/popup';
import { getActivePlaceContent } from '../../redux/selectors';

import { toast } from 'pebl';
import {
  checkoutStep,
  GACheckoutStep,
} from '../../helpers/googleAnalytics/checkoutSteps';
import store from '../../redux';
import { removeDiscount } from '../../redux/action-creators/discountCode.action-creator';
import { DISCOUNT_FIELD } from '../../redux/actions/api/features';
import PageButton from '../PageButton';
import CartItem from './CartItem';
import DiscountCode from './discountCode';
import Gratuity from './gratuity';
import { reportRemoveFromCart } from './helper';
import './index.scss';
import SpecialRequest from './specialRequest';

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageError: false,
      gratuityError: false,
      styles: {
        left: 0,
        right: 0,
        maxLeft: 160, // Don't update this
      },
      swipeX: 0,
      swiping: false,
      lastX: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('touchmove', this.preventScroll, {
      passive: false,
    });
    const { location } = this.props;

    reportPageView(location && location.pathname);
    checkoutStep(GACheckoutStep.VIEW_CART);
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.preventScroll, {
      passive: false,
    });
  }

  preventScroll = (event) => {
    if (this.state.swiping && !this.props.popup.isOpen) {
      event.preventDefault();
      event.returnValue = false;
      return false;
    }
  };

  onImageError = () => {
    this.setState({ imageError: true });
  };

  editOrder = (item) => {
    const { menu, openPopUp } = this.props;
    let product = find(
      menu.products,
      (product) => product.id === item.productId,
    );
    openPopUp(product, item, true);
  };

  removeOrder = (item, index) => {
    const {
      _cancelOrder,
      removeProductFromOrder,
      _updateOrder,
      order,
      activePlace,
      menu: { products, categories },
    } = this.props;
    reportRemoveFromCart(item, index, activePlace, products, categories);

    if (order.products.length > 1) {
      removeProductFromOrder(item);
      _updateOrder();
    } else {
      _cancelOrder();
    }
  };

  onSwipingItem = (id) => {
    this.setState({ activeSwipeComponent: id, swiping: true });
  };

  onSwipeEnd = () => {
    this.setState({ swiping: false });
  };

  onGratuitySelect = (amount, option) => {
    this.props.updateOrderGratuity(amount, option);
  };

  onGratuityError = (gratuityError) => {
    this.setState({ gratuityError });
  };

  renderSelectedModifiers(modifiers) {
    return (
      modifiers &&
      modifiers.map((modifier, index) => (
        <div
          className="selected-modifier-wrapper"
          key={`selected-modifier-${modifier.id}-${index}`}>
          <span className="modifier-display-name">{modifier.displayName}</span>
        </div>
      ))
    );
  }

  renderDiscountCode() {
    const { localization, webConfig } = this.props;
    const { features } = store.getState();
    const isDiscountCodeFeature = features && features[DISCOUNT_FIELD];
    if (isDiscountCodeFeature && webConfig.isSeasonPassDiscountEnabled) {
      return <DiscountCode localization={localization} />;
    }
  }

  getDiscountLabel = (lineItemId) => {
    const {
      order: { discounts },
    } = this.props;
    const discountedLineItem = discounts.find((discount) => {
      return discount.lineItems.includes(lineItemId);
    });

    if (!discountedLineItem) {
      return null;
    }

    return discountedLineItem.displayName;
  };

  renderCartItems() {
    const {
      order: { products },
      webConfig: { nodeEnv },
      localization,
    } = this.props;
    const { imageError, activeSwipeComponent } = this.state;
    const { webConfig } = store.getState();

    return sortProducts(products).map((product, index) => (
      <CartItem
        key={`cart-item-${product.productId}-${index}`}
        product={product}
        nodeEnv={nodeEnv}
        imageError={imageError}
        index={index}
        localization={localization}
        onRemoveOrder={this.removeOrder}
        onEditOrder={this.editOrder}
        onImageError={this.onImageError}
        onSwipingItem={this.onSwipingItem}
        onSwipeEnd={this.onSwipeEnd}
        activeSwipeComponent={activeSwipeComponent}
        isDiscountedLineItem={this.checkIsDiscountedLineItem(
          product.lineItemId,
        )}
        isDiscountCodeFeatureEnabled={webConfig.isSeasonPassDiscountEnabled}
        lineItemDiscountlabel={this.getDiscountLabel(product.lineItemId)}
      />
    ));
  }

  handleSpecialRequestChange = (name, eventValue) => {
    this.props.addSpecialRequest(eventValue);
  };

  renderSpecialRequest() {
    const { localization, order, webConfig, placeContent, match } = this.props;
    const { placeId } = match.params;
    const specialRequestsEnabled =
      placeContent[placeId].features.specialRequestsEnabled;
    const initialValue = order.specialInstructions
      ? order.specialInstructions
      : null;
    const maxLength =
      webConfig.specialRequest && webConfig.specialRequest.maxNumberOfChar
        ? webConfig.specialRequest.maxNumberOfChar
        : 0;

    return (
      <SpecialRequest
        initialValue={initialValue}
        title={localization.specialRequests}
        label={localization.enterAnySpecialRequests}
        onChange={this.handleSpecialRequestChange}
        specialRequestsEnabled={specialRequestsEnabled}
        maxLength={maxLength}
      />
    );
  }

  orderLineItem = (displayName, value, key, extraClass = '') => (
    <div className={`order-row font-secondary ${extraClass}`} key={key}>
      <span className="text">{displayName}</span>
      <span className="subtotal">
        {key === 'discount' ? `-${value}` : value}
      </span>
    </div>
  );

  onRemoveDiscount = (discountId, displayName) => {
    this.props.removeDiscount(discountId, displayName);
    this.props._updateOrder({}, () => {
      toast({ message: `${displayName} removed` });
    });
  };

  render() {
    const { webContent, order, localization } = this.props;
    const hasDiscounts = order.discounts && order.discounts.length > 0;
    const hasGratuity =
      order.gratuityConfig && order.gratuityConfig.gratuityEnabled;

    const sanitizedFees =
      order.fees?.filter(
        (fee) => fee.displayName !== null && fee.displayName !== '',
      ) || [];
    const taxableFees = sanitizedFees.filter(
      (fee) => fee.taxable !== null && fee.taxable === true,
    );
    const nonTaxableFees = sanitizedFees.filter(
      (fee) => fee.taxable !== null && fee.taxable === false,
    );

    if (isEmpty(order.products)) {
      return (
        <div className="empty-cart-wrapper">
          <h3 className="title">{localization.itemsInOrder} (0)</h3>
          <div className="cart-content bg-color-canvas">
            <img src={firePitIcon} alt="Fire Pit" />
            <div className="empty-description color-text-tertiary font-primary">
              {localization.emptyOrder}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="cart-wrapper">
        <h3 className="title">
          {localization.itemsInOrder} ({order.totalQuantity})
        </h3>
        <div className="content">{this.renderCartItems()}</div>

        {this.renderSpecialRequest()}

        {this.renderDiscountCode()}

        {hasGratuity && (
          <React.Fragment>
            <h3 className="title">{localization.addGratuity}</h3>
            <div className="content">{this.renderGratuity()}</div>
          </React.Fragment>
        )}
        <h3 className="title">{localization.orderTotalSummary}</h3>
        <div className="content color-text-primary">
          {this.orderLineItem(
            localization.orderSubtotal,
            order.displayOrderSubtotal,
            'subtotal',
          )}
          {hasDiscounts &&
            this.orderLineItem(
              localization.discount,
              order.displayOrderDiscountTotal,
              'discount',
              'discount-row color-discount',
            )}
          {taxableFees.map((fee, i) =>
            this.orderLineItem(
              fee.displayName,
              fee.displaySubtotal,
              `taxable-fee-${i}`,
            ),
          )}
          {order.orderTax > 0 &&
            this.orderLineItem(
              localization.salesTax,
              order.displayOrderTax,
              'order-tax',
            )}
          {nonTaxableFees.map((fee, i) =>
            this.orderLineItem(
              fee.displayName,
              fee.displaySubtotal,
              `non-taxable-fee-${i}`,
            ),
          )}
          {order.orderGratuity > 0 &&
            this.orderLineItem(
              localization.gratuity,
              order.displayOrderGratuity,
              'gratuity',
            )}
          {this.orderLineItem(
            localization.orderTotal,
            order.displayOrderBillTotal,
            'order-total',
            'order-row-bold',
          )}
          {webContent && webContent.preorderTaxAndDiscount && (
            <p className="tax-sales-pass-text color-text-tertiary">
              {webContent.preorderTaxAndDiscount}
            </p>
          )}
        </div>
        {hasDiscounts && this.renderDiscounts()}
        <div className="button-container">
          <PageButton disabled={this.state.gratuityError} inline="true" />
        </div>
      </div>
    );
  }

  renderDiscounts() {
    const { order, localization, webConfig } = this.props;
    const { features } = store.getState();
    const isDiscountCodeFeature = features && features[DISCOUNT_FIELD];
    return (
      <section>
        <h3 className="title">{localization.discounts}</h3>
        <div className="content">
          {order.discounts.map((discount, index) => (
            <div
              className="order-row font-secondary discount-row color-discount"
              key={`discount-${index}`}>
              <span className="text">
                {this.renderDiscountIcon()}
                {discount.displayName} {localization.applied}
              </span>
              {isDiscountCodeFeature &&
                webConfig.isSeasonPassDiscountEnabled &&
                // below condition will ensure user cannot delete order level discount
                discount.lineItems &&
                discount.lineItems.length > 0 && (
                  <span
                    className="icon-i-close-x text discount-close-icon"
                    alt="ic-close"
                    onClick={() =>
                      this.onRemoveDiscount(discount?.id, discount?.displayName)
                    }></span>
                )}
            </div>
          ))}
        </div>
      </section>
    );
  }

  checkIsDiscountedLineItem = (productlineItem) => {
    const { order } = this.props;
    const { discounts } = order;
    const isDiscounted = discounts.some((discount) => {
      if (discount.lineItems) {
        return discount.lineItems.some((lineItem) => {
          return lineItem === productlineItem;
        });
      } else {
        return false;
      }
    });
    return isDiscounted;
  };

  renderDiscountIcon() {
    return (
      <svg
        fill="currentColor"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <title>ic_discount_24</title>
        <desc>Created with Sketch.</desc>
        <g
          id="ic_discount_24"
          stroke="none"
          strokeWidth="1"
          fill="currentColor"
          fillRule="evenodd">
          <g>
            <path
              d="M13.576087,2 C13.4283287,2.00169826 13.2788722,2.00849174 13.1277174,2.02717391 C12.8271061,2.06623652 12.4976222,2.1681387 12.2445652,2.42119565 L2.36684783,12.298913 C1.87941565,12.7863452 1.87941565,13.5913722 2.36684783,14.0788043 L9.92119565,21.6331522 C10.4086278,22.1205843 11.2136548,22.1205843 11.701087,21.6331522 L21.5788043,11.7554348 C21.830163,11.5040761 21.9337635,11.1711957 21.9728261,10.8722826 C22.0118887,10.5733696 22,10.2778535 22,9.98913043 L22,3.52173913 C22,2.6861413 21.3138587,2 20.4782609,2 L14.0108696,2 C13.8699048,2 13.7238452,1.99830174 13.576087,2 Z M13.5896739,2.86956522 C13.7170517,2.8661687 13.8631113,2.86956522 14.0108696,2.86956522 L20.4782609,2.86956522 C20.8434104,2.86956522 21.1304348,3.15658957 21.1304348,3.52173913 L21.1304348,9.98913043 C21.1304348,10.2914404 21.1287365,10.5648778 21.1032609,10.763587 C21.0777852,10.9622961 21.0370243,11.0608017 20.9673913,11.1304348 L11.0896739,21.0217391 C10.9351222,21.1762909 10.6871604,21.1762909 10.5326087,21.0217391 L10.4782609,20.9538043 L2.97826087,13.4673913 C2.82370913,13.3128396 2.82370913,13.0648778 2.97826087,12.9103261 L12.8695652,3.0326087 C12.9375,2.96467391 13.0495926,2.92221478 13.25,2.89673913 C13.3502039,2.88315217 13.4622961,2.87296217 13.5896739,2.86956522 Z M18.0869565,4.17391304 C17.1307743,4.17391304 16.3478261,4.9568613 16.3478261,5.91304348 C16.3478261,6.86922565 17.1307743,7.65217391 18.0869565,7.65217391 C19.0431387,7.65217391 19.826087,6.86922565 19.826087,5.91304348 C19.826087,4.9568613 19.0431387,4.17391304 18.0869565,4.17391304 Z M18.0869565,5.04347826 C18.5726904,5.04347826 18.9565217,5.42731 18.9565217,5.91304348 C18.9565217,6.39877739 18.5726904,6.7826087 18.0869565,6.7826087 C17.601223,6.7826087 17.2173913,6.39877739 17.2173913,5.91304348 C17.2173913,5.42731 17.601223,5.04347826 18.0869565,5.04347826 Z"
              id="path"
              fill="currentColor"
              fillRule="nonzero"></path>
          </g>
        </g>
      </svg>
    );
  }

  renderGratuity() {
    const { order, localization } = this.props;
    let { orderGratuity, gratuityOption, gratuityConfig } = order;
    if (gratuityOption === null && orderGratuity !== 0) {
      gratuityOption = findIndex(
        gratuityConfig.gratuityOptions,
        (o) => o.gratuityAmount === orderGratuity,
      );
      if (gratuityOption < 0) {
        gratuityOption = gratuityConfig.gratuityOptions.length;
      }
    }

    return (
      <Gratuity
        currency={order.currency}
        options={gratuityConfig.gratuityOptions}
        customEnabled={gratuityConfig.customGratuityEnabled}
        orderGratuity={orderGratuity}
        gratuityOption={gratuityOption}
        onSelect={this.onGratuitySelect}
        onError={this.onGratuityError}
        localization={localization}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const activePlace = getActivePlaceContent(state.places);
  return {
    menu: state.menu,
    order: state.order,
    popup: state.popup,
    localization: state.localization.cart,
    webConfig: state.webConfig,
    webContent: getActivePlaceContent(state.places).webContent,
    placeContent: state.places.placeContent,
    activePlace,
  };
};

const mapDispatchToProps = {
  openPopUp,
  removeProductFromOrder,
  updateOrderGratuity,
  addSpecialRequest,
  _cancelOrder,
  _updateOrder,
  removeDiscount,
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
