import store from '../../index';
import axios from 'axios';
import { pushPromise, popPromise } from '../spinner';
import { createPromiseId, redirectToErrorPage } from '../../../helpers';
import { openToast2 } from '../toast2';
import { history } from '../../../helpers/history';
import { offlineError } from '../../../helpers/errorMessages';

export const SAVE_PAYMENT_KEY = 'SAVE_PAYMENT_KEY';

export const savePaymentKey = (keys) => ({
  type: SAVE_PAYMENT_KEY,
  keys: keys,
});

export const _getPaymentKey = (placeId) => {
  const state = store.getState(),
    { webConfig, token } = state;

  let promiseId = createPromiseId();
  return (dispatch) => {
    const requestPlaceId = placeId ? placeId : `venue:${webConfig.venueId}`;

    dispatch(pushPromise(promiseId));
    return axios
      .get(`${webConfig.apiUrl}payments/key/${requestPlaceId}`, {
        headers: {
          Authorization: `Bearer ${token.identity.access}`,
          'x-venue-id': webConfig.venueId,
        },
      })
      .then((response) => {
        dispatch(savePaymentKey(response.data));
      })
      .catch((error) => {
        console.error(error);
        if (error.message === 'Network Error') {
          if (history.location.pathname !== '/error') {
            redirectToErrorPage(history, offlineError);
          }
        } else {
          dispatch(
            openToast2({
              title: webConfig.genericErrorTitle,
              message: webConfig.genericErrorMessage,
            }),
          );
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};
