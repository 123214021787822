import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getCloseUrl } from '../../helpers';
import { formatTimeCheckinWindow } from '../../helpers/pickupTimeWindows';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import actions from '../../redux/actions';
import {
  _loadAvailableWindows,
  _rescheduleWindow,
  _updateCustomerInfo,
} from '../../redux/actions/api/order';
import {
  _setNotificationTags,
  _setVenueNotificationTags,
} from '../../redux/actions/api/tags';
import { _setSelectedCheckinTimeWindow } from '../../redux/actions/checkinTimeWindows';
import { closeToast2, openToast2 } from '../../redux/actions/toast2';
import { ChangeWindowReason } from '../../routes/ChangePickupWindow';
import OrderButton from '../OrderButton/OrderButton';
import './index.scss';

const PickupTimeWindowsList = ({
  listState,
  setSelectedCheckinTimeWindow,
  checkinTimeWindows,
  loadAvailableWindows,
  onSelect = undefined, // optional
  loading,
  selectedCheckinTimeWindow,
  rescheduleWindow,
  submitOrder,
  openToast2,
  closeToast2,
  setNotificationTags,
  setVenueNotificationTags,
  updateCustomerInfo,
}) => {
  const [isPTWListEmpty, setIsPTWListEmpty] = useState(false);
  const [isUnavailablePTW, setIsUnavailablePTW] = useState(false);
  const [isChangePtw, setIsChangePtw] = useState(false);
  const history = useHistory();
  const {
    menu,
    query,
    localization,
    order: { customerPhone, smsOptIn },
    zoneDetails: { isLockerPickup },
    webConfig: { venueId },
  } = useTypedSelector((state) => state);
  const { orderId } = useParams();
  const { cancelOrder } = useActions();

  const { emptyPTW, alertTitle, alertDesc } = localization.checkinTimeWindows;

  const handleClick = (timeWindow) => {
    setSelectedCheckinTimeWindow(timeWindow);
    if (onSelect) {
      onSelect(timeWindow);
    }
  };

  useEffect(() => {
    if (isUnavailablePTW) {
      setSelectedCheckinTimeWindow(null);
    }
  }, [setSelectedCheckinTimeWindow, isUnavailablePTW]);

  useEffect(() => {
    loadAvailableWindows(orderId);
    setIsUnavailablePTW(listState === ChangeWindowReason.PTW_UNAVAILABLE);
    setIsChangePtw(listState === ChangeWindowReason.CHANGE_PTW);
  }, [loadAvailableWindows, setIsUnavailablePTW, orderId, listState]);

  useEffect(() => {
    setIsPTWListEmpty(!loading && checkinTimeWindows.length === 0);
  }, [checkinTimeWindows, loading]);

  const getPTWTitle = () => {
    if (isPTWListEmpty) {
      return emptyPTW;
    }
    if (isUnavailablePTW) {
      return alertTitle;
    }

    return '';
  };

  const getCheckinTimeWindows = () => {
    if (checkinTimeWindows)
      return checkinTimeWindows.map((timeWindow) => {
        const { startTime, endTime, windowId } = timeWindow;
        const selectedClass =
          selectedCheckinTimeWindow &&
          selectedCheckinTimeWindow.windowId === windowId
            ? 'color-text-secondary bg-color-primary'
            : 'color-text-primary';
        return (
          <div
            className={`checkin-time-window ${selectedClass}`}
            key={windowId}
            onClick={() => {
              handleClick(timeWindow);
            }}>
            {formatTimeCheckinWindow(startTime, endTime)}
          </div>
        );
      });
  };

  const title = getPTWTitle();

  const navigateToConfirmation = () => {
    const { config, placeId, fromMobile, foodAndBev } = query.params;

    if (fromMobile || foodAndBev) {
      closeWebView();
    }
    const menuId = menu.id;
    history.push(
      `/config/${config}/venue/${venueId}/place/${placeId}/menu/${menuId}/confirmation`,
    );
  };

  const closeWebView = () => {
    window.location.replace(getCloseUrl(venueId));
  };

  const handleOk = (cancel) => {
    const { fromMobile, foodAndBev } = query.params;
    if (fromMobile || foodAndBev) {
      closeWebView();
    }
    if (cancel) {
      handleCancelOrder();
    }
  };

  const handleConfirmSelection = () => {
    const onSuccess = isUnavailablePTW ? navigateToConfirmation : closeWebView;
    if (listState === ChangeWindowReason.PTW_UNAVAILABLE) {
      handleTextNotification();
      submitOrder(history);
    } else {
      rescheduleWindow(selectedCheckinTimeWindow, orderId, onSuccess);
    }
  };

  const handleTextNotification = () => {
    if (!isLockerPickup && customerPhone) {
      setVenueNotificationTags();
      setNotificationTags(customerPhone, smsOptIn, updateCustomerInfo);
    }
  };

  const handleCancelOrder = () => {
    openToast2({
      title: `${localization.order.orderCancel}?`,
      message: localization.order.orderCancelQuestion,
      dismiss: true,
      buttons: [
        {
          label: localization.order.orderCancel,
          action: () => {
            cancelOrder(orderId, history);
          },
        },
        {
          label: localization.button.no,
          inverted: true,
          action: () => {
            closeToast2();
          },
        },
      ],
    });
  };

  return (
    <div className="pickup-time-window-list-selector">
      {(isPTWListEmpty || isUnavailablePTW) && (
        <h3 className="alert-title font-secondary">{title}</h3>
      )}
      {isUnavailablePTW && (
        <p className="alert-desc font-secondary">{alertDesc}</p>
      )}
      <div className="checkin-time-wrapper">{getCheckinTimeWindows()}</div>
      <div className="button-wrapper">
        {!isChangePtw && isPTWListEmpty && (
          <OrderButton type="OK" onClick={() => handleOk(true)} />
        )}
        {isChangePtw && !isPTWListEmpty && (
          <>
            <OrderButton
              type="CONFIRM"
              disabled={selectedCheckinTimeWindow === null}
              onClick={handleConfirmSelection}
            />
          </>
        )}
        {isChangePtw && isPTWListEmpty && (
          <>
            <OrderButton type="OK" onClick={() => handleOk(false)} />
          </>
        )}
        {isUnavailablePTW && !isPTWListEmpty && (
          <>
            <OrderButton
              type="CONFIRM"
              disabled={selectedCheckinTimeWindow === null}
              onClick={handleConfirmSelection}
            />
            <OrderButton type="CANCEL" onClick={handleCancelOrder} />
          </>
        )}
        {isUnavailablePTW && isPTWListEmpty && (
          <>
            <OrderButton type="OK" onClick={() => handleOk(true)} />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    checkinTimeWindows: state.checkinTimeWindows.checkinTimeWindows,
    selectedCheckinTimeWindow:
      state.checkinTimeWindows.selectedCheckinTimeWindow,
    loading: state.spinner.show,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedCheckinTimeWindow: (...args) => {
      dispatch(_setSelectedCheckinTimeWindow(...args));
    },
    loadAvailableWindows: (...args) => {
      dispatch(_loadAvailableWindows(...args));
    },
    rescheduleWindow: (...args) => {
      dispatch(_rescheduleWindow(...args));
    },
    openToast2: (data) => {
      dispatch(openToast2(data));
    },
    closeToast2: (data) => {
      dispatch(closeToast2(data));
    },
    submitOrder: (history) => {
      dispatch(actions.submitOrder(history));
    },
    setNotificationTags: (...args) => {
      dispatch(_setNotificationTags(...args));
    },
    setVenueNotificationTags: () => {
      dispatch(_setVenueNotificationTags());
    },
    updateCustomerInfo: (data) => {
      dispatch(_updateCustomerInfo(data));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PickupTimeWindowsList);
