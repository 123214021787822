import {
  SET_SELECTED_CHECKIN_TIME_WINDOW,
  LOAD_CHECKIN_TIME_WINDOWS,
} from '../actions/checkinTimeWindows';

const initialState = {
  checkinTimeWindows: [],
  selectedCheckinTimeWindow: null,
};

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_CHECKIN_TIME_WINDOWS:
      return {
        ...state,
        checkinTimeWindows: payload,
      };
    case SET_SELECTED_CHECKIN_TIME_WINDOW:
      return {
        ...state,
        selectedCheckinTimeWindow: payload,
      };
    default:
      return state;
  }
};
