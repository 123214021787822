export const PROMPTED_AUTH = 'PROMPTED_AUTH';
export const RESET_PROMPTED_AUTH = 'RESET_PROMPTED_AUTH';

export const promptedAuth = () => ({
  type: PROMPTED_AUTH,
  data: true,
});

export const resetPromptedAuth = () => ({
  type: RESET_PROMPTED_AUTH,
});
