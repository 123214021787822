import { OPEN_TOAST2, CLOSE_TOAST2 } from '../actions/toast2';

const defaultState = {
  isOpen: false,
  title: '',
  plainTitle: false,
  message: '',
  smallMessage: false,
  hasConfirmation: false,
  buttons: null,
  callback: null,
  dismissCallback: null,
  htmlMessage: null,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_TOAST2:
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case CLOSE_TOAST2:
      return {
        ...state,
        ...defaultState,
      };
    default:
      return state;
  }
};
