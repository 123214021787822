import React from 'react';

import './index.scss';

export default ({ isVisible, message, title, toggleVisibility }) => {
  return (
    <div
      className={`tooltip ${isVisible ? 'show' : ''}`}
      onClick={toggleVisibility}>
      {title && <h4 className="tooltip-title color-text-primary">{title}</h4>}
      <p className="tooltip-body color-text-primary">{message}</p>
    </div>
  );
};
