export const SAVE_LOCATION = 'SAVE_LOCATION';
export const SAVE_IN_VENUE = 'SAVE_IN_VENUE';

export const saveLocation = (lat, lon) => {
  let data = {
    asked: true,
    error: null,
    lat,
    lon,
  };
  return {
    type: SAVE_LOCATION,
    data,
  };
};

export const locationError = (error) => {
  let data = {
    asked: true,
    error,
    lat: null,
    lon: null,
  };
  return {
    type: SAVE_LOCATION,
    data,
  };
};

export const saveInVenue = (inVenue) => {
  let data = { inVenue };
  return {
    type: SAVE_IN_VENUE,
    data,
  };
};
