export const SAVE_PLACES = 'SAVE_PLACES';
export const SET_ACTIVE_PLACE = 'SET_ACTIVE_PLACE';
export const SAVE_PLACE_CONTENT = 'SAVE_PLACE_CONTENT';
export const DISPLAY_ORDERING_HOURS = 'DISPLAY_ORDERING_HOURS';
export const DISPLAY_PICKUP_TIME_WINDOW = 'DISPLAY_PICKUP_TIME_WINDOW';
export const SET_ESTIMATED_PICKUP_TIME = 'SET_ESTIMATED_PICKUP_TIME';
export const SET_OPENS_AT = 'SET_OPENS_AT';
export const SET_CLOSES_AT = 'SET_CLOSES_AT';

export const savePlaces = (data) => ({
  type: SAVE_PLACES,
  data: data,
});

export const setActivePlace = (data) => {
  return {
    type: SET_ACTIVE_PLACE,
    data: data,
  };
};

export const savePlaceContent = (data) => ({
  type: SAVE_PLACE_CONTENT,
  data: data,
});

export const displayOrderingHours = (data) => ({
  type: DISPLAY_ORDERING_HOURS,
  data: data,
});

export const displayPickupTimeWindow = (data) => ({
  type: DISPLAY_PICKUP_TIME_WINDOW,
  data: data,
});

export const setEstimatedPickupTime = (data) => ({
  type: SET_ESTIMATED_PICKUP_TIME,
  data: data,
});

export const setOpensAt = (data) => ({
  type: SET_OPENS_AT,
  data: data,
});

export const setClosesAt = (data) => ({
  type: SET_CLOSES_AT,
  data: data,
});
