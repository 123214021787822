export const SIRIUSWARE_LOG_IN = 'SIRIUSWARE_LOG_IN';
export const ERROR_SIRIUSWARE_LOGIN = 'ERROR_SIRIUSWARE_LOGIN';

export const logIn = (passes) => {
  return {
    type: SIRIUSWARE_LOG_IN,
    data: { passes },
  };
};

export const setErrorLogin = () => {
  return {
    type: ERROR_SIRIUSWARE_LOGIN,
  };
};
