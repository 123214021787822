export const OPEN_TOAST2 = 'OPEN_TOAST2';
export const CLOSE_TOAST2 = 'CLOSE_TOAST2';

export const openToast2 = (data) => {
  return {
    type: OPEN_TOAST2,
    payload: data,
  };
};

export const closeToast2 = () => {
  return {
    type: CLOSE_TOAST2,
  };
};
