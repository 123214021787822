import { ActionType } from '../action-types';
import { Action } from '../actions/zoneSelectionDetails.actions';

interface ZoneDetailsState {
  zone?: string;
  zoneUserCustomValue?: string;
  isLockerPickup?: boolean;
}

const initialState = {};

export const zoneSelectionDetailsReducer = (
  state: ZoneDetailsState = initialState,
  { type, payload }: Action,
) => {
  switch (type) {
    case ActionType.SAVE_ZONE_SELECTION_DETAILS:
      return { ...state, ...payload };

    default:
      return state;
  }
};
