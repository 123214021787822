import { SET_ERROR, CLEAR_ERROR } from '../actions/errorStatus';
import { defaultError } from '../../helpers/errorMessages';

const defaultState = { ...defaultError, error: null };

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return { ...state, ...action.payload };
    case CLEAR_ERROR:
      return defaultState;
    default:
      return state;
  }
};
