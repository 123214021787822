import flagsmith from 'flagsmith';
import features from './features';
import { _setFeatures } from '../features';

export const _initFlagsmith = () => {
  return (dispatch) => {
    flagsmith.init({
      environmentID: process.env.REACT_APP_FLAGSMITH_ENVIRONMENT_ID,
      enableAnalytics: true,
      onChange: (oldFlags, params) => {
        let loadedFeatures = {};
        features.forEach((feature) => {
          loadedFeatures[feature] = flagsmith.hasFeature(feature);
        });
        dispatch(_setFeatures(loadedFeatures));
      },
    });
  };
};
