import {
  SET_PAYMENT_STATUS,
  BILLING_INFORMATION_STATE,
} from '../actions/payment';

const defaultState = {
  status: BILLING_INFORMATION_STATE,
};

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case SET_PAYMENT_STATUS: {
      const { status } = data;
      return { ...state, status };
    }
    default:
      return state;
  }
};
