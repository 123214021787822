import { _fetchWebConfig as fetchWebConfig } from './webConfig';
import {
  _getAnonymousToken as getAnonymousToken,
  useRefreshToken,
  getProfile,
} from './identity';
import { getLocation, checkIfCurrentLocationIsInVenue } from './location';
import { _getPlaces as getPlaces } from './places';
import { _getPaymentKey as getPaymentKey } from './paymentKey';
import { _cancelOrder as cancelOrder } from './order';
import { getDiscounts } from './discounts';

const API = {
  fetchWebConfig,
  getAnonymousToken,
  useRefreshToken,
  getProfile,
  getLocation,
  checkIfCurrentLocationIsInVenue,
  getPlaces,
  getPaymentKey,
  cancelOrder,
  getDiscounts,
};

export default API;
