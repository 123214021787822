import { ActionType } from '../action-types';
import { Action } from '../actions/ageRestrictionPrompt.action';

interface AgeRestrictionPromptState {
  isAgeRestrictionAccepted: boolean;
  isAgeRestrictionDeclined: boolean;
  isAgeRestrictionPromptSet: boolean;
}

const initialState = {
  isAgeRestrictionAccepted: false,
  isAgeRestrictionDeclined: false,
  isAgeRestrictionPromptSet: false,
};

export const ageRestrictionPromptReducer = (
  state: AgeRestrictionPromptState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case ActionType.AGE_RESTRICTION_ACCEPTED:
      return {
        ...state,
        isAgeRestrictionAccepted: action.payload,
      };

    case ActionType.AGE_RESTRICTION_DECLINED:
      return {
        ...state,
        isAgeRestrictionDeclined: action.payload,
      };

    case ActionType.SET_AGE_RESTRICTION_PROMPT:
      return {
        ...state,
        isAgeRestrictionPromptSet: action.payload,
      };

    default:
      return state;
  }
};
