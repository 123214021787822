export const SET_ORDER_PLACE = 'SET_ORDER_PLACE';
export const ADD_TO_ORDER = 'ADD_TO_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_GRATUITY = 'UPDATE_ORDER_GRATUITY';
export const SAVE_ORDER_DATA = 'SAVE_ORDER_DATA';
export const RESET_ORDER_DATA = 'RESET_ORDER_DATA';
export const RESET_ORDER_ID = 'RESET_ORDER_ID';
export const REMOVE_PRODUCT_FROM_ODER = 'REMOVE_PRODUCT_FROM_ODER';
export const ADD_SPECIAL_REQUEST = 'ADD_SPECIAL_REQUEST';
export const RESET_SPECIAL_REQUEST = 'RESET_SPECIAL_REQUEST';

export const setOrderPlace = (data) => ({
  type: SET_ORDER_PLACE,
  data,
});

export const addToOrder = (data) => ({
  type: ADD_TO_ORDER,
  data,
});

export const updateOrder = (data) => ({
  type: UPDATE_ORDER,
  data,
});

export const updateOrderGratuity = (amount, option) => ({
  type: UPDATE_ORDER_GRATUITY,
  data: { amount, option },
});

export const saveOrderData = (data) => ({
  type: SAVE_ORDER_DATA,
  data,
});

export const resetOrderData = () => ({
  type: RESET_ORDER_DATA,
});

export const resetOrderId = () => ({
  type: RESET_ORDER_ID,
});

export const removeProductFromOrder = (data) => ({
  type: REMOVE_PRODUCT_FROM_ODER,
  data,
});

export const addSpecialRequest = (data) => ({
  type: ADD_SPECIAL_REQUEST,
  data,
});

export const resetSpecialRequest = () => ({
  type: RESET_SPECIAL_REQUEST,
});
