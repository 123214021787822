const LockersIcon = (props: {
  /**
   * The class used to apply the primary color of the icon
   * @default '#7BC143'
   */
  colorClass?: string;
  /**
   * The color to apply to the secondary color of the icon
   * @default #FFFFFF
   */
  secondaryColor?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 414 414"
    height="48"
    width="48">
    <g transform="translate(26,-73)">
      <g>
        <path
          className={props.colorClass}
          style={{ color: props.colorClass ? undefined : '#7BC143' }}
          d="M25 73L25 487L167 487L167 73L25 73Z"></path>
        <path
          className={props.colorClass}
          style={{ color: props.colorClass ? undefined : '#7BC143' }}
          d="M185 73L185 487L327 487L327 73L185 73Z"></path>
        <path
          d="M39 112L57 112L57 135L135 135L135 112L153 112L153 153L39 153L39 112Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M199 112L217 112L217 135L295 135L295 112L313 112L313 153L199 153L199 112Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M39 176L57 176L57 199L135 199L135 176L153 176L153 217L39 217L39 176Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M199 176L217 176L217 199L295 199L295 176L313 176L313 217L199 217L199 176Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M64 279C77.7 279 89 290.3 89 304C89 317.7 77.7 329 64 329C50.3 329 39 317.7 39 304C39 290.3 50.3 279 64 279Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M224 279C237.7 279 249 290.3 249 304C249 317.7 237.7 329 224 329C210.3 329 199 317.7 199 304C199 290.3 210.3 279 224 279Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M64 297C60.03 297 57 300 57 304C57 308 60.03 311 64 311C67.97 311 71 308 71 304C71 300 67.97 297 64 297Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M224 297C220 297 217 300 217 304C217 308 220 311 224 311C228 311 231 308 231 304C231 300 228 297 224 297Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M48 407L144 407L144 425L48 425L48 407Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M208 407L304 407L304 425L208 425L208 407Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M48 441L144 441L144 459L48 459L48 441Z"
          fill={props.secondaryColor || '#ffffff'}></path>
        <path
          d="M208 441L304 441L304 459L208 459L208 441Z"
          fill={props.secondaryColor || '#ffffff'}></path>
      </g>
    </g>
  </svg>
);

export default LockersIcon;
