import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  handleDefaultZone,
  handleDefaultZoneUserCustomValue,
} from '../../helpers/defaultZone';
import {
  reportCompletedPayment,
  reportOrderSubmitted,
} from '../../helpers/googleAnalytics';
import { history } from '../../helpers/history';
import {
  _loadAvailableWindows,
  _submitOrder,
} from '../../redux/actions/api/order';
import { getActivePlaceContent } from '../../redux/selectors';
import Button from '../Button';
import BillingInfo from './billingInfo';

import {
  checkoutStep,
  GACheckoutStep,
} from '../../helpers/googleAnalytics/checkoutSteps';
import { PTWRoutes, routePTW } from '../../helpers/routePTW/routePTW';
import './noPayment.scss';

const mapStateToProps = (state) => ({
  profile: state.profile,
  webConfig: state.webConfig,
  localization: state.localization,
  pickupZones: state.orderReadiness.pickupZones,
  order: state.order,
  features: state.features,
  checkinTimeWindows: state.checkinTimeWindows,
  webContent: getActivePlaceContent(state.places).webContent || {},
  placeFeatures: getActivePlaceContent(state.places).features || {},
  placeControls: getActivePlaceContent(state.places).controls || {},
});

const mapDispatchToProps = {
  submitOrder: _submitOrder,
  loadAvailableWindows: _loadAvailableWindows,
};

class NoPayment extends React.Component {
  static propTypes = { match: PropTypes.object.isRequired };

  constructor(props) {
    super(props);

    const { webConfig, pickupZones } = props;
    const { firstName, lastName, email } = props.profile;
    const userName = firstName && lastName ? `${firstName} ${lastName}` : '';

    this.state = {
      userName,
      userEmail: email || '',
      zone: handleDefaultZone({ webConfig, pickupZones }),
      zoneUserCustomValue: handleDefaultZoneUserCustomValue({ webConfig }),
      infoValid: false,
    };

    // Function binds for ease of use in the render method
    this.onHandleSubmitOrderClicked =
      this.onHandleSubmitOrderClicked.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidity = this.handleValidity.bind(this);
  }

  handleChange({ name, value }, callback) {
    this.setState({ [name]: value }, callback);
  }

  handleValidity(infoValid) {
    this.setState({ infoValid });
  }

  isPTWValid = () => {
    const {
      placeFeatures,
      checkinTimeWindows: { selectedCheckinTimeWindow },
    } = this.props;
    return (
      !placeFeatures['pickupTimeWindowsEnabled'] || selectedCheckinTimeWindow
    );
  };

  render() {
    const {
      localization,
      order,
      pickupZones,
      webContent,
      features,
      loadAvailableWindows,
      checkinTimeWindows,
      placeFeatures,
      placeControls,
    } = this.props;
    const orderTotal = `${
      order && order.displayOrderBillTotal ? order.displayOrderBillTotal : ''
    }`;
    const { userName, userEmail, zone, zoneUserCustomValue, infoValid } =
      this.state;

    return (
      <section className="payment-form">
        <BillingInfo
          userName={userName}
          userEmail={userEmail}
          zone={zone}
          pickupZones={pickupZones}
          zoneUserCustomValue={zoneUserCustomValue}
          localization={localization.billing}
          placeFeatures={placeFeatures}
          features={features}
          loadAvailableWindows={loadAvailableWindows}
          webContent={webContent}
          placeControls={placeControls}
          selectedCheckinTimeWindow={
            checkinTimeWindows.selectedCheckinTimeWindow
          }
          handleChange={this.handleChange}
          handleValidity={this.handleValidity}
        />

        <div className="submit-button-wrapper">
          <Button
            onClick={this.onHandleSubmitOrderClicked}
            localization={localization.billing}
            disabled={!infoValid || !this.isPTWValid()}>
            <div className="button-content-wrapper">
              <span className="primary-text header-text uppercase">
                {localization.billing.submitOrder}
              </span>
              <span className="secondary-text">{orderTotal}</span>
            </div>
          </Button>
        </div>
      </section>
    );
  }

  async onHandleSubmitOrderClicked(event) {
    event.preventDefault();
    const { submitOrder, match, order } = this.props;
    const {
      match: {
        params: { config, venue },
      },
    } = this.props;
    if (!match) {
      console.warn("match wasn't provided by the router component");
      return;
    }

    // Get a reference to the billing name and email
    const { userEmail, userName, zone, zoneUserCustomValue } = this.state;
    const userInfo = { userEmail, userName, zone, zoneUserCustomValue };

    checkoutStep(GACheckoutStep.PAYMENT_COMPLETE, 'NONE');
    reportCompletedPayment();

    const redirect = () => {
      reportOrderSubmitted();
      this.navigateToPage('/confirmation');
    };
    const onPTWUnavailable = () => {
      routePTW(PTWRoutes.PTW_UNAVAILABLE_ROUTE, config, venue, order.orderId);
    };

    submitOrder(
      match.params,
      userInfo,
      null,
      redirect,
      null,
      null,
      null,
      onPTWUnavailable,
    );
  }

  navigateToPage(url) {
    const {
      match: {
        params: { config, venue, placeId, menuId },
      },
    } = this.props;
    history.push(
      `/config/${config}/venue/${venue}/place/${placeId}/menu/${menuId}${url}`,
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NoPayment);
