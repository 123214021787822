import {
  AnalyticsAction,
  AnalyticsItem,
  AnalyticsPurchase,
} from '../../models/googleAnalytics.model';
import { gaEvent } from './gaEvent';

export const AFFILIATION = 'F&B Web';

export const ecommerceActionCart = (
  actionName: AnalyticsAction,
  currency: string,
  value: number,
  items: Array<AnalyticsItem>,
) => {
  gaEvent(actionName, {
    currency,
    value,
    items,
  });
};

export const ecommerceActionView = (
  actionName: AnalyticsAction,
  item_list_id: string,
  item_list_name: string,
  items: Array<AnalyticsItem>,
) => {
  gaEvent(actionName, {
    item_list_id,
    item_list_name,
    items,
  });
};

export const ecommerceActionCheckout = (
  actionName: AnalyticsAction,
  currency: string,
  value: number,
  items: Array<AnalyticsItem>,
  coupon?: string,
  payment_type?: string,
) => {
  if (actionName === AnalyticsAction.ADD_PAYMENT_INFO) {
    gaEvent(actionName, {
      currency,
      value,
      coupon,
      payment_type,
      items,
    });
  } else {
    gaEvent(actionName, {
      currency,
      value,
      coupon,
      items,
    });
  }
};

export const ecommerceActionPurchase = (
  purchase: AnalyticsPurchase,
  items: Array<AnalyticsItem>,
) => {
  gaEvent(AnalyticsAction.PURCHASE, {
    transaction_id: purchase.transaction_id,
    affiliation: purchase.affiliation,
    value: purchase.value,
    tax: purchase.tax,
    shipping: purchase.shipping,
    currency: purchase.currency,
    coupon: purchase.coupon,
    items,
  });
};
