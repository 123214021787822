import { connect } from 'react-redux';
import { getTimeFormat } from '../../../helpers/format';
import { getZoneName } from '../../../helpers/pickupZone/pickupZone';
import {
  LockerOrderStatus,
  Order,
  OrderStatus,
  PickupZoneType,
} from '../../../models/order.model';
import { getActivePlaceContent } from '../../../redux/selectors';

import './PickupInformation.scss';

interface StateProps {
  activePlaceContent: any;
  localization: any;
  order: Order;
  pickupZones: any;
}

function PickupInformation({
  order,
  localization,
  activePlaceContent,
  pickupZones,
}: StateProps) {
  const webContent = activePlaceContent.webContent || {};
  const pickupZoneName = getZoneName(
    order,
    activePlaceContent?.name,
    pickupZones,
  );

  const hasPickupInfo =
    order.estimatedPrepTime ||
    pickupZoneName ||
    webContent.preorderConfirmationPickupInstructions;

  const orderPickupLocation = webContent.preorderConfirmationPickupLocation
    ? pickupZoneName
      ? `${pickupZoneName}, ${webContent.preorderConfirmationPickupLocation}`
      : webContent.preorderConfirmationPickupLocation
    : pickupZoneName;

  return (
    <div className={'pickup-info-wrapper'}>
      {hasPickupInfo && (
        <section>
          <div className={'order-pickup-notes-wrapper'}>
            {order.pickupZoneDetails?.type === PickupZoneType.LOCKER_PICKUP &&
              (order.pickupZoneDetails?.status === LockerOrderStatus.LOADED ||
                order.pickupZoneDetails?.status === LockerOrderStatus.EXPIRED ||
                order.pickupZoneDetails?.status === LockerOrderStatus.DONE) && (
                <div className="prep-time">
                  <div className="color-confirmation-icon">
                    <span className="icon-small icon-i-clock"></span>
                  </div>
                  <p className="font-secondary color-confirmation-text">
                    {order.pickupZoneDetails.status ===
                      LockerOrderStatus.LOADED && (
                      <>
                        {`${localization.lockerPickupInstructions1} ${order.pickupZoneDetails.hold} ${localization.lockerPickupInstructions2}`}
                      </>
                    )}
                    {order.pickupZoneDetails.status ===
                      LockerOrderStatus.EXPIRED && (
                      <>{`${localization.lockerPickupExpiringInstructions}`}</>
                    )}
                    {order.pickupZoneDetails.status ===
                      LockerOrderStatus.DONE && (
                      <>{`${localization.lockerPickedUpMessage}`}</>
                    )}
                  </p>
                </div>
              )}
            {orderPickupLocation && (
              <div className="location">
                <div className="color-light-highlight">
                  <span className="icon-small icon-i-maps"></span>
                </div>
                <p className="font-secondary color-text-primary">
                  {orderPickupLocation}
                </p>
              </div>
            )}
            {order.estimatedPrepTime &&
              order.pickupZoneDetails?.type !==
                PickupZoneType.LOCKER_PICKUP && (
                <div className="prep-time">
                  <div className="color-confirmation-icon">
                    <span className="icon-small icon-i-clock"></span>
                  </div>
                  <p className="font-secondary color-confirmation-text">
                    {localization.prepTimeReady}{' '}
                    {getTimeFormat(order.estimatedPrepTime)}
                  </p>
                </div>
              )}
            {webContent.preorderConfirmationPickupInstructions &&
              ((order.orderStatus === OrderStatus.SUBMITTED &&
                order.checkinTime) ||
                (order.orderStatus === OrderStatus.RELEASED &&
                  !order.checkinTime)) && (
                <div className="pickup-instruction">
                  <div className="color-light-highlight">
                    <span className="icon-small icon-i-help"></span>
                  </div>
                  <p className="font-secondary color-text-primary">
                    {webContent.preorderConfirmationPickupInstructions}
                  </p>
                </div>
              )}
          </div>
        </section>
      )}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    activePlaceContent: getActivePlaceContent(state.places),
    localization: state.localization?.orderDetails,
    order: state.order,
    pickupZones: state.orderReadiness?.pickupZones,
  };
};

export default connect(mapStateToProps)(PickupInformation);
