import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Button from '../Button';
import { checkAndConvertToBoolean } from '../../helpers';
import { addIcon, subtractIcon } from '../../helpers/imageAssets';

import './index.scss';

class Counter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isDisableAddButton: false,
    };

    this.addItemQuantity = this.addItemQuantity.bind(this);
    this.subtractItemQuantity = this.subtractItemQuantity.bind(this);
  }

  addItemQuantity() {
    let { quantity, maxQuantity, showToast, updateQuantity, webConfig } =
      this.props;

    if (quantity < maxQuantity) {
      updateQuantity(++quantity);
    } else if (
      !(quantity < maxQuantity) &&
      !checkAndConvertToBoolean(webConfig.grayedButtonMaxItem)
    ) {
      showToast();
    }

    if (
      quantity === maxQuantity &&
      checkAndConvertToBoolean(webConfig.grayedButtonMaxItem)
    ) {
      this.setState({ isDisableAddButton: true });
    }
  }

  subtractItemQuantity() {
    let { quantity } = this.props;
    const { webConfig } = this.props;
    if (quantity > 1) {
      this.props.updateQuantity(--quantity);
    }

    if (checkAndConvertToBoolean(webConfig.grayedButtonMaxItem)) {
      this.setState({ isDisableAddButton: false });
    }
  }

  disableAddButton() {
    const { disabled = false, webConfig } = this.props,
      { isDisableAddButton } = this.state;

    if (
      !disabled &&
      checkAndConvertToBoolean(webConfig.grayedButtonMaxItem) &&
      isDisableAddButton
    ) {
      return true;
    } else if (
      !disabled &&
      checkAndConvertToBoolean(webConfig.grayedButtonMaxItem) &&
      !isDisableAddButton
    ) {
      return false;
    } else {
      return disabled;
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reset) {
      this.setState({ isDisableAddButton: false });
    }
  }

  render() {
    const { quantity, disabled = false, localization } = this.props;

    let classes = classNames(
      'counter-wrapper',
      disabled ? 'disabled color-button-disabled' : '',
    );

    return (
      <div className={classes}>
        <span className="counter-header gray-header uppercase header-secondary-text">
          {localization.popup.selectQuantity}
        </span>
        <div className="counter">
          <Button
            className="subtract"
            onClick={this.subtractItemQuantity}
            disabled={disabled}>
            <img src={subtractIcon} alt="subtract-button" />
          </Button>
          <div className="quantity-wrapper color-text-secondary bg-color-accent">
            <span className="quantity header-text font-primary">
              {quantity}
            </span>
          </div>
          <Button
            className={classNames(
              'add',
              this.disableAddButton() ? 'disabled' : '',
            )}
            onClick={this.addItemQuantity}
            disabled={this.disableAddButton()}>
            <img src={addIcon} alt="add-button" />
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  webConfig: state.webConfig,
  localization: state.localization,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Counter);
