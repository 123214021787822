import { history } from '../history';

export enum PTWRoutes {
  CHANGE_PTW_ROUTE = '/change',
  PTW_UNAVAILABLE_ROUTE = '/unavailable',
}

export const routePTW = (
  routes: PTWRoutes,
  config: string,
  venueId: string,
  orderId: string,
) => {
  const routePrefix = `/config/${config}/venue/${venueId}/order/${orderId}/ptw`;
  history.push(`${routePrefix}${routes}`);
};
