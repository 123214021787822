export const SET_API_ERROR = 'SET_API_ERROR';
export const CLEAR_API_ERROR = 'CLEAR_API_ERROR';

export const setApiError = (apiRequest, errorCode) => ({
  type: SET_API_ERROR,
  payload: { apiRequest, errorCode },
});

export const clearApiError = (apiRequest) => ({
  type: CLEAR_API_ERROR,
  payload: { apiRequest },
});
