import { FC, useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { connect } from 'react-redux';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { LockerOrderStatus, PickupZoneType } from '../../../models/order.model';

import './LockerOrderCodeStatus.scss';

type LockerOrderCodeStatusProps = {
  localization: any;
  wrapperClassName?: string;
};

// change App State here
const mapStateToProps = (state: any) => {
  return {
    localization: state.localization.lockerOrderStatus,
  };
};

type Props = LockerOrderCodeStatusProps;

const LockerOrderCodeStatus: FC<Props> = ({
  localization,
  wrapperClassName,
}) => {
  const { getOrder } = useActions();
  const order = useTypedSelector((state) => state.order);
  const [statusTimer, setStatusTimer] = useState(
    null as unknown as NodeJS.Timer,
  );

  useEffect(() => {
    if (
      order?.pickupZoneDetails?.type === PickupZoneType.LOCKER_PICKUP &&
      !statusTimer
    ) {
      setStatusTimer(
        setInterval(() => {
          if (
            order.pickupZoneDetails?.status !== LockerOrderStatus.DONE &&
            order.pickupZoneDetails?.status !== LockerOrderStatus.CANCELLED &&
            order.pickupZoneDetails?.status !== LockerOrderStatus.ERROR
          )
            getOrder(order.orderId);
        }, 60 * 1000),
      );
    }
  }, [order, statusTimer, getOrder]);

  return (
    <div className={`locker-code-status ${wrapperClassName}`}>
      {order?.pickupZoneDetails?.qrCode &&
        (order.pickupZoneDetails?.status === LockerOrderStatus.LOADED ||
          order.pickupZoneDetails?.status === LockerOrderStatus.EXPIRED) && (
          <QRCode value={order.pickupZoneDetails.qrCode} />
        )}
      {order?.pickupZoneDetails?.code &&
        (order.pickupZoneDetails?.status === LockerOrderStatus.LOADED ||
          order.pickupZoneDetails?.status === LockerOrderStatus.EXPIRED) && (
          <div className={'locker-code font-secondary color-text-primary'}>
            <span>
              {localization.manualLockerCode} {order.pickupZoneDetails.code}
            </span>
          </div>
        )}
      {(!order?.pickupZoneDetails?.code ||
        (order?.pickupZoneDetails?.status !== LockerOrderStatus.LOADED &&
          order?.pickupZoneDetails?.status !== LockerOrderStatus.EXPIRED)) && (
        <>
          <div className={'order-label font-primary color-text-tertiary'}>
            {localization.lockerCode}
          </div>
          <div className="order-number font-secondary color-text-primary locker-placeholder-wrapper">
            <div className="locker-placeholder code-1">
              {localization.lockerCodeDigitPreview}
            </div>
            <div className="locker-placeholder code-2">
              {localization.lockerCodeDigitPreview}
            </div>
            <div className="locker-placeholder code-3">
              {localization.lockerCodeDigitPreview}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default connect(mapStateToProps)(LockerOrderCodeStatus);
