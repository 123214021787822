import * as React from 'react';

const SVGComponent = (props: any) => (
  <svg
    width="14px"
    height="14px"
    fill="currentColor"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}>
    <title>{'close icon'}</title>
    <defs>
      <path
        id="path-1"
        d="m12.9 11.1h7.2c.49706 0 .9.40294.9.9 0 .49706-.40294.9-.9.9h-7.2v7.2c0 .49706-.40294.9-.9.9-.49706 0-.9-.40294-.9-.9v-7.2h-7.2c-.49706 0-.9-.40294-.9-.9 0-.49706.40294-.9.9-.9h7.2v-7.2c0-.49706.40294-.9.9-.9.49706 0 .9.40294.9.9v7.2z"
      />
    </defs>
    <g fill="currentColor">
      <g transform="translate(-343,-59)">
        <g>
          <g>
            <g transform="translate(251,44)">
              <g transform="translate(87,10)">
                <mask id="mask-2" fill="currentColor">
                  <use width="100%" height="100%" xlinkHref="#path-1" />
                </mask>
                <use
                  transform="rotate(45,12,12)"
                  width="100%"
                  height="100%"
                  fill="currentColor"
                  xlinkHref="#path-1"
                />
                <g mask="url(#mask-2)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVGComponent;
