import { getCurrentCategory, getProductsFromCategory } from '../../helpers';
import {
  AFFILIATION,
  ecommerceActionView,
  ecommerceActionCart,
} from '../../helpers/googleAnalytics/ecommerceGoogleAnalytics';
import {
  AnalyticsAction,
  AnalyticsItem,
} from '../../models/googleAnalytics.model';
import { MenuCategory } from '../../models/menuCategory.model';
import { MenuProduct } from '../../models/menuProduct.model';

export const reportViewItemList = (
  params: any,
  activePlace: any,
  products: Array<MenuProduct>,
  categories: Array<MenuCategory>,
) => {
  try {
    const { itemListId, itemListName } = getItemListIdAndName(activePlace);

    const currentProducts = getProductsFromCategory(
      products,
      getCurrentCategory(params, categories),
    );
    const currentCategory = getCurrentCategory(params, categories);
    const items: Array<AnalyticsItem> = [];

    currentProducts.forEach((product, index) => {
      items.push({
        item_id: product?.id,
        item_name: product?.displayName,
        affiliation: AFFILIATION,
        currency: product?.currency,
        index: index,
        item_brand: activePlace?.id,
        item_category: currentCategory?.displayName,
        item_list_id: itemListId,
        item_list_name: itemListName,
        price: product?.price,
      });
    });

    if (itemListId && itemListName && items.length) {
      ecommerceActionView(
        AnalyticsAction.VIEW_ITEM_LIST,
        itemListId,
        itemListName,
        items,
      );
    }
  } catch (error) {}
};

export const reportViewItem = (
  params: any,
  product: MenuProduct,
  index: number,
  activePlace: any,
  categories: Array<MenuCategory>,
) => {
  try {
    if (!categories.length) return;

    const { itemListId, itemListName } = getItemListIdAndName(activePlace);

    const currentCategory = getCurrentCategory(params, categories);
    const items: Array<AnalyticsItem> = [];
    items.push({
      item_id: product?.id,
      item_name: product?.displayName,
      affiliation: AFFILIATION,
      currency: product?.currency,
      index: index,
      item_brand: activePlace?.id,
      item_category: currentCategory?.displayName,
      item_list_id: itemListId,
      item_list_name: itemListName,
      price: product?.price,
    });

    if (items.length) {
      if (product) {
        ecommerceActionCart(
          AnalyticsAction.VIEW_ITEM,
          product.currency,
          product.price,
          items,
        );
      }

      if (itemListId && itemListName) {
        ecommerceActionView(
          AnalyticsAction.SELECT_ITEM,
          itemListId,
          itemListName,
          items,
        );
      }
    }
  } catch (error) {}
};

const getItemListIdAndName = (activePlace: any) => {
  const { activeMenu } = activePlace;
  return {
    itemListId: activeMenu?.id,
    itemListName: activeMenu?.content?.name,
  };
};
