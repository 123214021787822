import {
  SAVE_PLACES,
  SET_ACTIVE_PLACE,
  SAVE_PLACE_CONTENT,
  DISPLAY_ORDERING_HOURS,
  DISPLAY_PICKUP_TIME_WINDOW,
  SET_ESTIMATED_PICKUP_TIME,
  SET_OPENS_AT,
  SET_CLOSES_AT,
} from '../actions/places';
import { CONFIG_RESET } from '../actions/webConfig';

const defaultState = {
  data: [],
  placeContent: {},
  activePlace: null,
};

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case SAVE_PLACES: {
      // Default places to an empty list if the data is missing
      return {
        ...state,
        data: data || [],
      };
    }

    case SET_ACTIVE_PLACE: {
      return {
        ...state,
        activePlace: data,
      };
    }

    case CONFIG_RESET: {
      return {
        ...state,
        activePlace: null,
      };
    }

    case SAVE_PLACE_CONTENT: {
      if (data.id) {
        return {
          ...state,
          placeContent: {
            ...state.placeContent,
            [data.id]: data,
          },
        };
      } else {
        return state;
      }
    }

    case DISPLAY_ORDERING_HOURS: {
      if (data && state.data.find((place) => place.id === data.id)) {
        state.data.find(
          (place) => place.id === data.id,
        ).isDisplayOrderingHours = data.isDisplayOrderingHours;
        return state;
      }
      return state;
    }

    case DISPLAY_PICKUP_TIME_WINDOW: {
      if (data && state.data.find((place) => place.id === data.id)) {
        state.data.find(
          (place) => place.id === data.id,
        ).isDisplayPickupTimeWindow = data.isDisplayPickupTimeWindow;
        return state;
      }
      return state;
    }

    case SET_ESTIMATED_PICKUP_TIME: {
      if (data && state.data.find((place) => place.id === data.id)) {
        state.data.find((place) => place.id === data.id).estimatedPickupTime =
          data.estimatedPickupTime;
        return state;
      }
      return state;
    }

    case SET_OPENS_AT: {
      if (data && state.data.find((place) => place.id === data.id)) {
        state.data.find((place) => place.id === data.id).opensAt = data.opensAt;
        return state;
      }
      return state;
    }

    case SET_CLOSES_AT: {
      if (data && state.data.find((place) => place.id === data.id)) {
        state.data.find((place) => place.id === data.id).closesAt =
          data.closesAt;
        return state;
      }
      return state;
    }

    default:
      return state;
  }
};
