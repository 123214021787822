import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import apiError from './reducers/apiError';
import checkinTimeWindows from './reducers/checkinTimeWindows';
import { getDiscountCodeReducer } from './reducers/discountCode.reducer';
import discounts from './reducers/discounts';
import errorStatus from './reducers/errorStatus';
import features from './reducers/features';
import localization from './reducers/localization';
import location from './reducers/location';
import menu from './reducers/menu';
import order from './reducers/order';
import orderReadiness from './reducers/orderReadiness';
import payment from './reducers/payment';
import paymentKey from './reducers/paymentKey';
import places from './reducers/places';
import popup from './reducers/popup';
import profile from './reducers/profile';
import prompts from './reducers/prompts';
import { queryReducer } from './reducers/query.reducer';
import siriusware from './reducers/siriusware';
import spinner from './reducers/spinner';
import { orderSubmitReducer } from './reducers/submitOrder.reducer';
import toast from './reducers/toast';
import toast2 from './reducers/toast2';
import token from './reducers/token';
import webConfig from './reducers/webConfig';
import { zoneSelectionDetailsReducer } from './reducers/zoneSelectionDetails.reducer';
import { getVenueReducer } from './reducers/getVenue.reducer';
import { ageRestrictionPromptReducer } from './reducers/ageRestrictionPrompt.reducer';

const persistedStateKey = 'storeState';

const reducers = combineReducers({
  query: queryReducer,
  orderSubmit: orderSubmitReducer,
  menu,
  places,
  order,
  popup,
  spinner,
  orderReadiness,
  token,
  profile,
  prompts,
  toast,
  toast2,
  webConfig,
  location,
  localization,
  paymentKey,
  discounts,
  errorStatus,
  apiError,
  payment,
  checkinTimeWindows,
  siriusware,
  features,
  discountCode: getDiscountCodeReducer,
  zoneDetails: zoneSelectionDetailsReducer,
  ageRestrictionPrompt: ageRestrictionPromptReducer,
  venue: getVenueReducer,
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const filterObject = (obj, keys) => {
  return keys.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
};

const localStateKeys = [
  'token',
  'profile',
  'webConfig',
  'siriusware',
  'paymentKey',
  'menu',
  'places',
  'orderReadiness',
  'localization',
  'discounts',
];

const getPersistedStateFromLocalStorage = () => {
  let state = JSON.parse(localStorage.getItem(persistedStateKey)) || {};
  return filterObject(state, localStateKeys);
};

const sessionStateKeys = ['prompts'];

const getPersistedStateFromSessionStorage = () => {
  let state = JSON.parse(sessionStorage.getItem(persistedStateKey)) || {};
  return filterObject(state, sessionStateKeys);
};

const persistedState = {
  ...getPersistedStateFromLocalStorage(),
  ...getPersistedStateFromSessionStorage(),
};
const _store = createStore(
  reducers,
  persistedState,
  composeEnhancers(applyMiddleware(thunk)),
);

_store.subscribe(() => {
  const snapshot = _store.getState();
  const localState = filterObject(snapshot, localStateKeys);
  const sessionState = filterObject(snapshot, sessionStateKeys);

  localStorage.setItem(persistedStateKey, JSON.stringify(localState));
  sessionStorage.setItem(persistedStateKey, JSON.stringify(sessionState));
});

export default _store;
