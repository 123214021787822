export const MENU_NOT_FOUND = 'MENU_NOT_FOUND';
export const SAVE_MENU = 'SAVE_MENU';
export const SET_MENU_STATUS = 'SET_MENU_STATUS';

export const menuNotFound = () => ({
  type: MENU_NOT_FOUND,
});

export const saveMenu = (data) => ({
  type: SAVE_MENU,
  data: data,
});

export const setMenuStatus = (readinessStatus, willBeOpenLaterAt = null) => ({
  type: SET_MENU_STATUS,
  data: { readinessStatus, willBeOpenLaterAt },
});
