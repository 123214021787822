import axios from 'axios';
import { createPromiseId } from '../../../helpers';
import store from '../../index';
import { setMenuStatus } from '../menu';
import { savePlaceContent, savePlaces, setActivePlace } from '../places';
import { popPromise, pushPromise } from '../spinner';

const getMenu = (place) => {
  return place.menus
    ? place.menus.filter((menu) => !!menu.content)[0]
    : place.menus[0];
};

// Compare places by their active menu names
function comparePlaces(a, b) {
  var nameA = a?.activeMenu?.content?.name?.toUpperCase() || '';
  var nameB = b?.activeMenu?.content?.name?.toUpperCase() || '';
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }
  return 0;
}

export const _getPlaceContent = (fullyQualifiedId, callback, errCallback) => {
  const { webConfig, token } = store.getState();

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return axios
      .get(
        `${webConfig.apiUrl}api/v1/public/places/${fullyQualifiedId}?embed=configProfile`,
        {
          headers: {
            Authorization: `Bearer ${token.identity.access}`,
            'x-venue-id': webConfig.venueId,
            'x-place-id': fullyQualifiedId,
          },
        },
      )
      .then((response) => {
        dispatch(savePlaceContent(response.data));
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (errCallback) {
          errCallback(error);
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const _getPlaces = (callback, errCallback) => {
  const state = store.getState();
  const {
    webConfig,
    token,
    places: { activePlace },
  } = state;

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));

    // Clear menu closed status
    dispatch(setMenuStatus(true));

    return axios
      .get(
        `${webConfig.apiUrl}api/v1/public/venues/${webConfig.venueId}/pois?commerceEnabled=true`,
        {
          headers: {
            Authorization: `Bearer ${token.identity.access}`,
            'x-venue-id': webConfig.venueId,
          },
        },
      )
      .then((response) => {
        // Patch place ID with venue
        const places = response.data
          .map((place) => {
            const tags = place?.tags
              ? place.tags.filter((tag) => !tag.action)
              : [];
            const newPlace = {
              ...place,
              tags,
              activeMenu: getMenu(place),
              fullyQualifiedId: `poi:${webConfig.venueId}.${place.id}`,
            };
            return newPlace;
          })
          .filter((place) => place.activeMenu);

        places.sort(comparePlaces);
        dispatch(savePlaces(places));

        // If active place was saved...
        if (activePlace?.fullyQualifiedId) {
          // ...and it still exists for the current environment...
          const updatedPlace = places?.find(
            (p) => p.fullyQualifiedId === activePlace.fullyQualifiedId,
          );
          if (updatedPlace) {
            // ...update it
            dispatch(setActivePlace(updatedPlace));
          } else {
            // ...otherwise, clear it
            dispatch(setActivePlace(null));
          }
        }

        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (errCallback) {
          errCallback(error);
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};
