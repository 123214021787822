import React from 'react';

import './index.scss';

export default (props) => {
  return (
    <div className={`spinner-container ${props.show ? 'show' : 'hide'}`}>
      <div className="spinner border-color-top-accent" />
    </div>
  );
};
