import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  handleDefaultZone,
  handleDefaultZoneUserCustomValue,
} from '../../helpers/defaultZone';
import {
  reportCompletedPayment,
  reportOrderSubmitted,
} from '../../helpers/googleAnalytics';
import { history } from '../../helpers/history';
import {
  _loadAvailableWindows,
  _submitOrder,
} from '../../redux/actions/api/order';
import { getActivePlaceContent } from '../../redux/selectors';
import BillingInfo from '../Billing/billingInfo';
import Button from '../Button';

import {
  checkoutStep,
  GACheckoutStep,
} from '../../helpers/googleAnalytics/checkoutSteps';
import { PTWRoutes, routePTW } from '../../helpers/routePTW/routePTW';
import './stripe.scss';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': { color: '#aab7c4' },
    },
    invalid: { color: '#fa755a', iconColor: '#fa755a' },
  },
};

const PAYMENT_REQUEST_STYLE = {
  paymentRequestButton: {
    type: 'default',
    theme: 'dark', // One of 'dark', 'light', or 'light-outline'
    height: '40px',
  },
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  webConfig: state.webConfig,
  localization: state.localization,
  paymentKeys: state.paymentKey.keys,
  pickupZones: state.orderReadiness.pickupZones,
  checkinTimeWindows: state.checkinTimeWindows,
  order: state.order,
  features: state.features,
  webContent: getActivePlaceContent(state.places).webContent || {},
  placeFeatures: getActivePlaceContent(state.places).features || {},
  placeControls: getActivePlaceContent(state.places).controls || {},
});

const mapDispatchToProps = {
  submitOrder: _submitOrder,
  loadAvailableWindows: _loadAvailableWindows,
};

class StripePayment extends React.Component {
  static propTypes = { params: PropTypes.object.isRequired };

  constructor(props) {
    super(props);

    const {
      profile: { firstName, lastName, email },
      webConfig,
      pickupZones,
    } = props;
    const userName = firstName && lastName ? `${firstName} ${lastName}` : '';

    this.state = {
      userName: userName,
      userEmail: email || '',
      zone: handleDefaultZone({ webConfig, pickupZones }),
      zoneUserCustomValue: handleDefaultZoneUserCustomValue({ webConfig }),
      infoValid: false,
      cardValid: false,
      loading: true,
      requestPaymentAvailable: false,
      errorMsg: {},
    };

    // Function binds for ease of use in the render method
    this.onHandleSubmitOrderClicked =
      this.onHandleSubmitOrderClicked.bind(this);
    this.onCardElementChange = this.onCardElementChange.bind(this);
    this.handlePaymentToken = this.handlePaymentToken.bind(this);
    this.handlePaymentRequestError = this.handlePaymentRequestError.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleValidity = this.handleValidity.bind(this);

    this.billingInfo = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // Only load the stripe payment if the props are set
    if (!prevProps.stripe || !prevProps.elements) {
      const { stripe, elements } = this.props;
      if (stripe && elements) {
        this.loadStripePaymentRequest();
      }
    }
  }

  loadStripePaymentRequest = () => {
    const {
      stripe,
      elements,
      order,
      paymentKeys: { testMode },
    } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const orderTotal = Math.round(order.orderBillTotal * 100);
    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: order.currency ? order.currency.toLocaleLowerCase() : 'usd',
      total: {
        label: testMode ? 'Food order (test mode)' : 'Food order',
        amount: orderTotal,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
    });

    // Let the renderer see the payment request object so that it can supply it to the PaymentRequestButton element
    this.setState({ paymentRequest });
    (async () => {
      // Check the availability of the Payment Request API first.
      const result = await paymentRequest.canMakePayment();
      if (result) {
        // Attach the listener to handle the token once user interaction is finished
        paymentRequest.on(
          'token',
          this.handlePaymentToken,
          this.handlePaymentRequestError,
        );
        this.setState({ loading: false, requestPaymentAvailable: true });
        const paymentRequestElement = elements.create('paymentRequestButton', {
          paymentRequest,
          style: PAYMENT_REQUEST_STYLE,
        });
        paymentRequestElement.on('click', this.handlePaymentButtonClick);
        paymentRequestElement.mount('#payment-request-container');
      } else {
        this.setState({ loading: false, requestPaymentAvailable: false });
      }
    })();
  };

  handlePaymentButtonClick = (event) => {
    const { zone, zoneUserCustomValue } = this.state;
    const zoneValidity = this.billingInfo.current.zoneValidity();
    const zoneCustomValidity = this.billingInfo.current.zoneCustomValidity();

    if (!zoneValidity) this.billingInfo.current.handleChange('zone', zone);
    if (!zoneCustomValidity)
      this.billingInfo.current.handleChange(
        'zoneUserCustomValue',
        zoneUserCustomValue,
      );
    if (!zoneValidity || !zoneCustomValidity) event.preventDefault();
  };

  handlePaymentToken(event) {
    try {
      const { token, payerEmail, payerName } = event;
      const { submitOrder, params, order } = this.props;
      const { zone, zoneUserCustomValue } = this.state;
      const userInfo = {
        userEmail: payerEmail,
        userName: payerName,
        zone,
        zoneUserCustomValue,
      };

      if (!token || !token.id) {
        console.error('Error getting payment token');
        history.push('/error', 'There was an error with the payment.');
      } else {
        reportCompletedPayment();

        const onSubmitComplete = () => {
          event.complete('success');
          reportOrderSubmitted();
          this.navigateToPage('/confirmation');
        };

        const onPTWUnavailable = () => {
          routePTW(
            PTWRoutes.PTW_UNAVAILABLE_ROUTE,
            params.config,
            params.venue,
            order.orderId,
          );
        };

        submitOrder(
          params,
          userInfo,
          token,
          onSubmitComplete,
          () => event.complete('fail'),
          this.getStripeTokenAndSubmit,
          true,
          onPTWUnavailable,
        );
      }
    } catch (error) {
      event.complete('fail');
      this.handlePaymentRequestError(error);
    }
  }

  getStripeTokenAndSubmit = async () => {
    const { stripe, elements, submitOrder, params, order } = this.props;
    if (!params) {
      console.warn("params wasn't provided by the router component");
      return;
    }

    // Get a reference to the billing name and email
    const { userEmail, userName, zone, zoneUserCustomValue } = this.state;
    const userInfo = { userEmail, userName, zone, zoneUserCustomValue };

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);

    if (error) {
      console.log('[error]', error);
      history.push('/error', 'There was an error with the payment.');
    } else {
      checkoutStep(GACheckoutStep.PAYMENT_COMPLETE, token?.card?.brand);
      reportCompletedPayment();

      const redirect = () => {
        reportOrderSubmitted();
        this.navigateToPage('/confirmation');
      };

      const onPTWUnavailable = () => {
        routePTW(
          PTWRoutes.PTW_UNAVAILABLE_ROUTE,
          params.config,
          params.venue,
          order.orderId,
        );
      };

      submitOrder(
        params,
        userInfo,
        token,
        redirect,
        null,
        this.getStripeTokenAndSubmit,
        true,
        onPTWUnavailable,
      );
    }
  };

  async onHandleSubmitOrderClicked(event) {
    event.preventDefault();
    await this.getStripeTokenAndSubmit();
  }

  handlePaymentRequestError(error) {
    console.warn(error);
    this.setState({ errorMsg: error, cardValid: false }, this.validateForm);
  }

  onCardElementChange(event) {
    let errorMsg = { ...this.state.errorMsg };
    if (event.error) {
      errorMsg.card = event.error.message;
    }
    this.setState(
      {
        errorMsg,
        cardValid: event.complete,
      },
      this.validateForm,
    );
  }

  handleChange({ name, value }, callback) {
    this.setState({ [name]: value }, callback);
  }

  handleValidity(infoValid) {
    this.setState({ infoValid });
  }

  isPTWValid = () => {
    const {
      placeFeatures,
      checkinTimeWindows: { selectedCheckinTimeWindow },
    } = this.props;
    return (
      !placeFeatures['pickupTimeWindowsEnabled'] || selectedCheckinTimeWindow
    );
  };

  render() {
    const {
      userName,
      userEmail,
      zone,
      zoneUserCustomValue,
      infoValid,
      cardValid,
    } = this.state;
    const {
      stripe,
      localization,
      order,
      elements,
      pickupZones,
      webContent,
      features,
      loadAvailableWindows,
      checkinTimeWindows,
      placeFeatures,
      placeControls,
    } = this.props;
    const locBilling = localization.billing;
    const orderTotal = `${
      order && order.displayOrderBillTotal ? order.displayOrderBillTotal : ''
    }`;

    const stripeLoaded = stripe && elements;

    return (
      <section className="stripe-form">
        <BillingInfo
          ref={this.billingInfo}
          userName={userName}
          userEmail={userEmail}
          zone={zone}
          pickupZones={pickupZones}
          zoneUserCustomValue={zoneUserCustomValue}
          localization={locBilling}
          placeFeatures={placeFeatures}
          features={features}
          webContent={webContent}
          placeControls={placeControls}
          selectedCheckinTimeWindow={
            checkinTimeWindows.selectedCheckinTimeWindow
          }
          loadAvailableWindows={loadAvailableWindows}
          handleChange={this.handleChange}
          handleValidity={this.handleValidity}>
          {this.renderPaymentField()}
        </BillingInfo>

        <div className="submit-button-wrapper">
          <Button
            onClick={this.onHandleSubmitOrderClicked}
            disabled={
              !stripeLoaded || !cardValid || !infoValid || !this.isPTWValid()
            }>
            <div className="button-content-wrapper">
              <span className="primary-text header-text uppercase">
                {locBilling.submitOrder}
              </span>
              <span className="secondary-text">{orderTotal}</span>
            </div>
          </Button>
        </div>
      </section>
    );
  }

  navigateToPage(url) {
    const {
      params: { config, venue, placeId, menuId },
    } = this.props;
    history.push(
      `/config/${config}/venue/${venue}/place/${placeId}/menu/${menuId}${url}`,
    );
  }

  renderPaymentField() {
    const { localization } = this.props;
    const { loading, requestPaymentAvailable } = this.state;
    const locBilling = localization.billing;

    return (
      <section>
        <h3 className="title">{locBilling.payment}</h3>
        <div className="content">
          {loading ? null : (
            <div
              className={`form-group ${
                !this.state.cardValid && this.state.errorMsg.card
                  ? 'form-error'
                  : ''
              }`}>
              <CardElement
                onChange={this.onCardElementChange}
                options={CARD_ELEMENT_OPTIONS}
              />
              <div className="error-label">{this.state.errorMsg.card}</div>
            </div>
          )}
        </div>
        {requestPaymentAvailable && !loading ? (
          <div className="content">
            <div className="form-group">
              <div id="payment-request-container" />
            </div>
          </div>
        ) : null}
      </section>
    );
  }
}

const StripePaymentComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(StripePayment);

// This allows the elements and stripe pieces to get to the component class
const InjectedCheckoutForm = () => {
  const params = useParams();
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <StripePaymentComponent
          elements={elements}
          stripe={stripe}
          params={params}
        />
      )}
    </ElementsConsumer>
  );
};

export default InjectedCheckoutForm;
