export const CONFIG_ON_CHANGE = 'CONFIG_ON_CHANGE';
export const CONFIG_RESET = 'CONFIG_RESET';

export const onChangeConfig = (data) => {
  return {
    type: CONFIG_ON_CHANGE,
    payload: data,
  };
};

export const resetConfig = () => {
  return {
    type: CONFIG_RESET,
  };
};
