import { SET_FEATURES } from '../actions/features';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_FEATURES:
      return { ...action.payload };
    default:
      return state;
  }
};
