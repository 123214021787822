import { find } from 'lodash/collection';
import React from 'react';
import { connect } from 'react-redux';

import './index.scss';

class MenuItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageError: false,
    };

    this.onClickHandler = this.onClickHandler.bind(this);
    this.onImageError = this.onImageError.bind(this);
  }

  onClickHandler() {
    const { item, menu, onClick, index } = this.props;

    let product = find(menu.products, (product) => product.id === item.id);

    onClick(product, item, index);
  }

  onImageError() {
    this.setState({
      imageError: true,
    });
  }

  render() {
    const { item, webConfig } = this.props;

    if (!item) {
      return null;
    }

    const imageToShow = item?.images[0] ?? null;
    const hasCrop = imageToShow?.crop;
    const hideImage = this.state.imageError || !imageToShow;

    return (
      <li onClick={this.onClickHandler} key={`product-${item.id}`}>
        <div className="product-info-wrapper color-text-primary bg-color-card">
          <div className="product-info">
            <p className="product-name header-text">{item.displayName}</p>
            {item.content.description && (
              <p className="product-desc font-secondary">
                {item.content.description}
              </p>
            )}
            <p className="product-price font-secondary secondary-text gray-secondary-text">
              {item.displayPrice}
            </p>
          </div>
          <div className={`product-image ${hideImage ? 'hide' : ''}`}>
            {imageToShow && (
              <img
                alt="product"
                src={`https://${webConfig.nodeEnv}-img.te2.io/unsafe/${
                  hasCrop ? imageToShow.crop + '/' : ''
                }100x100/${imageToShow.src}`}
                onError={this.onImageError}
              />
            )}
          </div>
        </div>
      </li>
    );
  }
}

const mapStateToProps = (state) => ({
  menu: state.menu,
  webConfig: state.webConfig,
});

export default connect(mapStateToProps)(MenuItem);
