import React from 'react';
import { connect } from 'react-redux';

import AuthPrompter from '../authPrompter';
import Place from './place';

import { reportPageView } from '../../helpers/googleAnalytics';
import { filterPlacesByOrderingHours } from '../../helpers/orderingHours';
import { setupWebGateway } from '../../helpers/webgateway';
import actions from '../../redux/actions';

import { history } from '../../helpers/history';
import './placeList.scss';

class PlacesList extends React.Component {
  componentDidMount() {
    // Hack around sometimes ending up at root due to error or reloading page.
    const { match } = this.props;
    const { config, venue } = match.params;
    history.replace(`/config/${config}/venue/${venue}/places`);

    const { setActivePlace, setMenuStatus } = this.props;
    setActivePlace(null);
    setMenuStatus(true);
    reportPageView('/places');
  }

  render() {
    const {
      places,
      uiGateway,
      match,
      displayOrderingHours,
      displayPickupTimeWindow,
      setEstimatedPickupTime,
      setOpensAt,
      setClosesAt,
      webConfig,
    } = this.props;
    const openPlaces = filterPlacesByOrderingHours(places, true, {
      displayOrderingHours: (data) => displayOrderingHours(data),
      displayPickupTimeWindow: (data) => displayPickupTimeWindow(data),
      setEstimatedPickupTime: (data) => setEstimatedPickupTime(data),
      setOpensAt: (data) => setOpensAt(data),
      setClosesAt: (data) => setClosesAt(data),
    });
    const { hideUnavailableLocationsForVenues, venueId } = webConfig;
    let closePlaces =
      hideUnavailableLocationsForVenues &&
      hideUnavailableLocationsForVenues.length > 0 &&
      hideUnavailableLocationsForVenues.includes(venueId)
        ? []
        : filterPlacesByOrderingHours(places, false, {
            displayOrderingHours: displayOrderingHours,
            displayPickupTimeWindow: displayPickupTimeWindow,
            setEstimatedPickupTime: setEstimatedPickupTime,
            setOpensAt: setOpensAt,
            setClosesAt: setClosesAt,
          });

    return (
      <AuthPrompter uiGateway={uiGateway} match={match}>
        <div className="places-list-container">
          <div className="places-list">
            {openPlaces.map((place, index) => (
              <Place key={index} place={place} match={match} />
            ))}
            {(closePlaces.length > 0 || places.length === 0) && (
              <div className="select-menu color-text-tertiary">
                {this.props.placeLocalization.unavailableLocation}
              </div>
            )}
            {closePlaces.map((place, index) => (
              <Place key={index} place={place} match={match} />
            ))}
          </div>
        </div>
      </AuthPrompter>
    );
  }
}

const mapStateToProps = (state) => ({
  places: state.places.data,
  uiGateway: setupWebGateway(state.webConfig),
  placeLocalization: state.localization.place,
  webConfig: state.webConfig,
});

const mapDispatchToProps = (dispatch) => ({
  setActivePlace: (data) => {
    dispatch(actions.setActivePlace(data));
  },
  setMenuStatus: (data) => {
    dispatch(actions.setMenuStatus(data));
  },
  displayOrderingHours: (data) => {
    dispatch(actions.displayOrderingHours(data));
  },
  displayPickupTimeWindow: (data) => {
    dispatch(actions.displayPickupTimeWindow(data));
  },
  setEstimatedPickupTime: (data) => {
    dispatch(actions.setEstimatedPickupTime(data));
  },
  setOpensAt: (data) => {
    dispatch(actions.setOpensAt(data));
  },
  setClosesAt: (data) => {
    dispatch(actions.setClosesAt(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlacesList);
