import Bugsnag from '@bugsnag/js';
import axios from 'axios';
import { createPromiseId, redirectToErrorPage } from '../../../helpers';
import { offlineError } from '../../../helpers/errorMessages';
import { history } from '../../../helpers/history';
import store from '../../index';
import { saveOrderData } from '../order';
import { popPromise, pushPromise } from '../spinner';

const errorMessages = {
  400: 'Bad Request',
  401: 'Unauthorized',
  403: 'Forbidden',
  404: 'Not Found',
  500: 'Server Error',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Time Out',
};

const tagRequest = ({
  url,
  method = 'get',
  headers = {},
  data = undefined,
  testErrorCode = null,
}) => {
  const state = store.getState();
  const {
    webConfig: { apiUrl, customerId, nodeEnv },
  } = state;

  const baseAxiosOrderRequestConfig = {
    method,
    url: `${apiUrl}${url}`,
    data,
    headers: {
      Authorization: `Bearer ${state.token.identity.access}`,
      'X-Customer-ID': customerId,
      ...headers,
    },
  };
  const errorMessage = errorMessages[testErrorCode];

  //The errorCodes only can be tested in QA environment
  return testErrorCode && nodeEnv === 'qa' && errorMessage
    ? errorRequest(errorMessage, testErrorCode)
    : axios(baseAxiosOrderRequestConfig);
};

const errorRequest = (message, errorCode) => {
  return new Promise((resolve, reject) => {
    reject({ response: { status: errorCode }, message });
  });
};

/*
 *  This call can be used to update customer info if necessary (ex. customerName, customerPhone, etc.)
 *  For now, this only updates if the user wants to opt in to SMS notifications
 */

export const _setNotificationTags = (
  phoneNumber,
  savePhoneNumber,
  onSuccess,
  onError,
) => {
  const tagsPayload = [
    {
      tagKey: `F&B-SMS-User`,
      tagValue: true,
    },
    {
      tagKey: `phoneNumber`,
      tagValue: phoneNumber,
    },
    {
      tagKey: `F&B-SMS-Ready`,
      tagValue: savePhoneNumber,
    },
  ];

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return tagRequest({
      url: `v1/tags/users/me/tags`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: tagsPayload,
    })
      .then((response) => {
        dispatch(saveOrderData(response.data));

        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.error(error);
        const { message, response } = error;
        Bugsnag.notify(error, (event) => {
          event.addMetadata('apiError', {
            status: response.status,
            message: response?.data?.message,
          });
        });

        if (onError) {
          onError();
        }

        if (message === 'Network Error') {
          redirectToErrorPage(history, offlineError);
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

// This is separated as the values here should be multi-select
export const _setVenueNotificationTags = (onSuccess, onError) => {
  const state = store.getState();
  const {
    webConfig: { venueId },
  } = state;

  const venueTagsPayload = [
    {
      tagKey: `F&B-SMS-User-Venue`,
      tagValue: venueId,
    },
  ];

  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return tagRequest({
      url: `v1/tags/users/me/tags?addMultiSelectValue=true`,
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      data: venueTagsPayload,
    })
      .then((response) => {
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch((error) => {
        console.error(error);
        const { message, response } = error;
        Bugsnag.notify(error, (event) => {
          event.addMetadata('apiError', {
            status: response.status,
            message: response?.data?.message,
          });
        });

        if (onError) {
          onError();
        }

        if (message === 'Network Error') {
          redirectToErrorPage(history, offlineError);
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};

export const _getUserTags = (onSuccess, onError) => {
  let promiseId = createPromiseId();
  return (dispatch) => {
    dispatch(pushPromise(promiseId));
    return tagRequest({
      url: `v1/screen-flows/flows/fb-sms-notification/users/me`,
      method: 'get',
    })
      .then((response) => {
        if (onSuccess) {
          const { screens } = response.data;

          if (screens && screens.length > 0) {
            onSuccess(screens[0].tags);
          }
        }
      })
      .catch((error) => {
        console.error(error);
        const { message, response } = error;
        Bugsnag.notify(error, (event) => {
          event.addMetadata('apiError', {
            status: response.status,
            message: response?.data?.message,
          });
        });

        if (onError) {
          onError();
        }

        if (message === 'Network Error') {
          redirectToErrorPage(history, offlineError);
        }
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};
