import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import {
  LockerOrderStatus,
  Order,
  OrderStatus,
  PickupZoneType,
} from '../../models/order.model';
import { setActivePlace } from '../../redux/actions/places';

import FastFoodIcon from '../../assets/icons/FastFoodIcon';
import LockersIcon from '../../assets/icons/LockersIcon';
import CustomerSupportInfo from '../../components/CustomerSupportInfo/CustomerSupportInfo';
import OrderButton from '../../components/OrderButton/OrderButton';
import SignUpCard from '../../components/SignUpCard/SignUpCard';
import { shouldPromptForSignUp } from '../../helpers/authPrompt/authPrompt.helper';
import LockerOrderCodeStatus from './LockerOrderCodeStatus/LockerOrderCodeStatus';
import OrderCheckinStatus from './OrderCheckinStatus/OrderCheckinStatus';
import './OrderDetails.scss';
import PickupInformation from './PickupInformation/PickupInformation';

type OrderDetailsProps = {
  localization: any;
  places: any;
  shouldShowSignUpPrompt: boolean;
};

type DispatchProps = {
  setActivePlace: typeof setActivePlace;
};

type Props = OrderDetailsProps & DispatchProps;

// change App State here
const mapStateToProps = (state: any) => {
  return {
    localization: state.localization.orderDetails,
    shouldShowSignUpPrompt: shouldPromptForSignUp(state.webConfig, state.token),
    places: state.places,
  };
};

const mapDispatchToProps = {
  setActivePlace,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const OrderDetails: FC<Props> = ({
  localization,
  places,
  shouldShowSignUpPrompt,
  setActivePlace,
}) => {
  const { getOrder, resetDiscountData } = useActions();
  const history = useHistory();
  const { orderId }: { orderId: string } = useParams();
  const order = useTypedSelector((state) => state.order) as Order & {
    loading: boolean;
    error: string;
  };
  const activePlace = useTypedSelector((state) => state.places.activePlace);
  const query = useTypedSelector((state) => state.query);
  const activePlaceContent = (
    useTypedSelector((state) => state.places.placeContent) as any[]
  )[activePlace?.fullyQualifiedId];
  const [userTimer, setUserTimer] = useState(null as unknown as NodeJS.Timer);
  const [userTime, setUserTime] = useState(new Date());

  useEffect(() => {
    if (order?.expireTime && !userTimer) {
      setUserTimer(
        setInterval(() => {
          setUserTime(new Date());
        }, 30 * 1000),
      );
    }

    return () => {
      if (userTimer) {
        clearInterval(userTimer);
      }
    };
  }, [order, userTimer]);

  useEffect(() => {
    if (!order?.orderId && !order?.loading && !order?.error) {
      getOrder(orderId);
    }
  }, [getOrder, order, orderId]);

  useEffect(() => {
    if (order?.placeId) {
      const place = places.data.filter(
        (p: any) => p.fullyQualifiedId === order.placeId,
      );
      if (place.length > 0) {
        setActivePlace(place[0]);
      }
    }
  }, [order?.placeId, places.data, setActivePlace]);

  useEffect(() => {
    resetDiscountData();
  }, [resetDiscountData]);

  const handleNewOrder = () => {
    const { configFile } = query.params;
    const placeId = order.placeId;

    history.push(
      `/config/${configFile}/venue/${activePlace.fullyQualifiedId}/place/${placeId}/menu/${activePlace.activeMenu.id}`,
    );
  };
  return (
    <div className={'order-details-wrapper'}>
      {order?.orderId && (
        <div className={'content order-number-wrapper'}>
          {order?.pickupZoneDetails?.type !== PickupZoneType.LOCKER_PICKUP && (
            <FastFoodIcon colorClass="color-checkmark" />
          )}
          {order?.pickupZoneDetails?.type === PickupZoneType.LOCKER_PICKUP && (
            <LockersIcon colorClass="color-checkmark" />
          )}
          <div className="order-number-sub-header">
            <div className="sub-header-order-info">
              <div className={'order-label font-primary color-text-tertiary'}>
                {localization.orderNumber}
              </div>
              <div className={'order-number font-secondary color-text-primary'}>
                {order.externalOrderId}
              </div>
            </div>
            {localization.thankYou &&
              ((order.orderStatus === OrderStatus.SUBMITTED &&
                order.pickupWindow &&
                order.expireTime &&
                new Date(order.expireTime) > userTime) ||
                !order.pickupWindow ||
                order.orderStatus === OrderStatus.RELEASED) && (
                <span
                  className={'order-text font-secondary color-text-primary'}>
                  {(order.orderStatus === OrderStatus.SUBMITTED ||
                    order.pickupZoneDetails?.type !==
                      PickupZoneType.LOCKER_PICKUP) && (
                    <>
                      {activePlaceContent?.webContent
                        ?.preorderConfirmationMessage ?? localization.thankYou}
                    </>
                  )}
                  {order.pickupZoneDetails?.type ===
                    PickupZoneType.LOCKER_PICKUP &&
                    order.orderStatus === OrderStatus.RELEASED &&
                    order.pickupZoneDetails?.status !==
                      LockerOrderStatus.REMOVED && (
                      <>
                        {activePlaceContent?.webContent
                          ?.lockerFulfillmentNotificationInfo ??
                          localization.thankYou}
                      </>
                    )}
                  {order.pickupZoneDetails?.type ===
                    PickupZoneType.LOCKER_PICKUP &&
                    order.orderStatus === OrderStatus.RELEASED &&
                    order.pickupZoneDetails?.status ===
                      LockerOrderStatus.REMOVED && (
                      <>{localization.lockerPickupRemovedInstructions}</>
                    )}
                </span>
              )}
            {order?.pickupZoneDetails?.type === PickupZoneType.LOCKER_PICKUP &&
              order.orderStatus === OrderStatus.RELEASED &&
              order?.pickupZoneDetails?.status !== LockerOrderStatus.DONE &&
              order?.pickupZoneDetails?.status !==
                LockerOrderStatus.REMOVED && (
                <LockerOrderCodeStatus wrapperClassName="sub-header-order-info" />
              )}
          </div>
          {(activePlaceContent?.webContent ||
            localization.postCheckinMessage) &&
            (order.orderStatus === OrderStatus.SUBMITTED ||
              order.orderStatus === OrderStatus.EXPIRED) &&
            order.pickupWindow &&
            order.expireTime &&
            new Date(order.expireTime) < userTime && (
              <span className={'order-text font-secondary color-text-primary'}>
                {activePlaceContent?.webContent?.postCheckinMessage ??
                  localization.postCheckinMessage}
              </span>
            )}
        </div>
      )}
      {order?.orderId &&
        (!order.pickupWindow ||
          (order.orderStatus === OrderStatus.SUBMITTED &&
            order.expireTime &&
            new Date(order.expireTime) > userTime) ||
          order.orderStatus === OrderStatus.RELEASED) && (
          <div className={'order-next-steps'}>
            <h3 className={'title'}>
              {order.orderStatus === OrderStatus.SUBMITTED &&
                localization.nextSteps}
              {order.orderStatus === OrderStatus.RELEASED &&
                localization.orderPickupSummary}
            </h3>
            <div className={'content order-steps'}>
              {order.checkinTime && <OrderCheckinStatus />}
              {order.orderId.length && activePlace && <PickupInformation />}
              {activePlaceContent?.content && (
                <CustomerSupportInfo
                  phoneNumber={activePlaceContent.content.phoneNumber}
                />
              )}
            </div>
            {shouldShowSignUpPrompt && <SignUpCard />}
          </div>
        )}
      {order?.orderId &&
        ((order.orderStatus === OrderStatus.SUBMITTED &&
          order.pickupWindow &&
          order.expireTime &&
          new Date(order.expireTime) < userTime) ||
          order.orderStatus === OrderStatus.EXPIRED) && (
          <div className={'order-expired-next-steps'}>
            <div className="new-order-button-wrapper">
              <OrderButton
                type={
                  activePlaceContent?.webContent?.postCheckinMessageButton
                    ? 'OTHER'
                    : 'NEW'
                }
                onClick={handleNewOrder}
                buttonText={
                  activePlaceContent?.webContent?.postCheckinMessageButton ??
                  null
                }
              />
            </div>
          </div>
        )}
    </div>
  );
};

export default connector(OrderDetails);
