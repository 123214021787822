import classNames from 'classnames';
import { findIndex } from 'lodash/array';
import { has } from 'lodash/object';
import moment from 'moment';
import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';

import Counter from '../Counter';
import ModifierGroups from '../ModifierGroups';
import PageButton from '../PageButton';
import Spinner from '../Spinner';

import actions from '../../redux/actions';
import api from '../../redux/actions/api';
import { _getMenu } from '../../redux/actions/api/menu';
import {
  _createOrder,
  _getOrderReadiness,
  _updateOrder,
} from '../../redux/actions/api/order';
import {
  addToOrder,
  removeProductFromOrder,
  setOrderPlace,
  updateOrder,
} from '../../redux/actions/order';
import { setAgeRestrictionPrompt } from '../../redux/action-creators/ageRestrictionPrompt.action-creator';
import { closePopUp, updateItem } from '../../redux/actions/popup';
import { closeToast2, openToast2 } from '../../redux/actions/toast2';
import {
  getActivePlaceContent,
  shouldRequestRefreshedLocation,
  shouldShowOutOfVenueMessage,
} from '../../redux/selectors';

import {
  determineOrderingWindows,
  ORDER_OPERATION_CREATE,
} from '../../helpers';
import { reportEditCart } from '../../helpers/googleAnalytics';
import { history } from '../../helpers/history';
import { reportAddToCart } from './helper';

import './index.scss';
import ageRestrictionPrompt from '../Cart/ageRestrictionPrompt';
const PDFPopup = lazy(() => import('../PDFPopup/PDFPopup'));

class PopUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showNutritionPopup: false,
      imageLoaded: false,
      imageError: false,
      quantity: 0,
      item: { modifiers: [] },
      newOrderOnDiffLoc: false,
    };

    this.addToCart = this.addToCart.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.imageError = this.imageError.bind(this);
    this.toggleNutritionPopup = this.toggleNutritionPopup.bind(this);
    this.imageLoaded = this.imageLoaded.bind(this);
    this.showToast = this.showToast.bind(this);
    this.updateModifiers = this.updateModifiers.bind(this);
    this.updateQuantity = this.updateQuantity.bind(this);
  }

  componentDidMount() {
    this.setState({
      imageLoaded: false,
      imageError: false,
    });
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextState.newOrderOnDiffLoc === true) {
      // There is no way to set this to false since this component is rendered in App.js
      this.setState({ newOrderOnDiffLoc: false });
    }
    return true;
  }

  closePopUp() {
    this.setState({
      item: { modifiers: [] },
      imageError: false,
      quantity: 0,
    });

    this.props.closePopUp();
  }

  toggleNutritionPopup() {
    this.setState({
      showNutritionPopup: !this.state.showNutritionPopup,
    });
  }

  imageError(err) {
    this.setState({
      imageLoaded: true,
      imageError: true,
    });
  }

  imageLoaded() {
    this.setState({
      imageLoaded: true,
      imageError: false,
    });
  }

  createLineItemId(item) {
    return btoa(`${new Date().getTime()}:${item.id}`);
  }

  addToCart() {
    const {
      addToOrder,
      openToast2,
      location,
      popup: { isEditing, item },
      updateCart,
      updateOrder,
      setOrderPlace,
      activePlace,
      webConfig,
      willBeOpenLaterAt,
      localization,
      shouldRequestRefreshedLocation,
      shouldPreventAddToCart,
      readinessStatus,
      menu: { products, categories },
      match: { params },
      setAgeRestrictionPrompt,
    } = this.props;

    let cartItem = {
      lineItemId: this.createLineItemId(item),
      productId: item.id,
      quantity: item.quantity,
      modifiers: item.modifiers,
    };

    if (
      !this.props.location.asked &&
      shouldShowOutOfVenueMessage(webConfig, location, activePlace?.features)
    ) {
      handleLocation(this.props);
      // dont run through the full popup flow if we have never asked for location before
      return;
    }

    const now = moment();
    const isInOrderWindowCreate = determineOrderingWindows(
      now,
      this.props.orderReadiness,
      ORDER_OPERATION_CREATE,
      'MENU',
    );

    const {
      popup: {
        mustAllowLocationBody,
        mustAllowLocationTitle,
        weAreClosedTitle,
        mustBeOnsiteTitle,
        mustBeOnsiteBody,
        maxItemInCartTitle,
        maxItemInCartBody,
        maxItemTitle,
        maxItemBody,
        maxLineItemInCartTitle,
        maxLineItemInCartBody,
        closedEntireDay,
        opensLaterInTheCurrentDay,
      },
    } = localization;

    if (!readinessStatus || !isInOrderWindowCreate.status) {
      const customBanner =
        willBeOpenLaterAt !== null
          ? opensLaterInTheCurrentDay.replace('%hour%', willBeOpenLaterAt)
          : closedEntireDay;
      openToast2({
        title: weAreClosedTitle,
        message: customBanner,
      });
    } else if (shouldRequestRefreshedLocation) {
      handleLocation(this.props);
      openToast2({
        title: mustAllowLocationTitle,
        message: mustAllowLocationBody,
      });
    } else if (shouldPreventAddToCart) {
      openToast2({
        title: mustBeOnsiteTitle,
        message: mustBeOnsiteBody,
      });
    } else if (this.isMaxItemInCart(item.quantity)) {
      openToast2({
        title: maxItemInCartTitle,
        message: maxItemInCartBody.replace(
          '%maxItemInCart%',
          webConfig.maxItemInCart,
        ),
      });
    } else if (this.isMaxItemQuantity(item)) {
      openToast2({
        title: maxItemTitle.replace('%itemName%', item.displayName),
        message: maxItemBody,
      });
    } else if (this.isMaxLineItemsInCart(item)) {
      openToast2({
        title: maxLineItemInCartTitle,
        message: maxLineItemInCartBody,
      });
    } else if (this.askClearOrderWhenAddItemOnDiffLoc()) {
      this.openModalWhenAddingItemOnDiffLoc();
    } else {
      if (isEditing) {
        reportEditCart(item.id, item.quantity);
        updateCart(item);
        updateOrder();
      } else {
        addToOrder(cartItem);
        reportAddToCart(params, item, activePlace, products, categories);
      }
      if (
        item.hasAgeRestriction &&
        !ageRestrictionPrompt.isAgeRestrictionAccepted
      ) {
        setAgeRestrictionPrompt(true);
      }
      // Set as order place after adding to cart.
      setOrderPlace(activePlace.fullyQualifiedId);
      this.closePopUp();
    }
  }

  updateQuantity(quantity) {
    this.props.updateItem({
      quantity,
    });
  }

  updateModifiers(modifiers) {
    this.props.updateItem({
      modifiers,
    });
  }

  isMaxItemInCart(quantity) {
    const { order, popup, webConfig } = this.props;

    if (popup.isEditing) {
      const index = findIndex(order.products, (product) => {
        return product.lineItemId === popup.item.lineItemId;
      });

      if (order.products[index].quantity > popup.quantity) {
        return (
          order.products[index].quantity - popup.quantity >
          webConfig.maxItemInCart
        );
      }

      return (
        popup.item.quantity -
          order.products[index].quantity +
          order.totalQuantity >
        webConfig.maxItemInCart
      );
    }

    return quantity + order.totalQuantity > webConfig.maxItemInCart;
  }

  isMaxItemQuantity(item) {
    const { order } = this.props;
    const index = findIndex(
      order.products,
      (product) => product.productId === item.id,
    );

    if (index >= 0) {
      return order.products[index].quantity + item.quantity > item.maxQuantity;
    }

    return false;
  }

  isMaxLineItemsInCart(item) {
    const { order, webConfig } = this.props;

    const isExistingLineItem =
      findIndex(
        order.products || [],
        (product) => product.lineItemId === item.lineItemId,
      ) > -1;
    const maxCartLineItems = webConfig.maxLineItemsInCart || -1;
    if (isExistingLineItem || maxCartLineItems < 1) {
      return false;
    }

    return order.products && order.products.length >= maxCartLineItems;
  }

  askClearOrderWhenAddItemOnDiffLoc() {
    const { prevPlaceId, activePlace } = this.props;
    return (
      prevPlaceId &&
      prevPlaceId !== activePlace.fullyQualifiedId &&
      !this.state.newOrderOnDiffLoc
    );
  }

  openModalWhenAddingItemOnDiffLoc() {
    const {
      openToast2,
      closeToast2,
      setOrderPlace,
      prevPlaceId,
      places,
      cancelOrder,
      createOrder,
      match,
      closePopUp,
      getPaymentKeyFor,
      setActivePlace,
      localization,
      getMenu,
      getOrderReadiness,
    } = this.props;

    const {
      orderManagement: {
        clearOrderTitle,
        clearOrderBody,
        newOrderLabel,
        checkoutLabel,
      },
    } = localization;

    openToast2({
      title: clearOrderTitle,
      message: clearOrderBody,
      buttons: [
        {
          label: newOrderLabel,
          action: () => {
            this.setState({ newOrderOnDiffLoc: true }, () => {
              cancelOrder(this.addToCart);
              closeToast2();
            });
          },
        },
        {
          label: checkoutLabel,
          action: () => {
            closeToast2();
            closePopUp();

            const place = places.data.find(
              (o) => o.fullyQualifiedId === prevPlaceId,
            );
            setActivePlace(place);
            getPaymentKeyFor(place.fullyQualifiedId);
            setOrderPlace(place.fullyQualifiedId);
            getMenu(
              place.fullyQualifiedId,
              place.activeMenu.id,
              () => {},
              () => {},
            );
            getOrderReadiness(
              { matchParams: { placeId: place.fullyQualifiedId } },
              () => {},
              () => {},
            );

            createOrder(match.params, () => this.navigateToPage('/order'));
          },
        },
      ],
    });
  }

  navigateToPage(url) {
    const {
      match: {
        params: { config, venue, placeId, menuId },
      },
    } = this.props;

    const menuPath = `/config/${config}/venue/${venue}/place/${placeId}/menu/${menuId}${url}`;

    history.push(menuPath);
  }

  disableButton(item) {
    const { product } = this.props.popup;
    const limitedGroups = {};
    (item.modifierGroups || product.modifierGroups || []).forEach((group) => {
      if (!!group.min || group.max !== null) {
        limitedGroups[group.id] = {
          ...group,
          count: 0,
        };
      }
    });

    // modifierId : modifierGroupId
    const groupMembership = {};
    for (let id in limitedGroups) {
      const group = limitedGroups[id];
      const modifiers = group.modifiers || [];
      modifiers.forEach((mod) => {
        groupMembership[mod.id] = group.id;
      });
    }
    (item.modifiers || []).forEach((mod) => {
      const group = groupMembership[mod.modifierId];
      if (group) {
        limitedGroups[group].count += 1;
      }
    });

    for (let id in limitedGroups) {
      const group = limitedGroups[id];
      const { min, max, count = 0 } = group;
      if (count < min) {
        return true;
      }
      if (max !== null && max < count) {
        return true;
      }
    }

    return false;
  }

  showToast() {
    const {
      popup: { item },
      openToast2,
      webConfig,
      localization,
    } = this.props;

    const {
      popup: { maxItemTitle, maxItemBody },
    } = localization;

    openToast2({
      title: maxItemTitle.replace('%itemName%', item.displayName),
      message: maxItemBody
        .replace('%itemName%', item.displayName)
        .replace('%maxItem%', webConfig.maxItem),
    });
  }

  render() {
    const { imageLoaded, imageError, showNutritionPopup } = this.state;
    const { popup, webConfig, localization, menu } = this.props;
    const { item, product } = popup;
    const nutritionalInfoLink =
      menu?.links?.length &&
      menu.links.some((link) => link.type === 'NUTRITION_INFO')
        ? menu.links.filter((link) => link.type === 'NUTRITION_INFO')[0].ref
        : null;

    const displayClass = popup.isOpen ? 'show' : 'hide';
    const hasImage = has(product, 'images[1].src');
    const imageOK = !imageError && hasImage;

    // Comment out this for now. Might need it later on
    let crop = hasImage && product.images[1].crop;
    let screenWidth = window && window.screen.width;
    let screenHeight = window && Math.floor(window.screen.height * 0.3);

    const closeButtonWrapperClass = classNames(
      'close-button-wrapper',
      imageOK && 'shadow-bg',
    );
    const closeIconClass = classNames(
      'icon-i-close-x',
      'close-icon',
      imageOK ? 'color-text-secondary' : 'color-accent',
    );
    const imageWrapperClass = classNames('image-wrapper', !imageOK && 'hide');

    return (
      <div className={`popup-wrapper bg-color-card ${displayClass}`}>
        <div className="popup-content-wrapper">
          <div className={closeButtonWrapperClass}>
            <span
              className={closeIconClass}
              alt={'ic-close'}
              onClick={this.closePopUp}></span>
          </div>
          <div className={imageWrapperClass}>
            {!imageLoaded && popup.isOpen && <Spinner show={'show'} />}
            {!imageError && popup.isOpen && (
              <img
                src={
                  hasImage
                    ? `https://${webConfig.nodeEnv}-img.te2.io/unsafe/${
                        crop ? crop + '/' : ''
                      }fit-in/${screenWidth}x${screenHeight}${
                        product.images[1].src
                      }`
                    : ''
                }
                alt="product"
                onError={this.imageError}
                onLoad={this.imageLoaded}
                className="spinner"
              />
            )}
          </div>
          <div className="details-wrapper">
            <div className="item-name-wrapper header-text uppercase">
              <p className="item-name">{product.displayName}</p>
            </div>
            {product.content && product.content.description && (
              <div className="item-content-wrapper">
                <p className="item-content">{product.content.description}</p>
              </div>
            )}
            <div className="price-wrapper header-text">
              <p className="item-price">{product.displayPrice}</p>
              {localization?.popup?.nutritionInfoTitle && nutritionalInfoLink && (
                <>
                  <div
                    className="nutritional-link-wrapper"
                    onClick={() => this.toggleNutritionPopup()}>
                    <span>{localization.popup.nutritionInfoTitle}</span>
                  </div>
                  {showNutritionPopup && (
                    <Suspense fallback={<div>Loading...</div>}>
                      <PDFPopup
                        title={localization.popup.nutritionInfoTitle}
                        onClose={this.toggleNutritionPopup}
                        pdfSource={nutritionalInfoLink}
                      />
                    </Suspense>
                  )}
                </>
              )}
            </div>
            {product.hasAgeRestriction && product.ageRestrictionLabel && (
              <div className="item-content-wrapper item-age-restriction-label">
                <p className="item-content">{product.ageRestrictionLabel}</p>
              </div>
            )}
            <ModifierGroups
              localization={localization.modifierGroups}
              item={item}
              modifierGroups={product.modifierGroups}
              updateModifiers={this.updateModifiers}
            />
            <Counter
              maxQuantity={product.maxQuantity}
              quantity={item.quantity}
              reset={!popup.isOpen}
              updateQuantity={this.updateQuantity}
              showToast={this.showToast}
              disabled={false}
            />
          </div>
        </div>
        <PageButton
          className={`fixed-bottom header-text uppercase ${displayClass}`}
          onClick={this.addToCart}
          disabled={this.disableButton(item)}
        />
      </div>
    );
  }
}

function shouldPreventAddToCart(state, activePlace) {
  return (
    shouldShowOutOfVenueMessage(
      state.webConfig,
      state.location,
      activePlace.features,
    ) || !state.menu.readinessStatus
  );
}

function handleLocation(props) {
  const { getLocation, checkIfCurrentLocationIsInVenue } = props;
  getLocation(() => checkIfCurrentLocationIsInVenue());
}

const mapStateToProps = (state) => {
  const activePlace = getActivePlaceContent(state.places);
  return {
    order: state.order,
    prevPlaceId: state.order.placeId,
    activePlace: activePlace,
    places: state.places,
    popup: state.popup,
    location: state.location,
    readinessStatus: state.menu.readinessStatus,
    willBeOpenLaterAt: state.menu.willBeOpenLaterAt,
    orderReadiness: state.orderReadiness,
    ageRestrictionPrompt: state.ageRestrictionPrompt,
    webConfig: state.webConfig,
    localization: state.localization,
    shouldPreventAddToCart: shouldPreventAddToCart(state, activePlace),
    shouldRequestRefreshedLocation: shouldRequestRefreshedLocation(
      state.webConfig,
      state.location,
      activePlace.features,
    ),
    menu: state.menu,
  };
};

const mapDispatchToProps = {
  addToOrder,
  closePopUp,
  openToast2,
  closeToast2,
  removeProductFromOrder,
  updateItem,
  setOrderPlace,
  updateCart: updateOrder,
  updateOrder: _updateOrder,
  createOrder: _createOrder,
  getMenu: _getMenu,
  getOrderReadiness: _getOrderReadiness,
  setActivePlace: actions.setActivePlace,
  getLocation: api.getLocation,
  cancelOrder: api.cancelOrder,
  getPaymentKeyFor: api.getPaymentKey,
  checkIfCurrentLocationIsInVenue: api.checkIfCurrentLocationIsInVenue,
  setAgeRestrictionPrompt: setAgeRestrictionPrompt,
};

export default connect(mapStateToProps, mapDispatchToProps)(PopUp);
