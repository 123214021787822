import store from '../../redux';

export const gaEvent = (
  action: Gtag.EventNames | 'checkout_option',
  { ...rest }: Gtag.EventParams | Gtag.CustomParams,
) => {
  const {
    webConfig: { venueId, customerId, industryType },
  } = store.getState();
  let { gtag } = window;
  if (gtag) {
    gtag('event', action, {
      product_type: 'Experience F&B',
      client: customerId,
      merchant_name: venueId,
      industry: industryType,
      ...rest,
    });
  }
};
