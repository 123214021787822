import { ActionType } from '../action-types';
import { Action } from '../actions/discountCode.actions';
import { Discount, DiscountProblem } from '../../models/discountCode.model';

interface DiscountCodeState {
  discounts: Discount[];
  problems: DiscountProblem[];
  loading: boolean;
  errorText: any;
  selectedDiscount: Discount[];
  currentAppliedCode: any;
  isPopup: boolean;
  seasonPassDiscount?: Discount;
}

const initialState = {
  discounts: [],
  problems: [],
  loading: false,
  errorText: '',
  currentAppliedCode: '',
  selectedDiscount: [],
  isPopup: false,
};

export const getDiscountCodeReducer = (
  state: DiscountCodeState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case ActionType.GET_DISCOUNT:
      return {
        ...state,
        loading: true,
        errorText: '',
      };

    case ActionType.GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        discounts: [...state.discounts, ...action.payload.discounts],
        problems: action.payload.problems,
        currentAppliedCode: action.payload.currentAppliedCode,
        seasonPassDiscount: action.payload.seasonPassDiscount,
        loading: false,
        errorText: '',
      };

    case ActionType.GET_NO_DISCOUNT_FOUND:
      return {
        ...state,
        loading: false,
        errorText: action.payload,
      };

    case ActionType.GET_DISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        errorText: action.payload,
      };

    case ActionType.SET_DISCOUNT:
      return {
        ...state,
        selectedDiscount: action.payload,
      };

    case ActionType.REMOVE_DISCOUNT:
      const remainingDiscounts = state.selectedDiscount.filter(
        (d) => d.id !== action.payload,
      );

      return {
        ...state,
        discounts: [...remainingDiscounts],
        selectedDiscount: [...remainingDiscounts],
      };

    case ActionType.SET_DISCOUNT_POPUP:
      return {
        ...state,
        isPopup: action.payload,
      };

    case ActionType.RESET_DISCOUNT_DATA:
      return {
        ...initialState,
      };

    case ActionType.REMOVE_ERROR_TEXT:
      return {
        ...state,
        errorText: '',
      };

    default:
      return state;
  }
};
