import React, { useState } from 'react';
import classNames from 'classnames';

const Input = ({
  onChange,
  initialValue,
  label,
  id,
  type,
  errorMessage,
  name,
  maxLength,
  placeholder,
  displayCharCounter,
}) => {
  const [value, setValue] = useState(initialValue || '');
  const [charCounter, setCharCounter] = useState(
    initialValue ? initialValue.length : 0,
  );
  const extraProps =
    type === 'number'
      ? { min: '0', inputMode: 'numeric', pattern: '[0-9]*' }
      : {};
  const maxLengthNum = Number(maxLength);

  const handleChange = (event) => {
    const eventValue = truncate(event.target.value);
    onChange(name, eventValue);
    setValue(eventValue);
    if (displayCharCounter) {
      setCharCounter(eventValue.length);
    }
  };

  const truncate = (v) => {
    if (maxLengthNum && v.length > maxLengthNum) {
      return v.slice(0, maxLengthNum);
    }
    return v;
  };

  const truncatedValue = truncate(value);

  return (
    <div className={classNames('form-group', !!errorMessage && 'form-error')}>
      <label className="color-text-tertiary" htmlFor={id}>
        {label}
      </label>
      <input
        className="bg-color-card"
        id={id}
        name={name}
        type={type || 'text'}
        value={truncatedValue}
        onChange={handleChange}
        onBlur={handleChange}
        placeholder={placeholder}
        {...extraProps}
      />
      {!!errorMessage && (
        <div className="error-label color-error">{errorMessage}</div>
      )}
      {displayCharCounter && (
        <div className="char-counter color-text-tertiary">
          <span className="counter" data-testid={'counter'}>
            {charCounter}
          </span>
          <span className="separator">/</span>
          <span className="max-counter">{maxLengthNum}</span>
        </div>
      )}
    </div>
  );
};

export default Input;
