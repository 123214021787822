import { Order } from '../../models/order.model';
import { ActionType } from '../action-types';
import { Action } from '../actions/submitOrder.actions';

interface OrderState {
  order: Order;
  loading: boolean;
  error?: string;
}

const initialState = {
  order: {
    orderId: '',
  },
  loading: false,
  error: undefined,
};

export const orderSubmitReducer = (
  state: OrderState = initialState,
  action: Action,
) => {
  switch (action.type) {
    case ActionType.SUBMIT_ORDER:
      return {
        ...state,
        order: action.payload,
        loading: true,
        error: undefined,
      };

    case ActionType.SUBMIT_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
      };

    case ActionType.SUBMIT_ORDER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
