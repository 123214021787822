export const SAVE_ORDER_READINESS = 'SAVE_ORDER_READINESS';
export const GET_ORDER_READINESS = 'GET_ORDER_READINESS';

export const getOrderReadinessRequested = () => ({
  type: `${GET_ORDER_READINESS}_REQUESTED`,
});

export const getOrderReadinessReceived = () => ({
  type: `${GET_ORDER_READINESS}_RECEIVED`,
});

export const saveOrderReadiness = (data) => ({
  type: SAVE_ORDER_READINESS,
  data,
});
