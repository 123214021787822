import React, { FC } from 'react';
import PickupTimeWindowsList from '../../components/PickupTimeWindowsList';
import './index.scss';

export enum ChangeWindowReason {
  CHANGE_PTW = 'CHANGE_PTW',
  PTW_UNAVAILABLE = 'PTW_UNAVAILABLE',
}

type Props = {
  reason: ChangeWindowReason;
};

export const ChangePickupWindow: FC<Props> = ({ reason }: Props) => {

  return (
    <div id="change-pickup-window">
      <PickupTimeWindowsList listState={reason} />
    </div>
  );
};
