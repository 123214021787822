import React from 'react';

const NoticeBanner = ({ icon, alt, message }) => {
  return (
    <div className="on-site-notice-banner bg-color-discount">
      <img src={icon} alt={alt} />
      <p>{message}</p>
    </div>
  );
};

export default NoticeBanner;
