import moment from 'moment';

/**
 * filterByAvailability is true = filter by available places
 * filterByAvailability is false = filter by unavailable places
 * @returns list of filtered places
 */
export const filterPlacesByOrderingHours = (
  places,
  filterByAvailability,
  {
    displayOrderingHours,
    displayPickupTimeWindow,
    setEstimatedPickupTime,
    setOpensAt,
    setClosesAt,
  },
  customNow = null,
) => {
  const now = customNow || moment();
  const endOfDay = moment().endOf('day');

  if (filterByAvailability) {
    return places
      .filter((place) => place.activeMenu)
      .filter((place) =>
        nextPickupTimeWindow(
          now,
          place,
          displayOrderingHours,
          displayPickupTimeWindow,
          setEstimatedPickupTime,
          endOfDay,
          setOpensAt,
          setClosesAt,
        ),
      );
  }

  return places
    .filter((place) => place.activeMenu)
    .filter(
      (place) =>
        !nextPickupTimeWindow(
          now,
          place,
          displayOrderingHours,
          displayPickupTimeWindow,
          setEstimatedPickupTime,
          endOfDay,
          setOpensAt,
          setClosesAt,
        ),
    );
};

const isPlaceOpen = (place, now, endOfDay, setOpensAt, setClosesAt) => {
  if (!place.hours) {
    return false;
  }

  for (let o of place.hours) {
    if (!isScheduleToday(o.days, now)) {
      continue;
    }

    // Check current date against opening and closing time of place
    const openTime = o.openingTime.split(':');
    const closeTime = o.closingTime.split(':');
    const dateOpen = moment({
      hour: openTime[0],
      minute: openTime[1],
      second: openTime[2],
    });
    const dateClose = moment({
      hour: closeTime[0],
      minute: closeTime[1],
      second: closeTime[2],
    });

    // Supports opening time on evening and closing time on morning
    if (dateOpen.isAfter(dateClose)) {
      dateOpen.subtract(1, 'days');
    }

    if (now.isBetween(dateOpen, dateClose, undefined, '[]')) {
      // Inclusive
      setClosesAt({
        id: place.id,
        closesAt: getNextOpeningOrClosing(place.hours, now, endOfDay, false),
      });
      return true;
    }
  }

  if (place.orderingUnavailable) {
    setOpensAt({
      id: place.id,
      opensAt: getNextOpeningOrClosing(place.hours, now, endOfDay, true),
    });
    return false;
  } else {
    return true;
  }
};

const getNextOpeningOrClosing = (hours, now, endOfDay, isNextOpen) => {
  const sortedOpening = hours
    .map((o) => {
      const openTime = o.openingTime.split(':');
      return {
        openDateTime: moment({
          hour: openTime[0],
          minute: openTime[1],
          second: openTime[2],
        }),
        days: o.days,
      };
    })
    .sort((a, b) => a.openDateTime.diff(b.openDateTime));

  const sortedClosing = hours
    .map((o) => {
      const closeTime = o.closingTime.split(':');
      return {
        closeDateTime: moment({
          hour: closeTime[0],
          minute: closeTime[1],
          second: closeTime[2],
        }),
        days: o.days,
      };
    })
    .sort((a, b) => a.closeDateTime.diff(b.closeDateTime));

  if (isNextOpen) {
    // Get next opening schedule
    for (let o of sortedOpening) {
      if (
        now.isBefore(o.openDateTime) &&
        o.openDateTime.isBefore(endOfDay) &&
        isScheduleToday(o.days, now)
      ) {
        return o.openDateTime.format('h:mmA').toLowerCase(); // 3:30pm or 3:30am
      }
    }
  } else {
    // Get next closing schedule
    for (let o of sortedClosing) {
      if (
        now.isBefore(o.closeDateTime) &&
        o.closeDateTime.isBefore(endOfDay) &&
        isScheduleToday(o.days, now)
      ) {
        return o.closeDateTime.format('h:mmA').toLowerCase(); // 3:30pm or 3:30am
      }
    }
  }

  return null; // Hide ordering hours in UI
};

const isScheduleToday = (days, now) => {
  const dayNow = now.format('dddd').toUpperCase();
  const daysOpen = days.map((o) => o.toUpperCase());
  return !!daysOpen.includes(dayNow);
};

const nextPickupTimeWindow = (
  now,
  place,
  displayOrderingHours,
  displayPickupTimeWindow,
  setEstimatedPickupTime,
  endOfDay,
  setOpensAt,
  setClosesAt,
) => {
  if (place) {
    setEstimatedPickupTime({
      id: place.id,
      estimatedPickupTime: formatEstimatedTime(now, place),
    });

    // if the location has PTW enabled but not for the current day, and the location is currently closed,
    // the location will appear in the unavailable section and the ordering hours attribute will not  display
    if (
      !place.pickupTimeWindowsEnabled &&
      !isPlaceOpen(place, now, endOfDay, setOpensAt, setClosesAt)
    ) {
      displayOrderingHours({ id: place.id, isDisplayOrderingHours: false });
      displayPickupTimeWindow({
        id: place.id,
        isDisplayPickupTimeWindow: false,
      });
      return false;
    }

    if (!place.pickupTimeWindowsEnabled && place.orderingUnavailable) {
      // display the ordering hours attribute and categorize the location in the unavailable section
      displayOrderingHours({ id: place.id, isDisplayOrderingHours: true });
      displayPickupTimeWindow({
        id: place.id,
        isDisplayPickupTimeWindow: false,
      });
      return false;
    } else if (place.pickupTimeWindowsEnabled && place.orderingUnavailable) {
      // hide the ordering hours attribute AND categorize the location in the unavailable section
      displayOrderingHours({ id: place.id, isDisplayOrderingHours: false });
      displayPickupTimeWindow({
        id: place.id,
        isDisplayPickupTimeWindow: true,
      });
      return false;
    } else if (place.pickupTimeWindowsEnabled && !place.orderingUnavailable) {
      // hide the ordering hours attribute AND present value returned from estimatedPickupTime: XX:XX AM/PM next available pickup window
      displayOrderingHours({ id: place.id, isDisplayOrderingHours: false });
      displayPickupTimeWindow({
        id: place.id,
        isDisplayPickupTimeWindow: true,
      });
      return true;
    } else if (!place.pickupTimeWindowsEnabled && !place.orderingUnavailable) {
      // then the ordering hours attribute will display and the location will appear in the available section
      displayOrderingHours({ id: place.id, isDisplayOrderingHours: true });
      displayPickupTimeWindow({
        id: place.id,
        isDisplayPickupTimeWindow: false,
      });
      return true;
    }
  }

  displayOrderingHours({ id: place.id, isDisplayOrderingHours: true });
  displayPickupTimeWindow({ id: place.id, isDisplayPickupTimeWindow: false });
  return false;
};

const formatEstimatedTime = (now, poi) => {
  if (poi && poi.estimatedPickupTime) {
    if (hasFormatted(poi.estimatedPickupTime)) return poi.estimatedPickupTime;

    let time = poi.estimatedPickupTime.split(':');
    return moment({ hour: time[0], minute: time[1] })
      .format('h:mm A')
      .toUpperCase();
  }
  return null;
};

const hasFormatted = (time) => time.match(/AM|PM/i);
