import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { inputValidation } from '../../helpers/billingValidation';
import { _login } from '../../redux/actions/api/siriusware';
import Button from '../Button';

import Input from '../FormFields/Input';

import './index.scss';

const SiriuswareLogin = (props) => {
  const [memberOrEmailError, setMemberOrEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [signedIn, setSignedIn] = useState(false);

  const [memberOrEmail, setMemberOrEmail] = useState('');
  const [password, setPassword] = useState('');

  const {
    localization: {
      memberOrEmail: memberOrEmailLabel,
      password: passwordLabel,
      signInCta,
      description,
      invalidPassword,
      invalidMemberIdOrEmail,
    },
    siriusware: { loggedIn, error },
    login,
    match: {
      params: { config, venue },
    },
    history: { push },
    spinner: { show },
  } = props;

  const handleChange = (name, value) => {
    const {
      localization: { requiredField },
    } = props;
    if (name === 'memberOrEmail') {
      setMemberOrEmail(value);
      setMemberOrEmailError(inputValidation(value, requiredField));
    } else if (name === 'password') {
      setPassword(value);
      setPasswordError(inputValidation(value, requiredField));
    }
  };

  const handleSignIn = () => {
    if (!signedIn) setSignedIn(true);
    login(memberOrEmail, password);
  };

  const disabled = memberOrEmail === '' || password === '';

  useEffect(() => {
    if (signedIn && error && !show) {
      setMemberOrEmailError(invalidMemberIdOrEmail);
      setPasswordError(invalidPassword);
    }
  }, [error, signedIn, invalidPassword, invalidMemberIdOrEmail, show]);

  useEffect(() => {
    // Go to Places page if logged in
    if (loggedIn) {
      push(`/config/${config}/venue/${venue}/places`);
    }
  }, [loggedIn, config, venue, push]);

  return (
    <div id="billing-info">
      <section className="siriusware-info contains-forms">
        <div className="content">
          <p className="sign-in-text">{description}</p>
          <Input
            errorMessage={memberOrEmailError}
            id={'memberOrEmail'}
            type={'text'}
            initialValue={memberOrEmail}
            label={memberOrEmailLabel}
            name={'memberOrEmail'}
            onChange={handleChange}
          />
          <Input
            errorMessage={passwordError}
            id={'password'}
            type={'password'}
            initialValue={password}
            label={passwordLabel}
            name={'password'}
            onChange={handleChange}
          />
          <Button onClick={handleSignIn} disabled={disabled}>
            <div className="button-content-wrapper center">
              <span className="primary-text header-text uppercase">
                {signInCta}
              </span>
            </div>
          </Button>
        </div>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  localization: state.localization.siriusware,
  siriusware: state.siriusware,
  spinner: state.spinner,
  webConfig: state.webConfig,
});

const mapDispatchToProps = {
  login: _login,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SiriuswareLogin),
);
