import {
  findCategoryByProductId,
  findOrderItemCurrencyFromProducts,
} from '../../helpers';
import {
  AFFILIATION,
  ecommerceActionCart,
} from '../../helpers/googleAnalytics/ecommerceGoogleAnalytics';
import {
  AnalyticsAction,
  AnalyticsItem,
} from '../../models/googleAnalytics.model';
import { MenuCategory } from '../../models/menuCategory.model';
import { MenuProduct } from '../../models/menuProduct.model';

export const reportRemoveFromCart = (
  cartItem: any,
  index: number,
  activePlace: any,
  products: Array<MenuProduct>,
  categories: Array<MenuCategory>,
) => {
  try {
    const { itemListId, itemListName } = getItemListIdAndName(activePlace);

    const currentCategory = findCategoryByProductId(
      cartItem.productId,
      categories,
    );
    const product = mapIdAndCurrency(cartItem, products);
    const items: Array<AnalyticsItem> = [];
    items.push({
      item_id: product.id,
      item_name: product.displayName,
      affiliation: AFFILIATION,
      currency: product.currency,
      index: index,
      item_brand: activePlace.id,
      item_category: currentCategory.displayName,
      item_list_id: itemListId,
      item_list_name: itemListName,
      price: product.price,
      quantity: product.quantity,
    });

    if (product && items.length) {
      ecommerceActionCart(
        AnalyticsAction.REMOVE_FROM_CART,
        product.currency,
        product.price,
        items,
      );
    }
  } catch (error) {}
};

const getItemListIdAndName = (activePlace: any) => {
  const { activeMenu } = activePlace;
  return {
    itemListId: activeMenu.id,
    itemListName: activeMenu.content.name,
  };
};

const mapIdAndCurrency = (cartItem: any, products: any) => {
  return {
    id: cartItem.productId, // Mapped productId to id
    currency: findOrderItemCurrencyFromProducts(cartItem, products),
    ...cartItem,
  };
};
