import {
  SIRIUSWARE_LOG_IN,
  ERROR_SIRIUSWARE_LOGIN,
} from '../actions/siriusware.js';

const defaultState = {
  loggedIn: null,
  passes: [],
  error: null,
};

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case SIRIUSWARE_LOG_IN: {
      return { ...state, error: false, loggedIn: true, ...data };
    }
    case ERROR_SIRIUSWARE_LOGIN: {
      return { ...state, error: true, loggedIn: false, passes: [] };
    }
    default:
      return state;
  }
};
