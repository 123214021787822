import { Order } from '../../models/order.model';
import { PickupZone } from '../../models/pickup-zone.model';

export const getZoneName = (order: Order,  placeName: string,  pickupZones: PickupZone[]) => {
  if (!order?.pickupZoneId || !pickupZones) {
    return null;
  }
  const zones = pickupZones.filter((pz) => pz.pickupZoneId === order.pickupZoneId);
  if (zones.length > 0) {
    return `${zones[0].label} ${order.zoneEnteredData || ''}`;
  }
  return placeName || null;
};