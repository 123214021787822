import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { getItemsListForGAEvent } from '../../helpers/googleAnalytics/checkoutSteps';
import { gaEvent } from '../../helpers/googleAnalytics/gaEvent';
import { AnalyticsAction } from '../../models/googleAnalytics.model';
import { CheckinOrderRequest, Order } from '../../models/order.model';
import { ActionType } from '../action-types';
import { Action } from '../actions/order.actions';
import store from '../index';

export const checkinOrder = (history: any) => (dispatch: Dispatch<Action>) => {
  const {
    order,
    token,
    webConfig: { apiUrl, customerId, configFile, venueId },
    zoneDetails,
  } = store.getState();

  dispatch({
    type: ActionType.CHECKIN_ORDER,
    payload: order,
  });

  if (order) {
    const orderRequest: CheckinOrderRequest = {
      customerId: order.customerId,
      externalOrderId: order.externalOrderId,
      placeId: order.placeId,
      smsOptIn: order.smsOptIn,
      vehicleColor: order.vehicleColor,
      vehicleMake: order.vehicleMake,
      vehicleModel: order.vehicleModel,
      zone: order.zone || getZoneDetail(),
    };

    if (zoneDetails && orderRequest.zone && zoneDetails.zoneUserCustomValue) {
      orderRequest.customerSpecificOrderDetails = {
        [orderRequest.zone]: zoneDetails.zoneUserCustomValue,
      };
    }

    axios({
      method: 'post',
      url: `${apiUrl}orders/${order.orderId}/checkin`,
      headers: {
        Authorization: `Bearer ${token.identity.access}`,
        'X-Customer-ID': customerId,
        'Content-Type': 'application/json',
      },
      data: { ...orderRequest },
    })
      .then((response: AxiosResponse<Order, undefined>) => {
        dispatch({
          type: ActionType.CHECKIN_ORDER_SUCCESS,
          payload: response.data,
        });
        gaEvent(AnalyticsAction.PURCHASE, {
          transaction_id: response.data.externalOrderId,
          value: response.data.orderTotal,
          tax: response.data.orderTax,
          shipping: 0,
          items: getItemsListForGAEvent() || undefined,
          currency: response.data.currency,
          coupon:
            response.data.discounts && response.data.discounts.length > 0
              ? response.data.discounts[0]?.code
              : undefined,
        });
        history.push(
          `/config/${configFile}/venue/${venueId}/order/${order.orderId}`,
        );
      })
      .catch((error) => {
        dispatch({
          type: ActionType.CHECKIN_ORDER_FAILURE,
          payload: error.message,
        });
      });
  }
};

const getZoneDetail = () => {
  const {
    orderReadiness: { pickupZones, deliveryZones },
    zoneDetails: { zone },
  } = store.getState();
  const defaultZone = pickupZones.length > 0 ? pickupZones[0].pickupZoneId : '';
  const selectedZone =
    zone ||
    defaultZone ||
    (deliveryZones && deliveryZones.length > 0 && deliveryZones[0]) ||
    'INSIDE_TAKE_OUT';

  return selectedZone;
};
