import { Buffer } from 'buffer';
import { find, sortBy } from 'lodash/collection';
import moment from 'moment';
import { Fragment } from 'react';

import {
  ACCESSO_PAY_STATE,
  BILLING_INFORMATION_STATE,
} from '../redux/actions/payment';

export const MENU_PAGE = 'MENU';
export const PLACES_PAGE = 'PLACES';
export const VIEW_ORDER_PAGE = 'VIEW_ORDER';
export const ORDER_DETAILS_PAGE = 'ORDER_DETAILS';
export const BILLING_INFORMATION_PAGE = 'BILLING_INFORMATION';
export const CONFIRMATION_PAGE = 'CONFIRMATION';
export const ERROR_PAGE = 'ERROR';
export const ORDER_OPERATION_CREATE = 'CREATE';
export const SIRIUSWARE_LOGIN_PAGE = 'SIRIUSWARE_LOGIN';
export const PAYMENT_PAGE = 'PAYMENT';
export const ALERT_UNAVAILABLE_PTW_PAGE = 'ALERT_UNAVAILABLE_PTW_PAGE';
export const CHANGE_PTW = 'CHANGE_PTW';

export const URLify = (string) => {
  return encodeURIComponent(string.replace(' ', '-').toLowerCase());
};

export const determinePage = (url, paymentState) => {
  // TODO: This is not ideal and should be refactored.
  if (url.indexOf('/order') > -1) {
    if (url.includes('/ptw/change')) {
      return CHANGE_PTW;
    } else if (url.includes('/ptw/unavailable')) {
      return ALERT_UNAVAILABLE_PTW_PAGE;
    } else if (url.includes('/order') && !url.endsWith('/order')) {
      return ORDER_DETAILS_PAGE;
    }
    return VIEW_ORDER_PAGE;
  } else if (url.indexOf('/billing') > -1) {
    if (paymentState === BILLING_INFORMATION_STATE) {
      return BILLING_INFORMATION_PAGE;
    } else if (paymentState === ACCESSO_PAY_STATE) {
      return PAYMENT_PAGE;
    }
    return BILLING_INFORMATION_PAGE;
  } else if (url.indexOf('/confirmation') > -1) {
    return ORDER_DETAILS_PAGE;
  } else if (url.indexOf('/menu') > -1) {
    return MENU_PAGE;
  } else if (url.indexOf('/places') > -1) {
    return PLACES_PAGE;
  } else if (url.indexOf('/error') > -1) {
    return ERROR_PAGE;
  } else if (url.indexOf('/sign-in') > -1) {
    return SIRIUSWARE_LOGIN_PAGE;
  }

  return null;
};

export const createPromiseId = () => {
  return btoa(`promise-${new Date()}`);
};

export const checkIfWillBeOpenLater = (
  { placeTimeWithOffsetApplied, allOrderingWindows },
  customNow = null,
) => {
  const storeTime = moment.parseZone(placeTimeWithOffsetApplied);
  if (!allOrderingWindows) {
    return null;
  }
  const orderingWindows = allOrderingWindows
    .filter(
      (window) =>
        window &&
        window.orderOperation === ORDER_OPERATION_CREATE &&
        window.begins !== 'N/A' &&
        window.ends !== 'N/A',
    )
    .map((window) => {
      const hmsBegin = parseTimeStringToJson(window.begins);
      const hmsEnd = parseTimeStringToJson(window.ends);

      const begins = moment(storeTime);
      begins.hours(parseInt(hmsBegin.hours));
      begins.minutes(parseInt(hmsBegin.minutes));
      begins.seconds(parseInt(hmsBegin.seconds));

      const ends = moment(storeTime);
      ends.hours(parseInt(hmsEnd.hours));
      ends.minutes(parseInt(hmsEnd.minutes));
      ends.seconds(parseInt(hmsEnd.seconds));

      return { begins, ends };
    })
    .sort((orderWindowA, orderWindowB) =>
      orderWindowA.begins.diff(orderWindowB.begins),
    );

  const now = customNow || moment();
  const firstMoment = moment(storeTime);
  firstMoment.hours(0);
  firstMoment.minutes(0);
  firstMoment.seconds(0);

  for (const { begins, ends } of orderingWindows) {
    if (now.isAfter(ends)) continue;

    return now.isBetween(firstMoment, begins) ? begins.format('h:mmA') : null;
  }
  return null;
};

export const determineOrderingWindows = (
  now,
  store,
  orderOperation,
  screenType,
) => {
  const orderingWindows =
    screenType && screenType === 'MENU'
      ? store.allOrderingWindows
      : store.orderingWindows;

  const storeTime = moment.parseZone(store.placeTimeWithOffsetApplied);
  let orderWindowStatus = { status: true };

  if (!orderingWindows) {
    return orderWindowStatus;
  }

  const orderCreationWindows = orderingWindows.filter(
    (window) => window.orderOperation === orderOperation,
  );

  for (const orderWindow of orderCreationWindows) {
    if (orderWindow.begins === 'N/A' || orderWindow.ends === 'N/A') {
      orderWindowStatus = {
        orderOperation: orderWindow.orderOperation,
        status: false,
      };
      break;
    }
    const hmsBegin = parseTimeStringToJson(orderWindow.begins);
    const hmsEnd = parseTimeStringToJson(orderWindow.ends);

    const momentBegin = moment(storeTime);
    momentBegin.hours(hmsBegin.hours);
    momentBegin.minutes(hmsBegin.minutes);
    momentBegin.seconds(hmsBegin.seconds);

    const momentEnd = moment(storeTime);
    momentEnd.hours(hmsEnd.hours);
    momentEnd.minutes(hmsEnd.minutes);
    momentEnd.seconds(hmsEnd.seconds);

    // Fix offset to local time
    momentBegin.utcOffset(now.utcOffset());
    momentEnd.utcOffset(now.utcOffset());

    const timeFormat = 'h:mmA'; // eg. 2:00PM

    const isAlwaysOpen = momentBegin.isSame(momentEnd);

    if (isAlwaysOpen || now.isBetween(momentBegin, momentEnd)) {
      orderWindowStatus = {
        orderOperation: orderWindow.orderOperation,
        status: true,
        begins: momentBegin.format(timeFormat),
        ends: momentEnd.format(timeFormat),
      };

      break;
    } else {
      orderWindowStatus = {
        orderOperation: orderWindow.orderOperation,
        status: false,
        begins: momentBegin.format(timeFormat),
        ends: momentEnd.format(timeFormat),
      };
    }
  }

  return orderWindowStatus;
};

export const parseTimeStringToJson = (str) => {
  const strArr = str.split(':');
  return {
    hours: strArr[0],
    minutes: strArr[1],
    seconds: strArr[2],
  };
};

export const queryConfigBuilder = (queryParams) => {
  // Whitelist of fields to copy over from the url parameters
  let configByQuery = {};
  [
    'apiUrl',
    'subPath',
    'configFile',
    'customerId',
    'venueId',
    'placeId',
    'menuId',
    'access_token',
    'refresh_token',
    'menuVenue',
    'overrideInVenue',
    'pickupLoc',
    'pickupText',
  ]
    .filter((field) => queryParams[field])
    .forEach((field) => (configByQuery[field] = queryParams[field]));
  return configByQuery;
};

export const checkAndConvertToBoolean = (string) => {
  if (string === 'true') {
    return true;
  } else if (string === 'false') {
    return false;
  }

  return string;
};

export const scrollTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
  });
};

export const sortProducts = (products) => {
  return (
    products &&
    sortBy(products, (product) => {
      // Check if lineItemId is base64 encoded and decode it
      if (
        product.lineItemId &&
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
          product.lineItemId,
        )
      ) {
        return Buffer.from(product.lineItemId, 'base64')
          .toString()
          .split(':')[0];
      } else if (product.lineItemId) {
        return product.lineItemId;
      }
    })
  );
};

export const redirectToErrorPage = (history, errorMessage) => {
  if (history && history.location.pathname !== '/error') {
    history.push('/error', errorMessage);
  }
};

export const newlinesToBreaks = (text) => {
  return text.split('\n').map((value, idx) => (
    <Fragment key={idx}>
      {value}
      <br />
    </Fragment>
  ));
};

export const getCloseUrl = (venueId) => {
  // FB-1510 : required by mobile app to exit chrome custom tab and return to the parent app.
  // it expects venueId in the url without underscore and in lowercase.
  return `${venueId}://actions/close`.replace(/_/g, '-').toLowerCase();
};

export const getCurrentCategory = (params, categories) => {
  const categoryParam = params.category;

  // The url param is allowed to match the UUID or the display name. Default to the first category
  const matchCategory = (cat) =>
    cat.id === categoryParam || URLify(cat.displayName) === categoryParam;
  return find(categories, matchCategory) || categories[0];
};

export const getProductsFromCategory = (fullProductList, category) => {
  let renderProducts = [];
  category &&
    category.products &&
    category.products.forEach((product) => {
      renderProducts.push(
        find(fullProductList, (stateProd) => {
          return stateProd.id === product;
        }),
      );
    });

  return renderProducts.filter((product) => product !== undefined);
};

export const findCategoryByProductId = (productId, categories) => {
  return find(categories, (category) => {
    return category.products.includes(productId);
  });
};

export const findOrderItemCurrencyFromProducts = (orderItem, products) => {
  // No currency in orderItem object, need to find it in products object by product ID
  const foundCurrency = find(products, (p) => {
    return orderItem.productId === p.id;
  });
  return foundCurrency.currency;
};
