import React from 'react';

import Group from './group';

import './index.scss';

export default (props) => {
  const { localization, item, modifierGroups = [], updateModifiers } = props;

  // Rebuild group information lost during order management
  const groups = {};
  modifierGroups.forEach((group) => {
    const modifiers = group.modifiers || [];
    modifiers.forEach((mod) => {
      groups[mod.id] = group.id;
    });
  });
  item.modifiers = item.modifiers.map((mod) => {
    mod.modifierGroupId = groups[mod.modifierId];
    return mod;
  });

  return (
    <div className="modifier-group-wrapper">
      {item &&
        modifierGroups &&
        modifierGroups.map((group) => (
          <Group
            key={`group-${group.id}`}
            localization={localization}
            item={item}
            group={group}
            updateModifiers={updateModifiers}
          />
        ))}
    </div>
  );
};
