import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { scrollTop } from '../../helpers';
import {
  reportPageView,
  reportCategorySelect,
} from '../../helpers/googleAnalytics';

import './index.scss';

class MenuCategory extends React.Component {
  constructor(props) {
    super(props);

    this.onClickHandler = this.onClickHandler.bind(this);
  }

  onClickHandler() {
    const { match, menu, category } = this.props;

    const { config, venue, placeId, category: categoryId } = match.params;

    if (categoryId !== category.id) {
      const catName = category.displayName;
      const catPath = `/config/${config}/venue/${venue}/place/${placeId}/menu/${menu.id}/cat/${category.id}`;
      reportPageView(catPath);
      reportCategorySelect(catName);
      scrollTop();
    }
  }

  render() {
    const { category, activeCategory, match } = this.props;
    const { config, venue, placeId, menuId } = match.params;

    return (
      <li
        className={`header-secondary-text ${
          activeCategory === category.id ? 'active' : ''
        }`}>
        <Link
          className="color-nav-header"
          to={`/config/${config}/venue/${venue}/place/${placeId}/menu/${menuId}/cat/${category.id}`}
          onClick={this.onClickHandler}>
          {category.displayName}
        </Link>
      </li>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
  };
};

export default connect(mapStateToProps)(MenuCategory);
