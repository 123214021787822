import { connect } from 'react-redux';
import Button from '../Button';

interface OrderButtonProps {
  className?: string;
  type:
    | 'OK'
    | 'CANCEL'
    | 'CONFIRM'
    | 'DIRECTIONS'
    | 'CHECKIN'
    | 'NEW'
    | 'OTHER';
  disabled?: boolean;
  onClick?: () => void;
  buttonText?: string;
}

interface StateProps {
  localization: any;
}

type Props = OrderButtonProps & StateProps;

function OrderButton({
  className,
  type,
  localization,
  disabled,
  onClick,
  buttonText,
}: Props) {
  const {
    button: { cancelOrder, ok, confirm, directions, checkin, newOrder },
  } = localization;
  return (
    <>
      {type === 'OK' && (
        <Button
          className={`uppercase bg-color-primary color-text-secondary ${
            className ?? ''
          }`}
          onClick={onClick}
          disabled={disabled}>
          {ok}
        </Button>
      )}
      {type === 'CANCEL' && (
        <Button
          className={`uppercase bg-color-error color-text-secondary ${
            className ?? ''
          }`}
          onClick={onClick}
          disabled={disabled}>
          {cancelOrder}
        </Button>
      )}
      {type === 'CONFIRM' && (
        <Button
          className={`uppercase bg-color-primary color-text-secondary ${
            className ?? ''
          }`}
          onClick={onClick}
          disabled={disabled}>
          {confirm}
        </Button>
      )}
      {type === 'DIRECTIONS' && (
        <Button
          className={`uppercase bg-color-primary color-text-secondary ${
            className ?? ''
          }`}
          onClick={onClick}
          disabled={disabled}>
          {directions}
        </Button>
      )}
      {type === 'CHECKIN' && (
        <Button
          className={`uppercase bg-color-primary color-text-secondary ${
            className ?? ''
          }`}
          onClick={onClick}
          disabled={disabled}>
          {checkin}
        </Button>
      )}
      {type === 'NEW' && (
        <Button
          className={`uppercase bg-color-primary color-text-secondary ${
            className ?? ''
          }`}
          onClick={onClick}
          disabled={disabled}>
          {newOrder}
        </Button>
      )}
      {type === 'OTHER' && buttonText && (
        <Button
          className={`uppercase bg-color-primary color-text-secondary ${
            className ?? ''
          }`}
          onClick={onClick}
          disabled={disabled}>
          {buttonText}
        </Button>
      )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    localization: state.localization,
  };
};

export default connect(mapStateToProps)(OrderButton);
