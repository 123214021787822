import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import OrderButton from '../../../components/OrderButton/OrderButton';
import Spinner from '../../../components/Spinner';
import { getTimeFormat } from '../../../helpers/format';
import { useActions } from '../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { OrderStatus, PickupZoneType } from '../../../models/order.model';
import { openToast2 } from '../../../redux/actions/toast2';

import './OrderCheckinStatus.scss';

type OrderCheckinStatusProps = {
  localization: any;
  places: any;
};

interface DispatchProps {
  openToast2: typeof openToast2;
}

// change App State here
const mapStateToProps = (state: any) => {
  return {
    localization: state.localization.checkinStatuses,
    places: state.places,
  };
};

const mapDispatchToProps = {
  openToast2,
};

type Props = OrderCheckinStatusProps & DispatchProps;

const connector = connect(mapStateToProps, mapDispatchToProps);

const OrderCheckinStatus: FC<Props> = ({ localization, openToast2 }) => {
  const { checkinOrder } = useActions();
  const history = useHistory();
  const order = useTypedSelector((state) => state.order);
  const [errorMessageShown, setErrorMessageShown] = useState(false);
  const [userTimer, setUserTimer] = useState(null as unknown as NodeJS.Timer);
  const [userTime, setUserTime] = useState(new Date());
  const activePlace = useTypedSelector((state) => state.places.activePlace);
  const activePlaceContent = (
    useTypedSelector((state) => state.places.placeContent) as any[]
  )[activePlace?.fullyQualifiedId];
  if (order.error && !errorMessageShown) {
    openToast2({
      title:
        activePlaceContent?.webContent?.checkinErrorTitle ??
        localization.checkinErrorTitle,
      message:
        activePlaceContent?.webContent?.checkinErrorBody ??
        localization.checkinErrorBody,
      hasConfirmation: false,
      dismissCallback: () => {
        setErrorMessageShown(true);
      },
    });
    setErrorMessageShown(true);
  }

  useEffect(() => {
    if (order?.expireTime && !userTimer) {
      setUserTimer(
        setInterval(() => {
          setUserTime(new Date());
        }, 30 * 1000),
      );
    }
    return () => {
      if (userTimer) {
        clearInterval(userTimer);
      }
    };
  }, [order, userTimer]);

  const handleCheckin = () => {
    checkinOrder(history);
    setErrorMessageShown(false);
  };

  return (
    <div className={'order-checkin-status-wrapper'}>
      {order.orderId &&
        (!order.pickupWindow ||
          (order.orderStatus === OrderStatus.SUBMITTED &&
            order.expireTime &&
            new Date(order.expireTime) > userTime) ||
          order.orderStatus === OrderStatus.RELEASED) && (
          <div className={'checkin-step'}>
            {order.orderStatus === OrderStatus.SUBMITTED && (
              <div className={'checkin-order-actions'}>
                {order.pickupWindow && order.checkinTime && (
                  <div className="checkin-message">
                    <div className="color-confirmation-icon">
                      <span className="icon-small icon-i-clock"></span>
                    </div>
                    {order.checkinTime &&
                      new Date(order.checkinTime) > userTime && (
                        <p className="font-secondary color-confirmation-text">
                          {activePlaceContent?.webContent?.preCheckinMessage ??
                            localization.preCheckinMessage}{' '}
                          {getTimeFormat(order.checkinTime)}
                          {' - '}
                          {getTimeFormat(order.expireTime)}
                        </p>
                      )}
                    {order.checkinTime &&
                      new Date(order.checkinTime) <= userTime &&
                      order.expireTime &&
                      new Date(order.expireTime) > userTime && (
                        <p className="font-secondary color-confirmation-text">
                          {activePlaceContent?.webContent?.checkinMessage ??
                            localization.checkinMessage}
                        </p>
                      )}
                  </div>
                )}
                <div className={'checkin-order-button-wrapper'}>
                  {order.pickupWindow &&
                    order.expireTime &&
                    new Date(order.expireTime) > userTime && (
                      <OrderButton
                        onClick={handleCheckin}
                        type={
                          activePlaceContent?.webContent?.checkInButton
                            ? 'OTHER'
                            : 'CHECKIN'
                        }
                        buttonText={
                          activePlaceContent?.webContent?.checkInButton ?? null
                        }
                        disabled={
                          !order.checkinTime ||
                          new Date(order.checkinTime) > userTime ||
                          !order.expireTime ||
                          new Date(order.expireTime) < userTime
                        }
                      />
                    )}
                  {!order.pickupWindow && (
                    <OrderButton
                      onClick={handleCheckin}
                      type={
                        activePlaceContent?.webContent?.checkInButton
                          ? 'OTHER'
                          : 'CHECKIN'
                      }
                      buttonText={
                        activePlaceContent?.webContent?.checkInButton ?? null
                      }
                    />
                  )}
                </div>
              </div>
            )}
            {order.orderStatus === OrderStatus.RELEASED &&
              order.pickupZoneDetails?.type !==
                PickupZoneType.LOCKER_PICKUP && (
                <div className={'checkin-order-actions'}>
                  <div className="checkin-message">
                    <div className="color-confirmation-icon">
                      <span
                        className={
                          'color-checkmark icon-small icon-i-check-circle'
                        }></span>
                    </div>
                    <p className="font-secondary color-confirmation-text">
                      {activePlaceContent?.webContent?.checkedInMessage ??
                        localization.checkedInMessage}
                    </p>
                  </div>
                </div>
              )}
          </div>
        )}
      <Spinner
        show={order?.loading && order?.orderStatus === OrderStatus.SUBMITTED}
      />
    </div>
  );
};

export default connector(OrderCheckinStatus);
