import { PROMPTED_AUTH, RESET_PROMPTED_AUTH } from '../actions/prompts';

const defaultState = {
  auth: false,
};

export default (state = defaultState, action) => {
  const { type, data } = action;
  switch (type) {
    case PROMPTED_AUTH: {
      return {
        ...state,
        auth: data,
      };
    }
    case RESET_PROMPTED_AUTH: {
      return {
        ...state,
        auth: false,
      };
    }
    default:
      return state;
  }
};
