import classNames from 'classnames';
import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

// Components
import ErrorBoundary from '../ErrorBoundary/errorBoundary';
import ErrorPage from '../ErrorPage';
import Header from '../Header';
import Menu from '../Menu/menu.js';
import MenuCategoryList from '../MenuCategoryList';
import PageButton from '../PageButton';
import PlacesList from '../PlacesList/placesList.js';
import PopUp from '../PopUp';
import SiriuswareLogin from '../SiriuswareLogin';
import Spinner from '../Spinner';
import Toast from '../Toast';
import Toast2 from '../Toast2';

import {
  ChangePickupWindow,
  ChangeWindowReason,
} from '../../routes/ChangePickupWindow';
import OrderDetails from '../../routes/OrderDetails/OrderDetails';

// Actions
import { closeToast, openToast } from '../../redux/actions/toast';
import { openToast2 } from '../../redux/actions/toast2';

// Bugsnag
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

// Helpers
import alertIcon from '../../assets/icons/ic_alert_24.svg';
import {
  ALERT_UNAVAILABLE_PTW_PAGE,
  BILLING_INFORMATION_PAGE,
  CHANGE_PTW,
  determinePage,
  MENU_PAGE,
  ORDER_DETAILS_PAGE,
  PAYMENT_PAGE,
  PLACES_PAGE,
  SIRIUSWARE_LOGIN_PAGE,
  VIEW_ORDER_PAGE,
} from '../../helpers';

import './index.scss';

//Features
import { SIRIUSWARE_LOGIN_FEATURE } from '../../redux/actions/api/features';

let ErrorBoundaryComponent;
const { NODE_ENV } = process.env;
if (NODE_ENV !== 'development' && NODE_ENV !== 'test') {
  // Bugsnag startup
  Bugsnag.start({
    apiKey: '88a8743b4d22df89312c91917c9991f3',
    plugins: [new BugsnagPluginReact()],
    appVersion: '0.2.2',
  });

  ErrorBoundaryComponent =
    Bugsnag.getPlugin('react').createErrorBoundary(React);
} else {
  ErrorBoundaryComponent = ErrorBoundary;
}

class App extends React.Component {
  constructor(props) {
    super(props);

    // State is required to be initialized when using getDerivedStateFromProps
    this.state = {
      initApiRequestInitiated: false,
    };
  }

  getHeaderProps() {
    const {
      location,
      localization,
      activePlace,
      placeFeatures,
      payment: { status },
    } = this.props;

    let text = localization.pageHeader; // Default header

    if (activePlace) {
      text = activePlace.activeMenu.content.name;
      document.title = activePlace.label;
    } else {
      document.title = localization.defaultTitle;
    }
    const isCombineSubmitAndCheckin =
      placeFeatures?.uiControls?.combineSubmitAndCheckin;

    const ptwPopupHeader = isCombineSubmitAndCheckin
      ? localization.pickupTimeWindowsHeader
      : localization.checkInTimeWindowsHeader;

    let page = determinePage(location.pathname, status);
    switch (page) {
      case CHANGE_PTW:
        text = ptwPopupHeader;
        break;
      case BILLING_INFORMATION_PAGE: {
        text = localization.billingHeader;
        break;
      }
      case ORDER_DETAILS_PAGE: {
        text = localization.orderDetailsHeader;
        break;
      }
      case VIEW_ORDER_PAGE: {
        text = localization.orderHeader;
        break;
      }
      case SIRIUSWARE_LOGIN_PAGE: {
        text = localization.siriusware.signIn;
        break;
      }
      case PAYMENT_PAGE: {
        text = localization.paymentHeader;
        break;
      }
      case ALERT_UNAVAILABLE_PTW_PAGE: {
        text = localization.pickupTimeWindowsHeader;
        break;
      }
      default:
        break;
    }

    const isInOrder = page === VIEW_ORDER_PAGE;
    const isInBilling = page === BILLING_INFORMATION_PAGE;
    const isInPayment = page === PAYMENT_PAGE;
    const isInMenu = page === MENU_PAGE;
    const isChangePTW = page === CHANGE_PTW;
    const isPlacesPage = page === PLACES_PAGE;

    return {
      hasClose:
        !isInOrder &&
        !isInBilling &&
        !isInPayment &&
        !isInMenu &&
        !isChangePTW &&
        !isPlacesPage,
      hasBack:
        isInOrder || isInBilling || isInPayment || isInMenu || isChangePTW,
      text,
    };
  }

  showBanner = () => {
    const {
      closeToast,
      openToast,
      menu: { readinessStatus, willBeOpenLaterAt },
      webConfig: { menuVenue },
      localization: { closedEntireDay, opensLaterInTheCurrentDay },
      toast: { isOpen },
    } = this.props;

    if (readinessStatus === false && !isOpen) {
      const customBanner =
        willBeOpenLaterAt !== null
          ? opensLaterInTheCurrentDay.replace('%hour%', willBeOpenLaterAt)
          : closedEntireDay;
      openToast({ iconUrl: alertIcon, message: customBanner });
    }

    if (readinessStatus !== false && menuVenue && isOpen) {
      closeToast();
    }
  };

  componentDidUpdate(prevProps) {
    const {
      location,
      match: { params },
    } = this.props;
    this.showBanner();
    if (location !== prevProps.location) {
      const { config, venue, placeId, menuId } = params;

      const prevPlaceId = prevProps.match.params.placeId;
      const prevMenuId = prevProps.match.params.menuId;
      const prevConfig = prevProps.match.params.config;
      const prevVenue = prevProps.match.params.venue;

      if (
        placeId &&
        menuId &&
        prevPlaceId &&
        prevMenuId &&
        (placeId !== prevPlaceId || menuId !== prevMenuId)
      ) {
        // ROOM-4319 - Force a page refresh
        window.location.reload();
      } else if (
        prevConfig &&
        prevVenue &&
        (config !== prevConfig || venue !== prevVenue)
      ) {
        // ROOM-4319 - Force a page refresh
        window.location.reload();
      }
    }
  }

  render() {
    const {
      popup,
      spinner,
      history,
      openToast2,
      order,
      toast,
      features,
      webConfig: { selectedTheme, isSiriuswareClient },
      match,
    } = this.props;

    let page = determinePage(window.location.pathname);
    let appClassNames = classNames(
      'app',
      'color-primary',
      'bg-color-canvas',
      popup.isOpen ? 'disabled' : '',
      page && page.replace('_', '-').toLowerCase(),
    );

    const contentWrapperClassNames = classNames(
      'content-wrapper',
      toast.isOpen ? 'toast-open' : '',
      spinner.show ? 'disabled' : '',
    );

    const routePrefix = '/config/:config/venue/:venue';

    //Features
    const siriuswareLoginFeature =
      isSiriuswareClient && features[SIRIUSWARE_LOGIN_FEATURE];

    return (
      <ErrorBoundaryComponent>
        <section className={selectedTheme}>
          <Route path={'/'}>
            <div className={appClassNames}>
              <div className={'header-wrapper bg-color-primary'}>
                <Header
                  {...this.getHeaderProps()}
                  history={history}
                  openToast2={openToast2}
                  order={order}
                />
              </div>
              <Route
                exact
                path={[
                  `${routePrefix}/place/:placeId/menu/:menuId/cat/:category`,
                  `${routePrefix}/place/:placeId/menu/:menuId/`,
                ]}
                component={MenuCategoryList}
              />
              <Toast />
              <div className={contentWrapperClassNames}>
                <Route exact path={`${routePrefix}/order/:orderId`}>
                  <OrderDetails />
                </Route>
                <Route exact path={`${routePrefix}/order/:orderId/ptw/change`}>
                  <ChangePickupWindow reason={ChangeWindowReason.CHANGE_PTW} />
                </Route>
                <Route
                  exact
                  path={`${routePrefix}/order/:orderId/ptw/unavailable`}>
                  <ChangePickupWindow
                    reason={ChangeWindowReason.PTW_UNAVAILABLE}
                  />
                </Route>
                {siriuswareLoginFeature && (
                  <Route
                    path={`${routePrefix}/sign-in`}
                    component={SiriuswareLogin}
                  />
                )}
                <Route path={`${routePrefix}/places`} component={PlacesList} />
                <Route
                  path={[
                    `${routePrefix}/place/:placeId/menu/:menuId/cat/:category`,
                    `${routePrefix}/place/:placeId/menu/:menuId/`,
                  ]}
                  component={Menu}
                />
                <Route path={`${routePrefix}/error`} component={ErrorPage} />
              </div>
              <Spinner {...spinner} />
              <Route
                exact
                path={[
                  '/',
                  `${routePrefix}/place/:placeId/menu/:menuId/cat/:category`,
                  `${routePrefix}/place/:placeId/menu/:menuId/`,
                ]}
                component={PageButton}
              />
            </div>
            <PopUp match={match} history={history} />
            <Toast2 />
          </Route>
        </section>
      </ErrorBoundaryComponent>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    menu: state.menu,
    popup: state.popup,
    spinner: state.spinner,
    toast: state.toast,
    webConfig: state.webConfig,
    order: state.order,
    activePlace: state.places.activePlace,
    features: state.features,
    localization: state.localization,
    payment: state.payment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openToast: (data) => {
      dispatch(openToast(data));
    },
    closeToast: () => {
      dispatch(closeToast());
    },
    openToast2: (data) => {
      dispatch(openToast2(data));
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
