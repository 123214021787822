import { alertIconRed } from './imageAssets';

export const ERR_OFFLINE = 'OFFLINE';
export const ERR_PAYMENT = 'PAYMENT';
export const ERR_DEFAULT = 'DEFAULT';
/*
 *  You can create a similar object here and pass it via the react-router history.push's 2nd parameter(state)
 *  ex. history.push(<path>, offlineError)
 *
 *  This would then enable the ErrorPage component to render dynamic values for different error types.
 *  Unfortunately, functions cannot be serialized hence we cannot add a custom function for the button here.
 */
export const offlineError = {
  buttonText: 'Try again',
  header: 'No internet connection',
  message:
    "It looks like you're offline. Please try again once you're connected.",
  errorIcon: alertIconRed,
  errorType: ERR_OFFLINE,
};

export const defaultError = {
  buttonText: 'Continue',
  header: 'Oops',
  message: 'Something went wrong, please try again later.',
  errorIcon: alertIconRed,
  errorType: ERR_DEFAULT,
};

export const paymentError = {
  buttonText: 'Try again',
  header: 'Payment processor error',
  message: 'There was an error with the payment processor.',
  errorIcon: alertIconRed,
  errorType: ERR_PAYMENT,
};
