import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import { Action } from '../actions/getVenue.actions';
import store from '../index';
import { ActionType } from '../action-types';
import { Venue } from '../../models/venue.model';

export const getVenue =
  (venueId: string, onError: any) => (dispatch: Dispatch<Action>) => {
    const {
      token,
      webConfig: { apiUrl, customerId },
    } = store.getState();

    dispatch({
      type: ActionType.GET_VENUE,
    });

    axios({
      method: 'get',
      url: `${apiUrl}api/v1/public/places/venue:${venueId}`,
      headers: {
        Authorization: `Bearer ${token.identity.access}`,
        'X-Customer-ID': customerId,
        'Content-Type': 'application/json',
      },
    })
      .then((response: AxiosResponse<Venue, undefined>) => {
        dispatch({
          type: ActionType.GET_VENUE_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: ActionType.GET_VENUE_FAILURE,
          payload: error.message,
        });
        onError(error.message);
      });
  };
