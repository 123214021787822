export const setupWebGateway = (webConfig, _params = {}, authFlow = null) => {
  const { pickupFromUrl } = webConfig;

  _params = {
    subPath: webConfig.subPath,
    configFile: webConfig.configFile,
    venueId: webConfig.venueId,
    placeId: webConfig.placeId,
    overrideInVenue: webConfig.overrideInVenue,
    pickupLoc:
      pickupFromUrl && pickupFromUrl.pickupLoc ? pickupFromUrl.pickupLoc : null,
    pickupText:
      pickupFromUrl && pickupFromUrl.pickupText
        ? pickupFromUrl.pickupText
        : null,
    ..._params,
  };
  const params = new URLSearchParams();
  Object.keys(_params).forEach((key) => {
    _params[key] && params.set(key, _params[key]);
  });
  const redirectUrl = `${
    window.location.href.split('?')[0]
  }?${params.toString()}`;

  const gatewayParams = new URLSearchParams();
  gatewayParams.set('authFlow', authFlow || webConfig.authFlow);
  gatewayParams.set('customerId', webConfig.customerId);
  gatewayParams.set('redirect', redirectUrl);
  gatewayParams.set('client_id', webConfig.clientId || webConfig.customerId);

  return `${webConfig.gateway}?${gatewayParams.toString()}`;
};
