import axios, { AxiosResponse } from 'axios';
import { Dispatch } from 'redux';
import {
  Discount,
  DiscountApplicationType,
  DiscountCode,
  lineItemOption,
} from '../../models/discountCode.model';
import { Order } from '../../models/order.model';
import { Product } from '../../models/product.model';
import { ActionType } from '../action-types';
import { Action } from '../actions/discountCode.actions';
import store from '../index';

export const getDiscountCode =
  (barcode: any) => (dispatch: Dispatch<Action>) => {
    const {
      order,
      token,
      webConfig: { apiUrl, customerId },
      localization,
    } = store.getState();

    dispatch({
      type: ActionType.GET_DISCOUNT,
    });

    try {
      axios({
        method: 'get',
        url: `${apiUrl}discounts/${barcode}?orderId=${order.orderId}`,
        headers: {
          Authorization: `Bearer ${token.identity.access}`,
          'X-Customer-ID': customerId,
          'Content-Type': 'application/json',
        },
      })
        .then((response: AxiosResponse<DiscountCode, undefined>) => {
          if (response.data.discounts.length) {
            response.data.currentAppliedCode = barcode;
            const orderLevelDiscount = getOrderLevelDiscountIfAvailable(
              response.data.discounts,
            );
            const diningPlanDiscount = getDiningDiscountIfAvailable(
              response.data.discounts,
            );

            if (orderLevelDiscount) {
              response.data.seasonPassDiscount = orderLevelDiscount;
              dispatch({
                type: ActionType.SET_DISCOUNT,
                payload: [orderLevelDiscount],
              });
            }
            if (diningPlanDiscount) {
              const matchingDiscountedItemsInCart =
                getMatchedDiscountedItemsInCart(
                  order,
                  diningPlanDiscount.lineItemOptions,
                );
              if (
                matchingDiscountedItemsInCart &&
                matchingDiscountedItemsInCart.length > 0
              ) {
                dispatch({
                  type: ActionType.SET_DISCOUNT_POPUP,
                  payload: true,
                });
              }
            }
            dispatch({
              type: ActionType.GET_DISCOUNT_SUCCESS,
              payload: response.data,
            });
          }
          if (
            response.data.problems &&
            response.data.problems.length > 0 &&
            response.data.problems[0].errors &&
            response.data.problems[0].errors.length > 0 &&
            response.data.problems[0].errors[0].errorCode
          ) {
            dispatch({
              type: ActionType.GET_DISCOUNT_FAILURE,
              payload: response.data.problems[0].errors[0].errorCode,
            });
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          dispatch({
            type: ActionType.GET_DISCOUNT_FAILURE,
            payload: localization.cart.discountSearchError,
          });
        });
    } catch (error: any) {
      dispatch({
        type: ActionType.GET_DISCOUNT_FAILURE,
        payload: localization.cart.discountSearchError,
      });
    }
  };

export const setDiscount =
  (discount: Discount[]) => (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_DISCOUNT,
      payload: discount,
    });
  };

const getOrderLevelDiscountIfAvailable = (discounts: Discount[]) => {
  const orderLevelDiscountIndex = discounts.findIndex(
    (discount) =>
      discount.applicationType === DiscountApplicationType.ORDER_LEVEL_DISCOUNT,
  );
  return orderLevelDiscountIndex > -1
    ? discounts[orderLevelDiscountIndex]
    : null;
};

export const removeDiscount =
  (discountId: string) => (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.REMOVE_DISCOUNT,
      payload: discountId,
    });
  };

export const setDiscountPopup =
  (isPopup: boolean) => (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_DISCOUNT_POPUP,
      payload: isPopup,
    });
  };

export const resetDiscountData = () => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.RESET_DISCOUNT_DATA,
  });
};

const getMatchedDiscountedItemsInCart = (
  order: Order,
  diningPlanDiscountedProduct: lineItemOption[],
) => {
  let matchedItems: any = [];
  const diningPlanDiscountedLineItemsId = diningPlanDiscountedProduct.map(
    (d) => d.lineItemId,
  );
  if (order && order.products) {
    const selectedLineItemsId = order?.products.map(
      (p: Product) => p.lineItemId,
    );
    matchedItems =
      diningPlanDiscountedLineItemsId?.filter((item) =>
        selectedLineItemsId?.includes(item),
      ) || [];
  }

  return matchedItems;
};

const getDiningDiscountIfAvailable = (discounts: Discount[]) => {
  const diningPlanDiscountIndex = discounts.findIndex(
    (discount) =>
      discount.applicationType === DiscountApplicationType.ITEM_LEVEL_DISCOUNT,
  );
  return diningPlanDiscountIndex > -1
    ? discounts[diningPlanDiscountIndex]
    : null;
};

export const removeErrorText = () => (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.REMOVE_ERROR_TEXT,
  });
};
