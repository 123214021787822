import { getCurrentCategory, getProductsFromCategory } from '../../helpers';
import {
  AnalyticsAction,
  AnalyticsItem,
} from '../../models/googleAnalytics.model';
import {
  AFFILIATION,
  ecommerceActionCart,
} from '../../helpers/googleAnalytics/ecommerceGoogleAnalytics';
import { MenuProduct } from '../../models/menuProduct.model';
import { MenuCategory } from '../../models/menuCategory.model';

export const reportAddToCart = (
  params: any,
  cartItem: any,
  activePlace: any,
  products: Array<MenuProduct>,
  categories: Array<MenuCategory>,
) => {
  try {
    const { itemListId, itemListName } = getItemListIdAndName(activePlace);

    const currentProducts = getProductsFromCategory(
      products,
      getCurrentCategory(params, categories),
    );
    const currentCategory = getCurrentCategory(params, categories);
    const index = currentProducts.findIndex((o: any) => o.id === cartItem.id);
    const items: Array<AnalyticsItem> = [];
    items.push({
      item_id: cartItem.id,
      item_name: cartItem.displayName,
      affiliation: AFFILIATION,
      currency: cartItem.currency,
      index: index,
      item_brand: activePlace.id,
      item_category: currentCategory.displayName,
      item_list_id: itemListId,
      item_list_name: itemListName,
      price: cartItem.price,
      quantity: cartItem.quantity,
    });

    if (cartItem && items.length && items[0].index >= 0) {
      ecommerceActionCart(
        AnalyticsAction.ADD_TO_CART,
        cartItem.currency,
        cartItem.price,
        items,
      );
    }
  } catch (error) {}
};

const getItemListIdAndName = (activePlace: any) => {
  const { activeMenu } = activePlace;
  return {
    itemListId: activeMenu.id,
    itemListName: activeMenu.content.name,
    activePlaceId: activePlace.id,
  };
};
