import moment from 'moment';

export const formatTimeCheckinWindow = (beginTime, endTime) => {
  const [beginHours, beginMinutes] = beginTime.split(':');
  const [endHours, endMinutes] = endTime.split(':');

  const beginMoment = moment();
  beginMoment.set('hour', beginHours);
  beginMoment.set('minute', beginMinutes);

  const endMoment = moment();
  endMoment.set('hour', endHours);
  endMoment.set('minute', endMinutes);
  return `${beginMoment.format('h:mm')} - ${endMoment.format('h:mm A')}`;
};
