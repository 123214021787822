export const OPEN_POPUP = 'OPEN_POPUP';
export const CLOSE_POPUP = 'CLOSE_POPUP';
export const UPDATE_ITEM = 'UPDATE_ITEM';

export const openPopUp = (product, item, isEditing = false) => {
  return {
    type: OPEN_POPUP,
    data: {
      isEditing,
      isOpen: true,
      product,
      item: { ...item },
    },
  };
};

export const closePopUp = () => ({
  type: CLOSE_POPUP,
  isOpen: false,
});

export const updateItem = (data) => ({
  type: UPDATE_ITEM,
  data,
});
