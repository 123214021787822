const FastFoodIcon = (props: {
  /**
   * Primary class to apply the color to the icon
   * @default '#7BC143'
   */
  colorClass?: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="48"
    width="48"
    style={{ color: props.colorClass ? undefined : '#7BC143' }}
    className={props.colorClass}>
    <path d="M2 32.8q0-2.4.875-4.525.875-2.125 2.75-3.7 1.875-1.575 4.75-2.5t6.825-.925q4 0 6.85.925 2.85.925 4.725 2.5 1.875 1.575 2.75 3.7T32.4 32.8ZM35.4 46V32.25q0-5.8-3.775-9.425Q27.85 19.2 23.15 18.25L22.3 11h10.25V2h3v9H46l-3.35 32q-.15 1.3-1.075 2.15-.925.85-2.325.85ZM2 39.4V36h30.4v3.4ZM3.5 46q-.6 0-1.05-.45Q2 45.1 2 44.5v-1.9h30.4v1.9q0 .6-.45 1.05-.45.45-1.05.45Z" />
  </svg>
);

export default FastFoodIcon;
