import { OPEN_POPUP, CLOSE_POPUP, UPDATE_ITEM } from '../actions/popup';

const defaultState = {
  isOpen: false,
  isEditing: false,
  item: {
    lineItemId: null,
    quantity: 1,
    modifiers: [],
  },
  product: {},
};

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case OPEN_POPUP: {
      return {
        ...state,
        isEditing: data.isEditing,
        isOpen: data.isOpen,
        item: {
          ...state.item,
          ...data.item,
        },
        product: data.product,
      };
    }
    case CLOSE_POPUP: {
      return {
        ...defaultState,
      };
    }
    case UPDATE_ITEM: {
      return {
        ...state,
        item: {
          ...state.item,
          ...data,
        },
      };
    }
    default:
      return state;
  }
};
