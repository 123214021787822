import { PUSH_PROMISE, POP_PROMISE } from '../actions/spinner';

const defaultState = {
  promiseIds: [],
  show: false,
};

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case PUSH_PROMISE: {
      const newPromises = state.promiseIds;
      newPromises.push(data);

      return {
        ...state,
        show: true,
        promiseIds: [...newPromises],
      };
    }
    case POP_PROMISE: {
      const newPromises = state.promiseIds;
      const index = newPromises.indexOf(data);
      if (index !== -1) {
        newPromises.splice(index, 1);
      }

      return {
        ...state,
        promiseIds: [...newPromises],
        show: newPromises.length > 0,
      };
    }
    default:
      return state;
  }
};
