import React from 'react';
import { connect } from 'react-redux';
import Button from '../Button';
import { history } from '../../helpers/history';
import {
  _getOrderReadiness,
  _retrieveOrder,
} from '../../redux/actions/api/order';
import { _clearError } from '../../redux/actions/errorStatus';
import { ERR_OFFLINE, ERR_PAYMENT } from '../../helpers/errorMessages';
import { alertIconRed } from '../../helpers/imageAssets';

import './index.scss';

import Bugsnag from '@bugsnag/js';

class ErrorPage extends React.Component {
  componentDidMount() {
    const { error } = this.props;
    Bugsnag.notify(error);
  }

  setButtonAction = () => {
    const {
      getOrderReadiness,
      location,
      order,
      retrieveOrder,
      match,
      clearError,
    } = this.props;

    const error = location.state && location.state.error;
    const errorType = location.state && location.state.errorType;

    if (errorType === ERR_OFFLINE && error) {
      return () => {
        clearError();
      };
    }

    const returnToPreviousPage = () => {
      history.goBack();
    };

    if (errorType === ERR_OFFLINE || errorType === ERR_PAYMENT) {
      // If orderId is present, then we can try to retrieve the order and redirect the user
      // back to the cart
      if (order.orderId) {
        return () => {
          retrieveOrder(match.params, returnToPreviousPage);
        };
      } else {
        // Let's do a simple API call and see if we're online. If API call succeeds, redirect user.
        return () => {
          getOrderReadiness(match.params, returnToPreviousPage);
        };
      }
    } else {
      // Go back to the home page
      if (match && match.params) {
        const { config, venue } = match.params;
        return () => {
          history.push(`/config/${config}/venue/${venue}/places`);
        };
      } else {
        return () => {
          history.push(`/`);
        };
      }
    }
  };

  render() {
    const {
      location: { state },
    } = this.props;

    return (
      <div className="error-page-wrapper">
        <div className="error-image-wrapper">
          <img alt="error" src={(state && state.errorIcon) || alertIconRed} />
        </div>
        <div className="error-message-wrapper">
          <div className="error-header-wrapper color-text-primary font-primary">
            <span className="uppercase">{state ? state.header : 'ERROR'}</span>
          </div>
          <div className="error-text-wrapper font-secondary">
            <p className="error-text gray-secondary-text">
              {state ? state.message : 'There was an error.'}
            </p>
          </div>
        </div>
        <div className="error-button-wrapper font-primary">
          <Button
            className="error-button uppercase bg-color-error color-text-secondary"
            onClick={this.setButtonAction()}>
            {(state && state.buttonText) || 'Continue'}
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  order: state.order,
  errorStatus: state.errorStatus,
});

const mapDispatchToProps = {
  getOrderReadiness: _getOrderReadiness,
  retrieveOrder: _retrieveOrder,
  clearError: _clearError,
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
