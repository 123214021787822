import { FC, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'pebl';
import { openToast2, closeToast2 } from '../../redux/actions/toast2';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { _cancelOrder, _updateOrder } from '../../redux/actions/api/order';
import {
  acceptAgeRestriction,
  declineAgeRestriction,
  setAgeRestrictionPrompt,
} from '../../redux/action-creators';
import { Product } from '../../models/product.model';
import { removeProductFromOrder } from '../../redux/actions/order';
import { cloneDeep } from 'lodash';

interface AgeRestrictionPromptProps {
  localization: any;
  showAgeRestrictionPrompt: boolean;
  onPromptClose: () => void;
  match: any;
  navigateToPage: any;
}

const mapStateToProps = (state: any) => ({
  localization: state.localization.cart,
  order: state.order,
});

type DispatchProps = {
  openToast2: typeof openToast2;
  closeToast2: typeof closeToast2;
  _updateOrder: any;
  _cancelOrder: any;
  removeProductFromOrder: any;
};

const mapDispatchToProps = {
  openToast2,
  closeToast2,
  acceptAgeRestriction: acceptAgeRestriction,
  declineAgeRestriction: declineAgeRestriction,
  setAgeRestrictionPrompt: setAgeRestrictionPrompt,
  _updateOrder: _updateOrder,
  _cancelOrder: _cancelOrder,
  removeProductFromOrder: removeProductFromOrder,
};

type Props = AgeRestrictionPromptProps &
  DispatchProps & {
    acceptAgeRestriction: (isAgeRestrictionAccepted: boolean) => void;
    declineAgeRestriction: (isAgeRestrictionDeclined: boolean) => void;
    setAgeRestrictionPrompt: (setAgeRestrictionPrompt: boolean) => void;
  };

const connector = connect(mapStateToProps, mapDispatchToProps);

const AgeRestrictionPrompt: FC<Props> = ({
  localization,
  showAgeRestrictionPrompt,
  onPromptClose,
  _updateOrder,
  _cancelOrder,
  match,
  openToast2,
  closeToast2,
  acceptAgeRestriction,
  declineAgeRestriction,
  setAgeRestrictionPrompt,
  navigateToPage,
  removeProductFromOrder,
}) => {
  const {
    webContent: { idDetailTitle, idDetailDescription },
  } = useTypedSelector((state) => state.venue);

  const order = useTypedSelector((state) => state.order);
  const isAgeRestictionPromptSet = useTypedSelector(
    (state) => state.ageRestrictionPrompt.isAgeRestrictionPromptSet,
  );

  const promptOnDeleteToast = useCallback(() => {
    toast({
      icon: 'done',
      message: localization.ageRestrictedDeleteMessage,
    });
  }, [localization.ageRestrictedDeleteMessage]);

  const handleDecline = useCallback(() => {
    const clonedOrder = cloneDeep(order);
    const ageRestrictedProducts = clonedOrder.products.filter(
      (product: Product) => product.hasAgeRestriction,
    );
    const isAllItemsInCartAgeRestricted =
      ageRestrictedProducts.length === clonedOrder.products.length;
    if (isAllItemsInCartAgeRestricted) {
      _cancelOrder(promptOnDeleteToast);
    } else {
      ageRestrictedProducts.forEach((product: Product) => {
        if (product.hasAgeRestriction) {
          removeProductFromOrder(product);
        }
      });
      _updateOrder({}, promptOnDeleteToast);
    }
    declineAgeRestriction(true);
    setAgeRestrictionPrompt(false);
    closeToast2();
    onPromptClose();
  }, [
    _cancelOrder,
    _updateOrder,
    closeToast2,
    declineAgeRestriction,
    onPromptClose,
    order,
    removeProductFromOrder,
    setAgeRestrictionPrompt,
    promptOnDeleteToast,
  ]);

  const handleAccept = useCallback(() => {
    closeToast2();
    onPromptClose();
    acceptAgeRestriction(true);
    setAgeRestrictionPrompt(false);
    const redirect = () => {
      navigateToPage('/billing');
    };
    _updateOrder(match.params, redirect);
  }, [
    closeToast2,
    onPromptClose,
    acceptAgeRestriction,
    setAgeRestrictionPrompt,
    _updateOrder,
    match.params,
    navigateToPage,
  ]);

  useEffect(() => {
    if (showAgeRestrictionPrompt && isAgeRestictionPromptSet) {
      openToast2({
        title: idDetailTitle ?? localization.ageRestrictedDefaultTitle,
        htmlMessage: idDetailDescription
          ? idDetailDescription + localization.ageRestrictedMessage
          : localization.ageRestrictedMessage,
        dismissCallback: () => {
          onPromptClose();
        },
        buttons: [
          {
            label: localization.ageRestrictedAcceptLabel,
            action: () => handleAccept(),
          },
          {
            label: localization.ageRestrictedDeclineLabel,
            inverted: true,
            action: handleDecline,
          },
        ],
      });
    }
  }, [
    showAgeRestrictionPrompt,
    isAgeRestictionPromptSet,
    openToast2,
    handleAccept,
    handleDecline,
    idDetailDescription,
    idDetailTitle,
    localization,
    onPromptClose,
  ]);

  return null;
};

export default connector(AgeRestrictionPrompt);
