export const PUSH_PROMISE = 'PUSH_PROMISE';
export const POP_PROMISE = 'POP_PROMISE';

export const pushPromise = (promiseId) => ({
  type: PUSH_PROMISE,
  data: promiseId,
});

export const popPromise = (promiseId) => ({
  type: POP_PROMISE,
  data: promiseId,
});
