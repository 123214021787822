import { FC, useState } from 'react';
import PhoneNumberInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { connect, useDispatch } from 'react-redux';
import { infoIcon } from '../../helpers/imageAssets';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Order } from '../../models/order.model';
import Tooltip from '../Tooltip';
// @ts-ignore
import ShowMoreText from 'react-show-more-text';
import { saveOrderData } from '../../redux/actions/order';
import './OrderStatusNotifications.scss';

interface NotificationProps {
  onPhoneNumberUpdate: (valid: boolean) => void;
  pickedZoneType: string;
  isPhoneNumberRequired: boolean;
  enableOptInCheckbox: boolean;
  order: Order;
}

const OrderStatusNotifications: FC<NotificationProps> = ({
  order,
  pickedZoneType,
  enableOptInCheckbox,
  isPhoneNumberRequired,
  onPhoneNumberUpdate,
}: NotificationProps) => {
  const [phoneNumber, setPhoneNumber] = useState(
    order?.smsOptIn && order.customerPhone ? order.customerPhone : '',
  );
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const { billing } = useTypedSelector((state: any) => state.localization);
  const [phoneNumberFocus, setPhoneNumberFocus] = useState(false);
  const dispatch = useDispatch();
  const activePlace = useTypedSelector((state) => state.places.activePlace);
  const { webContent } = (
    useTypedSelector((state) => state.places.placeContent) as any[]
  )[activePlace?.fullyQualifiedId];

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const toggleTooltip = () => {
    setIsTooltipVisible(!isTooltipVisible);
  };

  const setPhoneNumberFromForm = (newNumber: string) => {
    setPhoneNumber(newNumber);
    const isValid = newNumber
      ? isValidPhoneNumber(newNumber)
      : !isPhoneNumberRequired;
    setIsPhoneNumberValid(isValid);
    onPhoneNumberUpdate(isValid);
    if (isValid) {
      dispatch(saveOrderData({ customerPhone: newNumber }));
    }
  };

  const handlePhoneNumberFocus = () => {
    setPhoneNumberFocus(true);
  };

  const handlePhoneNumberBlur = () => {
    const isValid = phoneNumber
      ? isValidPhoneNumber(phoneNumber)
      : !isPhoneNumberRequired;
    setIsPhoneNumberValid(isValid);
    onPhoneNumberUpdate(isValid);
    setPhoneNumberFocus(false);
    if (isValid) {
      dispatch(saveOrderData({ ...order, customerPhone: phoneNumber }));
    }
  };

  const handleAdaChange = (e: any) => {
    dispatch(
      saveOrderData({
        ...order,
        customerSpecificOrderDetails: { adaCompliant: e.target.checked },
      }),
    );
  };

  const handleOptInChange = (e: any) => {
    dispatch(
      saveOrderData({
        ...order,
        smsOptIn: e.target.checked,
      }),
    );
  };

  const getPhoneNumberError = (
    phoneNumber: string,
    isPhoneNumberValid: boolean,
  ) => {
    if (!phoneNumber) {
      return 'empty phone';
    }

    if (!isPhoneNumberValid) {
      return 'invalid phone';
    }
  };

  if (pickedZoneType === 'LOCKER_PICKUP') {
    return (
      <section className="check-in-selection contains-forms">
        <div className="title">{billing.lockerPickUpFormTitle}</div>
        <div className="content">
          <p className="order-notifications-header">
            {webContent?.lockerPickupDetailsHeader}
          </p>
          <div className="order-notifications-content">
            <ShowMoreText
              lines={2}
              more="see more"
              less="see less"
              expanded={false}
              className="order-notifications-text"
              truncatedEndingComponent={'... '}>
              <div>{webContent?.lockerPickupDetailsBody}</div>
            </ShowMoreText>
          </div>
          <div className="form-group">
            <PhoneNumberInput
              placeholder="(201) 555-0123"
              value={phoneNumber}
              defaultCountry="US"
              onFocus={handlePhoneNumberFocus}
              onBlur={handlePhoneNumberBlur}
              onChange={setPhoneNumberFromForm}
            />

            {phoneNumber && !phoneNumberFocus && !isPhoneNumberValid && (
              <div className="color-error error-message">
                {getPhoneNumberError(phoneNumber, isPhoneNumberValid)}
              </div>
            )}

            <label className="ada-checkbox">
              <input type="checkbox" id="checkbox" onChange={handleAdaChange} />
              <span className="ada-checkbox-label">{billing.adaCompliant}</span>
            </label>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="check-in-selection contains-forms">
        <div className="title">{billing.contactInformationFormTitle}</div>
        <div className="content">
          <p className="order-notifications-header">
            {webContent?.phoneNumberCollectionTitle}
          </p>
          <div className="order-notifications-content">
            <ShowMoreText
              lines={2}
              more="see more"
              less="see less"
              expanded={false}
              className="order-notifications-text"
              truncatedEndingComponent={'... '}>
              <div>{webContent?.phoneNumberCollectionContent}</div>
            </ShowMoreText>
            {webContent?.phoneNumberCollectionTooltip && (
              <div className="tooltip-wrapper">
                <img
                  src={infoIcon}
                  className="info-icon"
                  alt="Info"
                  onClick={toggleTooltip}
                />

                <Tooltip
                  isVisible={isTooltipVisible}
                  message={webContent?.phoneNumberCollectionTooltip}
                  title={null}
                  toggleVisibility={toggleTooltip}
                />
              </div>
            )}
          </div>
          <div className="form-group">
            <div className="phone-input">
              <PhoneNumberInput
                placeholder="Enter Mobile Number"
                value={phoneNumber}
                defaultCountry="US"
                onFocus={handlePhoneNumberFocus}
                onBlur={handlePhoneNumberBlur}
                onChange={setPhoneNumberFromForm}
              />
            </div>

            {phoneNumber && !phoneNumberFocus && !isPhoneNumberValid && (
              <div className="color-error error-message">
                {getPhoneNumberError(phoneNumber, isPhoneNumberValid)}
              </div>
            )}

            {enableOptInCheckbox &&
              webContent?.phoneNumberCollectionOptInTitleOne && (
                <label className="ada-checkbox">
                  <input
                    type="checkbox"
                    id="checkbox"
                    onChange={handleOptInChange}
                  />
                  <span className="ada-checkbox-label">
                    {webContent.phoneNumberCollectionOptInTitleOne}
                  </span>
                </label>
              )}
          </div>
        </div>
      </section>
    );
  }
};

const mapStateToProps = (state: any) => {
  return {
    order: state.order,
    placeContent: state.places.placeContent,
  };
};

export default connect(mapStateToProps)(OrderStatusNotifications);
