import { SAVE_TOKEN } from '../actions/token';

const defaultState = {
  identity: {
    access: '',
    refresh: '',
    customer: '',
  },
};

export default (state = defaultState, action) => {
  const { type, data } = action;

  switch (type) {
    case SAVE_TOKEN: {
      return {
        ...state,
        identity: data,
      };
    }
    default:
      return state;
  }
};
