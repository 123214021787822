import axios from 'axios';
import store from '../../index';
import { createPromiseId } from '../../../helpers';
import { popPromise, pushPromise } from '../spinner';
import { saveLocation, locationError, saveInVenue } from '../location';
import { reportLocationRequest } from '../../../helpers/googleAnalytics';

const getGeolocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (location) => {
        reportLocationRequest('allowed');
        resolve(location);
      },
      (err) => reject(err),
      {
        timeout: 30000, // Give up after 30 seconds
      },
    );
  });
};

// GeolocationPositionError also has a message property but it's browser-specific.
const decodeLocationError = (code) => {
  if (code === 1) {
    reportLocationRequest('denied');
  } else {
    reportLocationRequest('error');
  }
  switch (code) {
    case 1:
      return 'permissionDenied';
    case 2:
      return 'unavailable';
    case 3:
      return 'timeout';
    default:
      return `unknown ${code}`;
  }
};

// Get the user's current location via the browser Geolocation API.
// If overrideInVenue is truthy, does not prompt for location access.
export const _handleLocation = (
  dispatch,
  _store = store,
  _getGeolocation = getGeolocation,
) => {
  const {
    webConfig: { overrideInVenue, inVenueRequired },
  } = _store.getState();

  if (overrideInVenue || !inVenueRequired) {
    dispatch(saveLocation(0, 0));
    return Promise.resolve();
  }

  return _getGeolocation()
    .then((geolocation) => {
      const { latitude, longitude } = geolocation.coords;
      dispatch(saveLocation(latitude, longitude));
    })
    .catch((err) => {
      dispatch(locationError(decodeLocationError(err.code)));
    });
};

// Run completion regardless of user's location or how it was determined (or faked).
export const getLocation = (completion) => {
  return (dispatch) => {
    return _handleLocation(dispatch).finally(() => {
      if (completion) {
        completion();
      }
    });
  };
};

// Checks if the user's current location is inside the venue geofence.
// If overrideInVenue is true, no need to check further.
// If the location is unknown or a network error occurs, we assume that the user is out of venue.
export const checkIfCurrentLocationIsInVenue = (_store = store) => {
  const { webConfig, token, location } = _store.getState();
  const { apiUrl, venueId, overrideInVenue, inVenueRequired } = webConfig;

  return (dispatch) => {
    if (overrideInVenue) {
      const inVenue = overrideInVenue !== 'OUT_OF_VENUE' || !inVenueRequired;
      return dispatch(saveInVenue(inVenue));
    }

    const { lat, lon } = location;
    if (typeof lat !== 'number' || typeof lon !== 'number') {
      return dispatch(saveInVenue(false));
    }

    const promiseId = createPromiseId();
    dispatch(pushPromise(promiseId));
    return axios
      .get(`${apiUrl}api/v1/public/venues/${venueId}/@${lat},${lon}`, {
        headers: {
          Authorization: `Bearer ${token.identity.access}`,
          'x-venue-id': venueId,
        },
      })
      .then((response) => {
        const { insideLocation } = response.data;
        dispatch(saveInVenue(insideLocation));
      })
      .catch((error) => {
        dispatch(saveInVenue(false));
      })
      .finally(() => {
        dispatch(popPromise(promiseId));
      });
  };
};
