import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { closeToast2 } from '../../redux/actions/toast2';

import './index.scss';

class Toast2 extends React.Component {
  constructor(props) {
    super(props);

    this.displayActions = this.displayActions.bind(this);
    this.dismissAction = this.dismissAction.bind(this);
    this.noAction = this.noAction.bind(this);
    this.yesAction = this.yesAction.bind(this);
  }

  dismissAction(e) {
    const { toast2, closeToast2 } = this.props;
    if (toast2 && toast2.isOpen) {
      closeToast2();
      if (toast2.dismissCallback) {
        toast2.dismissCallback();
      }
    }
    e.preventDefault();
  }

  noAction(e) {
    this.dismissAction(e);
  }

  yesAction(e) {
    const {
      toast2: { callback },
    } = this.props;
    callback();
    e.preventDefault();
  }

  displayTopActions() {
    const { localization } = this.props;
    const {
      popup: { closeLabel },
    } = localization;

    return (
      <div className="actions-left">
        <button
          className="x-close color-accent font-primary bg-color-card"
          onClick={this.dismissAction}>
          {closeLabel}
        </button>
      </div>
    );
  }

  displayActions() {
    const {
      toast2: { hasConfirmation, buttons },
    } = this.props;

    if (hasConfirmation) {
      return (
        <div className="actions">
          <button
            className="no font-primary color-accent bg-color-card"
            onClick={this.noAction}>
            No
          </button>
          <button
            className="yes font-primary color-accent bg-color-card"
            onClick={this.yesAction}>
            Yes
          </button>
        </div>
      );
    }

    if (buttons && buttons.length) {
      return (
        <div>
          <div className="actions column">
            {buttons.map((button, idx) => (
              <button
                key={`action-btn-${idx}`}
                className={classNames(
                  'full-width',
                  'font-secondary',
                  button.inverted &&
                    'inverted bg-color-card color-accent border-color-accent',
                  !button.inverted && 'color-card bg-color-accent',
                )}
                onClick={button.action}>
                {button.label}
              </button>
            ))}
          </div>
        </div>
      );
    }
  }

  render() {
    const { toast2 } = this.props;

    return (
      <div
        className={classNames(
          'toast2-wrapper',
          toast2.isOpen ? 'show' : 'hide',
        )}>
        <div
          className={classNames(
            'toast2 bg-color-card',
            toast2.isOpen ? 'show' : 'hide',
          )}>
          {this.displayTopActions()}

          {toast2.title && (
            <h3
              className={classNames(
                'title',
                toast2.plainTitle && 'plain font-secondary',
              )}>
              {toast2.title}
            </h3>
          )}
          {toast2.message && (
            <p
              className={classNames(
                'message',
                'font-secondary',
                toast2.smallMessage && 'small',
              )}>
              {toast2.message}
            </p>
          )}

          {toast2.htmlMessage && (
            <div
              className={classNames(
                'message',
                'font-secondary',
                toast2.smallMessage && 'small',
              )}
              dangerouslySetInnerHTML={{ __html: toast2.htmlMessage }}
            />
          )}

          {this.displayActions()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    toast2: state.toast2,
    localization: state.localization,
  };
};

const mapDispatchToProps = {
  closeToast2,
};

export default connect(mapStateToProps, mapDispatchToProps)(Toast2);
