import { FC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import CloseSvg from '../../assets/icons/close.svg';
import { ReactComponent as BackIcon } from '../../assets/icons/i-back.svg';
import { determinePage, getCloseUrl, PAYMENT_PAGE } from '../../helpers';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { Order, OrderStatus } from '../../models/order.model';
import { resetOrderData } from '../../redux/actions/order';
import {
  BILLING_INFORMATION_STATE,
  _setPaymentStatus,
} from '../../redux/actions/payment';
import { closeToast2, openToast2 } from '../../redux/actions/toast2';
import './index.scss';

// change App State here
const mapStateToProps = (state: any, props: HeaderProps) => {
  return {
    localization: state.localization,
    webConfig: state.webConfig,
    paymentStatus: state.payment.status,
  };
};

const mapDispatchToProps = {
  openToast2,
  closeToast2,
  _setPaymentStatus,
  resetOrderData,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface HeaderProps extends RouteComponentProps {
  hasBack: boolean;
  hasClose: boolean;
  history: any;
  text: string;
  order: Order & { totalQuantity: number };
}

interface DispatchProps {
  openToast2: typeof openToast2;
  closeToast2: typeof closeToast2;
  _setPaymentStatus: typeof _setPaymentStatus;
  resetOrderData: typeof resetOrderData;
}

interface StateProps {
  webConfig: any;
  localization: any;
  paymentStatus: string;
}

export type Props = HeaderProps & DispatchProps & StateProps;

const Header: FC<Props> = ({
  hasBack,
  history,
  text,
  hasClose,
  webConfig,
  localization,
  order,
  paymentStatus,
  _setPaymentStatus,
  openToast2,
  closeToast2,
  resetOrderData,
}) => {
  const { params } = useTypedSelector((state) => state.query);

  function handleBack() {
    const currentPage = determinePage(window.location.hash, paymentStatus);
    if (currentPage === PAYMENT_PAGE) {
      _setPaymentStatus(BILLING_INFORMATION_STATE);
    } else {
      history.goBack();
    }
  }

  function handleClose() {
    const closeUrl = getCloseUrl(webConfig.venueId);

    if (
      order.totalQuantity &&
      ((order.orderStatus !== OrderStatus.RELEASED && !order.checkinTime) ||
        (order.orderStatus !== OrderStatus.RELEASED &&
          order.orderStatus !== OrderStatus.SUBMITTED &&
          order.checkinTime))
    ) {
      openToast2({
        title: localization.header.toastTitle,
        message: localization.header.toastMessage,
        hasConfirmation: true,
        callback: () => {
          if (
            params.fromMobile !== 'true' &&
            params.foodAndBev !== 'true' &&
            webConfig.venueId &&
            webConfig.configFile
          ) {
            resetOrderData();
            history.push(
              `/config/${webConfig.configFile}/venue/${webConfig.venueId}/places`,
            );
            closeToast2();
          } else {
            window.location.replace(closeUrl);
          }
        },
      });
    } else if (
      params.fromMobile !== 'true' &&
      params.foodAndBev !== 'true' &&
      webConfig.venueId &&
      webConfig.configFile
    ) {
      resetOrderData();
      history.push(
        `/config/${webConfig.configFile}/venue/${webConfig.venueId}/places`,
      );
      closeToast2();
    } else {
      window.location.replace(closeUrl);
    }
  }
  return (
    <div className="store-name-wrapper">
      <h2 className="header-text store-name color-text-secondary">
        <div className="left-button-wrapper">
          {hasBack &&
            (params.fromMobile === undefined || !params.fromMobile) &&
            (params.foodAndBev === undefined || !params.foodAndBev) &&
            !hasClose && (
              <BackIcon onClick={handleBack} className="color-nav" />
            )}
          {(hasClose ||
            params.fromMobile === 'true' ||
            params.foodAndBev === 'true') &&
            webConfig.venueId && (
              <CloseSvg
                className="color-nav"
                onClick={handleClose.bind(this)}
                alt="close-icon"
              />
            )}
        </div>
        <span>{text}</span>
      </h2>
    </div>
  );
};

export default connector(Header);
